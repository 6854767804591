const countriesData = [
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1990,
        "ID": "AFG1990",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1991,
        "ID": "AFG1991",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1992,
        "ID": "AFG1992",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1993,
        "ID": "AFG1993",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1994,
        "ID": "AFG1994",
        "yCap": 1280.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1995,
        "ID": "AFG1995",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1996,
        "ID": "AFG1996",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1997,
        "ID": "AFG1997",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1998,
        "ID": "AFG1998",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 1999,
        "ID": "AFG1999",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2000,
        "ID": "AFG2000",
        "yCap": 1280.46,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2001,
        "ID": "AFG2001",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2002,
        "ID": "AFG2002",
        "yCap": 1280.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2003,
        "ID": "AFG2003",
        "yCap": 1292.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2004,
        "ID": "AFG2004",
        "yCap": 1260.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2005,
        "ID": "AFG2005",
        "yCap": 1352.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2006,
        "ID": "AFG2006",
        "yCap": 1366.99,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2007,
        "ID": "AFG2007",
        "yCap": 1528.34,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2008,
        "ID": "AFG2008",
        "yCap": 1556.84,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2009,
        "ID": "AFG2009",
        "yCap": 1823.74,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2010,
        "ID": "AFG2010",
        "yCap": 2026.16,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2011,
        "ID": "AFG2011",
        "yCap": 1961.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2012,
        "ID": "AFG2012",
        "yCap": 2122.83,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2013,
        "ID": "AFG2013",
        "yCap": 2165.34,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2014,
        "ID": "AFG2014",
        "yCap": 2144.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2015,
        "ID": "AFG2015",
        "yCap": 2108.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2016,
        "ID": "AFG2016",
        "yCap": 2101.42,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2017,
        "ID": "AFG2017",
        "yCap": 2096.09,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2018,
        "ID": "AFG2018",
        "yCap": 2060.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2019,
        "ID": "AFG2019",
        "yCap": 2079.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2020,
        "ID": "AFG2020",
        "yCap": 1968.34,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2021,
        "ID": "AFG2021",
        "yCap": 1516.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Afghanistan",
        "Alpha-3": "AFG",
        "Alpha-2": "AF",
        "Region": "SA",
        "IncGroup": "Low",
        "RegIncGrp": "SA-Low",
        "Time": 2022,
        "ID": "AFG2022",
        "yCap": 1516.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "ALB1990",
        "yCap": 4827.04,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "ALB1991",
        "yCap": 3496.38,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "ALB1992",
        "yCap": 3264.83,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "ALB1993",
        "yCap": 3598.82,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "ALB1994",
        "yCap": 3921.63,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "ALB1995",
        "yCap": 4471.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "ALB1996",
        "yCap": 4908.95,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "ALB1997",
        "yCap": 4400.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "ALB1998",
        "yCap": 4819.08,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "ALB1999",
        "yCap": 5474.87,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "ALB2000",
        "yCap": 5892.6,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "ALB2001",
        "yCap": 6441.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "ALB2002",
        "yCap": 6753.9,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "ALB2003",
        "yCap": 7154.02,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "ALB2004",
        "yCap": 7580.15,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "ALB2005",
        "yCap": 8040.11,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "ALB2006",
        "yCap": 8568.58,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "ALB2007",
        "yCap": 9150.15,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "ALB2008",
        "yCap": 9912.18,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "ALB2009",
        "yCap": 10313.94,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "ALB2010",
        "yCap": 10749.5,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "ALB2011",
        "yCap": 11052.81,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "ALB2012",
        "yCap": 11227.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "ALB2013",
        "yCap": 11361.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "ALB2014",
        "yCap": 11586.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "ALB2015",
        "yCap": 11878.48,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "ALB2016",
        "yCap": 12291.88,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "ALB2017",
        "yCap": 12770.99,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "ALB2018",
        "yCap": 13317.12,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "ALB2019",
        "yCap": 13653.23,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "ALB2020",
        "yCap": 13253.76,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "ALB2021",
        "yCap": 14516.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Albania",
        "Alpha-3": "ALB",
        "Alpha-2": "AL",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "ALB2022",
        "yCap": 14516.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "DZA1990",
        "yCap": 8828.87,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "DZA1991",
        "yCap": 8517.38,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "DZA1992",
        "yCap": 8471.53,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "DZA1993",
        "yCap": 8109.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "DZA1994",
        "yCap": 7869.27,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "DZA1995",
        "yCap": 8013.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "DZA1996",
        "yCap": 8195.86,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "DZA1997",
        "yCap": 8147.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "DZA1998",
        "yCap": 8435.04,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "DZA1999",
        "yCap": 8584.07,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "DZA2000",
        "yCap": 8786.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "DZA2001",
        "yCap": 8926.11,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "DZA2002",
        "yCap": 9299.68,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "DZA2003",
        "yCap": 9835.16,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "DZA2004",
        "yCap": 10114.73,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "DZA2005",
        "yCap": 10566.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "DZA2006",
        "yCap": 10592.25,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "DZA2007",
        "yCap": 10775.53,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "DZA2008",
        "yCap": 10847.18,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "DZA2009",
        "yCap": 10824.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "DZA2010",
        "yCap": 11007.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "DZA2011",
        "yCap": 11113.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "DZA2012",
        "yCap": 11270.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "DZA2013",
        "yCap": 11360.64,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "DZA2014",
        "yCap": 11561.26,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "DZA2015",
        "yCap": 11751.63,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "DZA2016",
        "yCap": 11888.32,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "DZA2017",
        "yCap": 11809.48,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "DZA2018",
        "yCap": 11725.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "DZA2019",
        "yCap": 11627.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "DZA2020",
        "yCap": 10844.77,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "DZA2021",
        "yCap": 11039.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Algeria",
        "Alpha-3": "DZA",
        "Alpha-2": "DZ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "DZA2022",
        "yCap": 11039.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "ASM1990",
        "yCap": 12367.59,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "ASM1991",
        "yCap": 12505.46,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "ASM1992",
        "yCap": 12903.06,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "ASM1993",
        "yCap": 13250.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "ASM1994",
        "yCap": 13652.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "ASM1995",
        "yCap": 14030.41,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "ASM1996",
        "yCap": 14250.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "ASM1997",
        "yCap": 14345.62,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "ASM1998",
        "yCap": 13907.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "ASM1999",
        "yCap": 14177.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "ASM2000",
        "yCap": 14657.18,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "ASM2001",
        "yCap": 14767.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "ASM2002",
        "yCap": 15202.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "ASM2003",
        "yCap": 15690.84,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "ASM2004",
        "yCap": 16635.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "ASM2005",
        "yCap": 17160.26,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "ASM2006",
        "yCap": 17930.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "ASM2007",
        "yCap": 18645.08,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "ASM2008",
        "yCap": 18659.79,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "ASM2009",
        "yCap": 18352.43,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "ASM2010",
        "yCap": 19761.77,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "ASM2011",
        "yCap": 20971.66,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "ASM2012",
        "yCap": 21618,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "ASM2013",
        "yCap": 22402.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "ASM2014",
        "yCap": 22596.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "ASM2015",
        "yCap": 21826.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "ASM2016",
        "yCap": 22024.82,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "ASM2017",
        "yCap": 22747.82,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "ASM2018",
        "yCap": 23336.48,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "ASM2019",
        "yCap": 23421.25,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "ASM2020",
        "yCap": 20593.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "ASM2021",
        "yCap": 21482.05,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "American Samoa",
        "Alpha-3": "ASM",
        "Alpha-2": "AS",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "ASM2022",
        "yCap": 21482.05,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "AND1990",
        "yCap": 21546.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "AND1991",
        "yCap": 21372.8,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "AND1992",
        "yCap": 21081.84,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "AND1993",
        "yCap": 20893.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "AND1994",
        "yCap": 21098.63,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "AND1995",
        "yCap": 21483.99,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "AND1996",
        "yCap": 21981.24,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "AND1997",
        "yCap": 22717.27,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "AND1998",
        "yCap": 23548.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "AND1999",
        "yCap": 24300.48,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "AND2000",
        "yCap": 25310.46,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "AND2001",
        "yCap": 25961.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "AND2002",
        "yCap": 26468.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "AND2003",
        "yCap": 27049.29,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "AND2004",
        "yCap": 28109.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "AND2005",
        "yCap": 29015.44,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "AND2006",
        "yCap": 30295.56,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "AND2007",
        "yCap": 31705.85,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "AND2008",
        "yCap": 31930.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "AND2009",
        "yCap": 30195.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "AND2010",
        "yCap": 30589.07,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "AND2011",
        "yCap": 30918.19,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "AND2012",
        "yCap": 30801.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "AND2013",
        "yCap": 31011.79,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "AND2014",
        "yCap": 31496.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "AND2015",
        "yCap": 32309.12,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "AND2016",
        "yCap": 32968.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "AND2017",
        "yCap": 33882.6,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "AND2018",
        "yCap": 34759.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "AND2019",
        "yCap": 35523.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "AND2020",
        "yCap": 34038.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "AND2021",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Andorra",
        "Alpha-3": "AND",
        "Alpha-2": "AD",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "AND2022",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "AGO1990",
        "yCap": 5768.56,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "AGO1991",
        "yCap": 5635.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "AGO1992",
        "yCap": 5136.55,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "AGO1993",
        "yCap": 3783.11,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "AGO1994",
        "yCap": 3713.1,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "AGO1995",
        "yCap": 4131.88,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "AGO1996",
        "yCap": 4537.86,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "AGO1997",
        "yCap": 4708.28,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "AGO1998",
        "yCap": 4770.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "AGO1999",
        "yCap": 4719.45,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "AGO2000",
        "yCap": 4708.36,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "AGO2001",
        "yCap": 4747.83,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "AGO2002",
        "yCap": 5219.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "AGO2003",
        "yCap": 5195.31,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "AGO2004",
        "yCap": 5565.58,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "AGO2005",
        "yCap": 6178.33,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "AGO2006",
        "yCap": 6648.76,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "AGO2007",
        "yCap": 7309.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "AGO2008",
        "yCap": 7832.89,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "AGO2009",
        "yCap": 7613.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "AGO2010",
        "yCap": 7691.13,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "AGO2011",
        "yCap": 7664.44,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "AGO2012",
        "yCap": 8012.1,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "AGO2013",
        "yCap": 8100.39,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "AGO2014",
        "yCap": 8183.68,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "AGO2015",
        "yCap": 7967.1,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "AGO2016",
        "yCap": 7488.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "AGO2017",
        "yCap": 7216.06,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "AGO2018",
        "yCap": 6878.59,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "AGO2019",
        "yCap": 6602.42,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "AGO2020",
        "yCap": 6032.27,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "AGO2021",
        "yCap": 5908.57,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Angola",
        "Alpha-3": "AGO",
        "Alpha-2": "AO",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "AGO2022",
        "yCap": 5908.57,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "ATG1990",
        "yCap": 15264.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "ATG1991",
        "yCap": 15521.87,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "ATG1992",
        "yCap": 15452.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "ATG1993",
        "yCap": 15978.31,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "ATG1994",
        "yCap": 16730.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "ATG1995",
        "yCap": 15690.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "ATG1996",
        "yCap": 16391.72,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "ATG1997",
        "yCap": 16943.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "ATG1998",
        "yCap": 17414.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "ATG1999",
        "yCap": 17754.54,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "ATG2000",
        "yCap": 18545.92,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "ATG2001",
        "yCap": 17433.02,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "ATG2002",
        "yCap": 17388.54,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "ATG2003",
        "yCap": 18237.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "ATG2004",
        "yCap": 19077.41,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "ATG2005",
        "yCap": 20076.44,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "ATG2006",
        "yCap": 22340.79,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "ATG2007",
        "yCap": 24088.12,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "ATG2008",
        "yCap": 23727.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "ATG2009",
        "yCap": 20571.91,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "ATG2010",
        "yCap": 18702.09,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "ATG2011",
        "yCap": 18117.19,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "ATG2012",
        "yCap": 18526.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "ATG2013",
        "yCap": 18243.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "ATG2014",
        "yCap": 18779.48,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "ATG2015",
        "yCap": 19345.02,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "ATG2016",
        "yCap": 20267.92,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "ATG2017",
        "yCap": 20777.87,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "ATG2018",
        "yCap": 22085.26,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "ATG2019",
        "yCap": 23035.66,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "ATG2020",
        "yCap": 18275.69,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "ATG2021",
        "yCap": 19124.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Antigua and Barbuda",
        "Alpha-3": "ATG",
        "Alpha-2": "AG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "ATG2022",
        "yCap": 19124.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "ARG1990",
        "yCap": 14136.53,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "ARG1991",
        "yCap": 15209.49,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "ARG1992",
        "yCap": 16190.51,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "ARG1993",
        "yCap": 17282.96,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "ARG1994",
        "yCap": 18046.89,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "ARG1995",
        "yCap": 17303.92,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "ARG1996",
        "yCap": 18031.55,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "ARG1997",
        "yCap": 19261.9,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "ARG1998",
        "yCap": 19773.18,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "ARG1999",
        "yCap": 18884.94,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "ARG2000",
        "yCap": 18524.81,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "ARG2001",
        "yCap": 17514.5,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "ARG2002",
        "yCap": 15439.74,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "ARG2003",
        "yCap": 16631.57,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "ARG2004",
        "yCap": 17950.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "ARG2005",
        "yCap": 19338.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "ARG2006",
        "yCap": 20679.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "ARG2007",
        "yCap": 22316.26,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "ARG2008",
        "yCap": 22992.39,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "ARG2009",
        "yCap": 21413.28,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "ARG2010",
        "yCap": 23521.27,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "ARG2011",
        "yCap": 24647.63,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "ARG2012",
        "yCap": 24118.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "ARG2013",
        "yCap": 24424.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "ARG2014",
        "yCap": 23550.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "ARG2015",
        "yCap": 23933.89,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "ARG2016",
        "yCap": 23189.53,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "ARG2017",
        "yCap": 23597.12,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "ARG2018",
        "yCap": 22747.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "ARG2019",
        "yCap": 22071.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "ARG2020",
        "yCap": 19685.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "ARG2021",
        "yCap": 21527.2,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Argentina",
        "Alpha-3": "ARG",
        "Alpha-2": "AR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "ARG2022",
        "yCap": 21527.2,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "ARM1990",
        "yCap": 5153.3,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "ARM1991",
        "yCap": 4473.52,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "ARM1992",
        "yCap": 2634.96,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "ARM1993",
        "yCap": 2484.55,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "ARM1994",
        "yCap": 2683.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "ARM1995",
        "yCap": 2912.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "ARM1996",
        "yCap": 3105.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "ARM1997",
        "yCap": 3236.06,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "ARM1998",
        "yCap": 3505.37,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "ARM1999",
        "yCap": 3660.03,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "ARM2000",
        "yCap": 3921.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "ARM2001",
        "yCap": 4346.91,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "ARM2002",
        "yCap": 4965.22,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "ARM2003",
        "yCap": 5698.78,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "ARM2004",
        "yCap": 6334.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "ARM2005",
        "yCap": 7259.2,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "ARM2006",
        "yCap": 8273.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "ARM2007",
        "yCap": 9476.47,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "ARM2008",
        "yCap": 10201.56,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "ARM2009",
        "yCap": 8819.68,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "ARM2010",
        "yCap": 9068.79,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "ARM2011",
        "yCap": 9551.16,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "ARM2012",
        "yCap": 10289.98,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "ARM2013",
        "yCap": 10677.3,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "ARM2014",
        "yCap": 11105.53,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "ARM2015",
        "yCap": 11506.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "ARM2016",
        "yCap": 11580.38,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "ARM2017",
        "yCap": 12509.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "ARM2018",
        "yCap": 13231.43,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "ARM2019",
        "yCap": 14317.55,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "ARM2020",
        "yCap": 13357.7,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "ARM2021",
        "yCap": 14193.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Armenia",
        "Alpha-3": "ARM",
        "Alpha-2": "AM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "ARM2022",
        "yCap": 14193.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "ABW1990",
        "yCap": 30823.48,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "ABW1991",
        "yCap": 32222.66,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "ABW1992",
        "yCap": 32986.54,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "ABW1993",
        "yCap": 34336.55,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "ABW1994",
        "yCap": 35984.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "ABW1995",
        "yCap": 35780.72,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "ABW1996",
        "yCap": 35125.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "ABW1997",
        "yCap": 36479.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "ABW1998",
        "yCap": 36105.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "ABW1999",
        "yCap": 35495.25,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "ABW2000",
        "yCap": 37241.03,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "ABW2001",
        "yCap": 38123.34,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "ABW2002",
        "yCap": 37309.79,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "ABW2003",
        "yCap": 37353.56,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "ABW2004",
        "yCap": 39713.85,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "ABW2005",
        "yCap": 39166.13,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "ABW2006",
        "yCap": 39146.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "ABW2007",
        "yCap": 39866.72,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "ABW2008",
        "yCap": 40096.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "ABW2009",
        "yCap": 34978.01,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "ABW2010",
        "yCap": 33639.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "ABW2011",
        "yCap": 34446.86,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "ABW2012",
        "yCap": 33815.11,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "ABW2013",
        "yCap": 35720.7,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "ABW2014",
        "yCap": 35467.24,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "ABW2015",
        "yCap": 36490.52,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "ABW2016",
        "yCap": 37043.7,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "ABW2017",
        "yCap": 38865.19,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "ABW2018",
        "yCap": 40706.75,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "ABW2019",
        "yCap": 40780.52,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "ABW2020",
        "yCap": 33155.24,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "ABW2021",
        "yCap": 38866.33,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Aruba",
        "Alpha-3": "ABW",
        "Alpha-2": "AW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "ABW2022",
        "yCap": 38866.33,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "AUS1990",
        "yCap": 31006.1,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "AUS1991",
        "yCap": 30496.13,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "AUS1992",
        "yCap": 30285.85,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "AUS1993",
        "yCap": 31232.59,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "AUS1994",
        "yCap": 32164.66,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "AUS1995",
        "yCap": 33044.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "AUS1996",
        "yCap": 33905.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "AUS1997",
        "yCap": 34852.75,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "AUS1998",
        "yCap": 36099.02,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "AUS1999",
        "yCap": 37475.98,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "AUS2000",
        "yCap": 38494.89,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "AUS2001",
        "yCap": 38779.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "AUS2002",
        "yCap": 39872.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "AUS2003",
        "yCap": 40642.56,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "AUS2004",
        "yCap": 41905.85,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "AUS2005",
        "yCap": 42704.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "AUS2006",
        "yCap": 43286.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "AUS2007",
        "yCap": 44109.67,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "AUS2008",
        "yCap": 44777.27,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "AUS2009",
        "yCap": 44684.4,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "AUS2010",
        "yCap": 44965.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "AUS2011",
        "yCap": 45405.37,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "AUS2012",
        "yCap": 46360.61,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "AUS2013",
        "yCap": 46744.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "AUS2014",
        "yCap": 47240.27,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "AUS2015",
        "yCap": 47567.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "AUS2016",
        "yCap": 48109.2,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "AUS2017",
        "yCap": 48400.25,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "AUS2018",
        "yCap": 49052.82,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "AUS2019",
        "yCap": 49379.09,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "AUS2020",
        "yCap": 48747.85,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "AUS2021",
        "yCap": 49774.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Australia",
        "Alpha-3": "AUS",
        "Alpha-2": "AU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "AUS2022",
        "yCap": 49774.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "AUT1990",
        "yCap": 37494.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "AUT1991",
        "yCap": 38399.67,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "AUT1992",
        "yCap": 38774.49,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "AUT1993",
        "yCap": 38658.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "AUT1994",
        "yCap": 39435.21,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "AUT1995",
        "yCap": 40425.39,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "AUT1996",
        "yCap": 41319.37,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "AUT1997",
        "yCap": 42136.66,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "AUT1998",
        "yCap": 43597.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "AUT1999",
        "yCap": 45060.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "AUT2000",
        "yCap": 46469.86,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "AUT2001",
        "yCap": 46878.92,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "AUT2002",
        "yCap": 47419.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "AUT2003",
        "yCap": 47633.11,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "AUT2004",
        "yCap": 48633.27,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "AUT2005",
        "yCap": 49387.03,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "AUT2006",
        "yCap": 50840.69,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "AUT2007",
        "yCap": 52565.07,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "AUT2008",
        "yCap": 53166.05,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "AUT2009",
        "yCap": 51030.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "AUT2010",
        "yCap": 51843.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "AUT2011",
        "yCap": 53179.15,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "AUT2012",
        "yCap": 53297.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "AUT2013",
        "yCap": 52997.75,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "AUT2014",
        "yCap": 52932.9,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "AUT2015",
        "yCap": 52873.86,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "AUT2016",
        "yCap": 53345.74,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "AUT2017",
        "yCap": 54172.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "AUT2018",
        "yCap": 55217.29,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "AUT2019",
        "yCap": 55806.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "AUT2020",
        "yCap": 51988.42,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "AUT2021",
        "yCap": 54121.15,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Austria",
        "Alpha-3": "AUT",
        "Alpha-2": "AT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "AUT2022",
        "yCap": 54121.15,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "AZE1990",
        "yCap": 7616.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "AZE1991",
        "yCap": 7463.63,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "AZE1992",
        "yCap": 5690.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "AZE1993",
        "yCap": 4309.92,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "AZE1994",
        "yCap": 3414.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "AZE1995",
        "yCap": 2977,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "AZE1996",
        "yCap": 2985.34,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "AZE1997",
        "yCap": 3128.16,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "AZE1998",
        "yCap": 3408.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "AZE1999",
        "yCap": 3628.72,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "AZE2000",
        "yCap": 3998.52,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "AZE2001",
        "yCap": 4360.46,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "AZE2002",
        "yCap": 4736.56,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "AZE2003",
        "yCap": 5180.69,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "AZE2004",
        "yCap": 5610.76,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "AZE2005",
        "yCap": 7106.6,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "AZE2006",
        "yCap": 9453.94,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "AZE2007",
        "yCap": 11684.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "AZE2008",
        "yCap": 12672.38,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "AZE2009",
        "yCap": 13565.86,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "AZE2010",
        "yCap": 14082.24,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "AZE2011",
        "yCap": 13913.84,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "AZE2012",
        "yCap": 14027.47,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "AZE2013",
        "yCap": 14651.69,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "AZE2014",
        "yCap": 14867.94,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "AZE2015",
        "yCap": 14852.61,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "AZE2016",
        "yCap": 14232.19,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "AZE2017",
        "yCap": 14121.41,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "AZE2018",
        "yCap": 14209.59,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "AZE2019",
        "yCap": 14442.04,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "AZE2020",
        "yCap": 13726.77,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "AZE2021",
        "yCap": 14431.66,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Azerbaijan",
        "Alpha-3": "AZE",
        "Alpha-2": "AZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "AZE2022",
        "yCap": 14431.66,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "BHS1990",
        "yCap": 36041.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "BHS1991",
        "yCap": 33860.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "BHS1992",
        "yCap": 31882.47,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "BHS1993",
        "yCap": 31293.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "BHS1994",
        "yCap": 31638.51,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "BHS1995",
        "yCap": 32411.26,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "BHS1996",
        "yCap": 33184.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "BHS1997",
        "yCap": 34867.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "BHS1998",
        "yCap": 32744.92,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "BHS1999",
        "yCap": 36179.89,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "BHS2000",
        "yCap": 37131.3,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "BHS2001",
        "yCap": 37572.89,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "BHS2002",
        "yCap": 38083.53,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "BHS2003",
        "yCap": 37103.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "BHS2004",
        "yCap": 36929.17,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "BHS2005",
        "yCap": 37665.39,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "BHS2006",
        "yCap": 38081.25,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "BHS2007",
        "yCap": 38091.91,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "BHS2008",
        "yCap": 36680.83,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "BHS2009",
        "yCap": 34646.59,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "BHS2010",
        "yCap": 34688.09,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "BHS2011",
        "yCap": 34468.89,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "BHS2012",
        "yCap": 35150.56,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "BHS2013",
        "yCap": 33826.34,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "BHS2014",
        "yCap": 34143.03,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "BHS2015",
        "yCap": 34170.23,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "BHS2016",
        "yCap": 33596.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "BHS2017",
        "yCap": 34357.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "BHS2018",
        "yCap": 34735.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "BHS2019",
        "yCap": 35161.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "BHS2020",
        "yCap": 26659.24,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "BHS2021",
        "yCap": 30210.16,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bahamas, The",
        "Alpha-3": "BHS",
        "Alpha-2": "BS",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "BHS2022",
        "yCap": 30210.16,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "BHR1990",
        "yCap": 36997.74,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "BHR1991",
        "yCap": 39769.31,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "BHR1992",
        "yCap": 40971.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "BHR1993",
        "yCap": 44690.41,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "BHR1994",
        "yCap": 43113.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "BHR1995",
        "yCap": 43369.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "BHR1996",
        "yCap": 43737.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "BHR1997",
        "yCap": 43716.02,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "BHR1998",
        "yCap": 44458.44,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "BHR1999",
        "yCap": 45053.75,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "BHR2000",
        "yCap": 46154.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "BHR2001",
        "yCap": 46084.93,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "BHR2002",
        "yCap": 46478.21,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "BHR2003",
        "yCap": 47504.56,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "BHR2004",
        "yCap": 47455.24,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "BHR2005",
        "yCap": 46821.02,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "BHR2006",
        "yCap": 46303.49,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "BHR2007",
        "yCap": 46792.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "BHR2008",
        "yCap": 46588.64,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "BHR2009",
        "yCap": 44972.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "BHR2010",
        "yCap": 45600.07,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "BHR2011",
        "yCap": 46564.71,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "BHR2012",
        "yCap": 47793.53,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "BHR2013",
        "yCap": 48915.53,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "BHR2014",
        "yCap": 49117.99,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "BHR2015",
        "yCap": 48453.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "BHR2016",
        "yCap": 48486.3,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "BHR2017",
        "yCap": 48929.45,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "BHR2018",
        "yCap": 48937.66,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "BHR2019",
        "yCap": 49768.98,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "BHR2020",
        "yCap": 47847.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "BHR2021",
        "yCap": 49387.42,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bahrain",
        "Alpha-3": "BHR",
        "Alpha-2": "BH",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "BHR2022",
        "yCap": 49387.42,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "BGD1990",
        "yCap": 1730.37,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "BGD1991",
        "yCap": 1756.33,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "BGD1992",
        "yCap": 1818.15,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "BGD1993",
        "yCap": 1867.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "BGD1994",
        "yCap": 1903.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "BGD1995",
        "yCap": 1964.06,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "BGD1996",
        "yCap": 2017.21,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "BGD1997",
        "yCap": 2070.44,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "BGD1998",
        "yCap": 2137.15,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "BGD1999",
        "yCap": 2194.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "BGD2000",
        "yCap": 2267.08,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "BGD2001",
        "yCap": 2337.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "BGD2002",
        "yCap": 2382.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "BGD2003",
        "yCap": 2451.99,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "BGD2004",
        "yCap": 2537.95,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "BGD2005",
        "yCap": 2663.1,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "BGD2006",
        "yCap": 2806.59,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "BGD2007",
        "yCap": 2973.28,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "BGD2008",
        "yCap": 3124.23,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "BGD2009",
        "yCap": 3253.09,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "BGD2010",
        "yCap": 3395.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "BGD2011",
        "yCap": 3571.06,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "BGD2012",
        "yCap": 3756.93,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "BGD2013",
        "yCap": 3932.71,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "BGD2014",
        "yCap": 4119.42,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "BGD2015",
        "yCap": 4337.39,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "BGD2016",
        "yCap": 4589.09,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "BGD2017",
        "yCap": 4830.78,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "BGD2018",
        "yCap": 5124.5,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "BGD2019",
        "yCap": 5467.21,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "BGD2020",
        "yCap": 5591.37,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "BGD2021",
        "yCap": 5911.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bangladesh",
        "Alpha-3": "BGD",
        "Alpha-2": "BD",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "BGD2022",
        "yCap": 5911.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "BRB1990",
        "yCap": 14097.4,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "BRB1991",
        "yCap": 13519.72,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "BRB1992",
        "yCap": 12710.42,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "BRB1993",
        "yCap": 12772.79,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "BRB1994",
        "yCap": 12993.92,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "BRB1995",
        "yCap": 13225.75,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "BRB1996",
        "yCap": 13726.03,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "BRB1997",
        "yCap": 14353.12,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "BRB1998",
        "yCap": 14864.67,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "BRB1999",
        "yCap": 14884.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "BRB2000",
        "yCap": 15517.94,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "BRB2001",
        "yCap": 15109.76,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "BRB2002",
        "yCap": 15167.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "BRB2003",
        "yCap": 15436.6,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "BRB2004",
        "yCap": 15595.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "BRB2005",
        "yCap": 16154.26,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "BRB2006",
        "yCap": 17081.63,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "BRB2007",
        "yCap": 17371.07,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "BRB2008",
        "yCap": 17405.89,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "BRB2009",
        "yCap": 16462.71,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "BRB2010",
        "yCap": 16019.51,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "BRB2011",
        "yCap": 15864.57,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "BRB2012",
        "yCap": 15755.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "BRB2013",
        "yCap": 15489.76,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "BRB2014",
        "yCap": 15441.22,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "BRB2015",
        "yCap": 15789.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "BRB2016",
        "yCap": 16156.52,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "BRB2017",
        "yCap": 16198.96,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "BRB2018",
        "yCap": 16003.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "BRB2019",
        "yCap": 15954.8,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "BRB2020",
        "yCap": 13805.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "BRB2021",
        "yCap": 13754.8,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Barbados",
        "Alpha-3": "BRB",
        "Alpha-2": "BB",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "BRB2022",
        "yCap": 13754.8,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "BLR1990",
        "yCap": 8895.17,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "BLR1991",
        "yCap": 8784.38,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "BLR1992",
        "yCap": 7923.65,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "BLR1993",
        "yCap": 7305.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "BLR1994",
        "yCap": 6458.21,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "BLR1995",
        "yCap": 5805.36,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "BLR1996",
        "yCap": 5988.04,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "BLR1997",
        "yCap": 6698.46,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "BLR1998",
        "yCap": 7293.91,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "BLR1999",
        "yCap": 7575.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "BLR2000",
        "yCap": 8053.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "BLR2001",
        "yCap": 8477.08,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "BLR2002",
        "yCap": 8961.64,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "BLR2003",
        "yCap": 9660.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "BLR2004",
        "yCap": 10839.96,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "BLR2005",
        "yCap": 11940.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "BLR2006",
        "yCap": 13214.87,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "BLR2007",
        "yCap": 14417.35,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "BLR2008",
        "yCap": 15942.9,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "BLR2009",
        "yCap": 16014.12,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "BLR2010",
        "yCap": 17300.7,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "BLR2011",
        "yCap": 18274.02,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "BLR2012",
        "yCap": 18611.45,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "BLR2013",
        "yCap": 18805.43,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "BLR2014",
        "yCap": 19119.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "BLR2015",
        "yCap": 18362.75,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "BLR2016",
        "yCap": 17883.13,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "BLR2017",
        "yCap": 18356.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "BLR2018",
        "yCap": 18974.7,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "BLR2019",
        "yCap": 19279.21,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "BLR2020",
        "yCap": 19225.57,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "BLR2021",
        "yCap": 19751.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belarus",
        "Alpha-3": "BLR",
        "Alpha-2": "BY",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "BLR2022",
        "yCap": 19751.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "BEL1990",
        "yCap": 35506.93,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "BEL1991",
        "yCap": 36023.68,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "BEL1992",
        "yCap": 36426.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "BEL1993",
        "yCap": 35935.96,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "BEL1994",
        "yCap": 36981.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "BEL1995",
        "yCap": 37784.15,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "BEL1996",
        "yCap": 38208.72,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "BEL1997",
        "yCap": 39562.37,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "BEL1998",
        "yCap": 40252.47,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "BEL1999",
        "yCap": 41583.1,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "BEL2000",
        "yCap": 43024.14,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "BEL2001",
        "yCap": 43347.89,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "BEL2002",
        "yCap": 43890.6,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "BEL2003",
        "yCap": 44160.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "BEL2004",
        "yCap": 45540.47,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "BEL2005",
        "yCap": 46342.19,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "BEL2006",
        "yCap": 47212.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "BEL2007",
        "yCap": 48590.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "BEL2008",
        "yCap": 48423.52,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "BEL2009",
        "yCap": 47064.79,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "BEL2010",
        "yCap": 47972.56,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "BEL2011",
        "yCap": 48154.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "BEL2012",
        "yCap": 48210.92,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "BEL2013",
        "yCap": 48204.59,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "BEL2014",
        "yCap": 48748.62,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "BEL2015",
        "yCap": 49456.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "BEL2016",
        "yCap": 49829.93,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "BEL2017",
        "yCap": 50442.27,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "BEL2018",
        "yCap": 51113.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "BEL2019",
        "yCap": 51977.19,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "BEL2020",
        "yCap": 48978.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "BEL2021",
        "yCap": 51739.54,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belgium",
        "Alpha-3": "BEL",
        "Alpha-2": "BE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "BEL2022",
        "yCap": 51739.54,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "BLZ1990",
        "yCap": 6396.5,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "BLZ1991",
        "yCap": 6981.01,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "BLZ1992",
        "yCap": 7663.65,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "BLZ1993",
        "yCap": 7979.02,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "BLZ1994",
        "yCap": 7823.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "BLZ1995",
        "yCap": 7675.22,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "BLZ1996",
        "yCap": 7521.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "BLZ1997",
        "yCap": 7530.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "BLZ1998",
        "yCap": 7554.93,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "BLZ1999",
        "yCap": 7954.44,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "BLZ2000",
        "yCap": 8702.08,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "BLZ2001",
        "yCap": 8857.37,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "BLZ2002",
        "yCap": 9023.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "BLZ2003",
        "yCap": 9566.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "BLZ2004",
        "yCap": 9710.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "BLZ2005",
        "yCap": 9631.59,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "BLZ2006",
        "yCap": 9781.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "BLZ2007",
        "yCap": 9815.05,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "BLZ2008",
        "yCap": 9397.15,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "BLZ2009",
        "yCap": 9101.55,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "BLZ2010",
        "yCap": 8988.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "BLZ2011",
        "yCap": 8776.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "BLZ2012",
        "yCap": 8912.11,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "BLZ2013",
        "yCap": 9109.57,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "BLZ2014",
        "yCap": 9276.32,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "BLZ2015",
        "yCap": 9387.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "BLZ2016",
        "yCap": 9208.11,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "BLZ2017",
        "yCap": 8873.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "BLZ2018",
        "yCap": 8798.54,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "BLZ2019",
        "yCap": 9028.55,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "BLZ2020",
        "yCap": 7703.12,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "BLZ2021",
        "yCap": 8762.62,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Belize",
        "Alpha-3": "BLZ",
        "Alpha-2": "BZ",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "BLZ2022",
        "yCap": 8762.62,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "BEN1990",
        "yCap": 2109.56,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "BEN1991",
        "yCap": 2132.4,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "BEN1992",
        "yCap": 2129.2,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "BEN1993",
        "yCap": 2155.37,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "BEN1994",
        "yCap": 2118.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "BEN1995",
        "yCap": 2200.59,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "BEN1996",
        "yCap": 2237.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "BEN1997",
        "yCap": 2297.99,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "BEN1998",
        "yCap": 2317.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "BEN1999",
        "yCap": 2367.76,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "BEN2000",
        "yCap": 2431.45,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "BEN2001",
        "yCap": 2485.12,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "BEN2002",
        "yCap": 2523.61,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "BEN2003",
        "yCap": 2533,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "BEN2004",
        "yCap": 2566.35,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "BEN2005",
        "yCap": 2528.68,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "BEN2006",
        "yCap": 2549.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "BEN2007",
        "yCap": 2625.21,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "BEN2008",
        "yCap": 2673.77,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "BEN2009",
        "yCap": 2656.43,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "BEN2010",
        "yCap": 2634.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "BEN2011",
        "yCap": 2633.94,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "BEN2012",
        "yCap": 2681.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "BEN2013",
        "yCap": 2792.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "BEN2014",
        "yCap": 2883.9,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "BEN2015",
        "yCap": 2849.82,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "BEN2016",
        "yCap": 2859.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "BEN2017",
        "yCap": 2933.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "BEN2018",
        "yCap": 3040.17,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "BEN2019",
        "yCap": 3156.44,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "BEN2020",
        "yCap": 3186.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "BEN2021",
        "yCap": 3321.55,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Benin",
        "Alpha-3": "BEN",
        "Alpha-2": "BJ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "BEN2022",
        "yCap": 3321.55,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1990,
        "ID": "BMU1990",
        "yCap": 65961.41,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1991,
        "ID": "BMU1991",
        "yCap": 63958.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1992,
        "ID": "BMU1992",
        "yCap": 64385.41,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1993,
        "ID": "BMU1993",
        "yCap": 65967.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1994,
        "ID": "BMU1994",
        "yCap": 65904.72,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1995,
        "ID": "BMU1995",
        "yCap": 68313.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1996,
        "ID": "BMU1996",
        "yCap": 69643.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1997,
        "ID": "BMU1997",
        "yCap": 72403.88,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1998,
        "ID": "BMU1998",
        "yCap": 74677.09,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1999,
        "ID": "BMU1999",
        "yCap": 76772.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2000,
        "ID": "BMU2000",
        "yCap": 83181.53,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2001,
        "ID": "BMU2001",
        "yCap": 88231.78,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2002,
        "ID": "BMU2002",
        "yCap": 86364.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2003,
        "ID": "BMU2003",
        "yCap": 88727.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2004,
        "ID": "BMU2004",
        "yCap": 90195.81,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2005,
        "ID": "BMU2005",
        "yCap": 91114.08,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2006,
        "ID": "BMU2006",
        "yCap": 95615.36,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2007,
        "ID": "BMU2007",
        "yCap": 98257.99,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2008,
        "ID": "BMU2008",
        "yCap": 95855.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2009,
        "ID": "BMU2009",
        "yCap": 89961.98,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2010,
        "ID": "BMU2010",
        "yCap": 88399.92,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2011,
        "ID": "BMU2011",
        "yCap": 85829.38,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2012,
        "ID": "BMU2012",
        "yCap": 80982.37,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2013,
        "ID": "BMU2013",
        "yCap": 80503.65,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2014,
        "ID": "BMU2014",
        "yCap": 77361.1,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2015,
        "ID": "BMU2015",
        "yCap": 77842.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2016,
        "ID": "BMU2016",
        "yCap": 78149.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2017,
        "ID": "BMU2017",
        "yCap": 81834.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2018,
        "ID": "BMU2018",
        "yCap": 81423.41,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2019,
        "ID": "BMU2019",
        "yCap": 81681.73,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2020,
        "ID": "BMU2020",
        "yCap": 76117.8,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2021,
        "ID": "BMU2021",
        "yCap": 80271.13,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Bermuda",
        "Alpha-3": "BMU",
        "Alpha-2": "BM",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2022,
        "ID": "BMU2022",
        "yCap": 80271.13,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "BTN1990",
        "yCap": 2737.99,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "BTN1991",
        "yCap": 2682.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "BTN1992",
        "yCap": 2917.58,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "BTN1993",
        "yCap": 3118.96,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "BTN1994",
        "yCap": 3270.75,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "BTN1995",
        "yCap": 3460.46,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "BTN1996",
        "yCap": 3585.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "BTN1997",
        "yCap": 3713.29,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "BTN1998",
        "yCap": 3860.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "BTN1999",
        "yCap": 4067.47,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "BTN2000",
        "yCap": 4087.83,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "BTN2001",
        "yCap": 4311.45,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "BTN2002",
        "yCap": 4663.13,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "BTN2003",
        "yCap": 4904.51,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "BTN2004",
        "yCap": 5076.32,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "BTN2005",
        "yCap": 5336.78,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "BTN2006",
        "yCap": 5626.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "BTN2007",
        "yCap": 6577.43,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "BTN2008",
        "yCap": 6811.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "BTN2009",
        "yCap": 7188.86,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "BTN2010",
        "yCap": 7958.23,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "BTN2011",
        "yCap": 8499.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "BTN2012",
        "yCap": 8837.58,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "BTN2013",
        "yCap": 8929.05,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "BTN2014",
        "yCap": 9349.04,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "BTN2015",
        "yCap": 9877.25,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "BTN2016",
        "yCap": 10587.57,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "BTN2017",
        "yCap": 10986.89,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "BTN2018",
        "yCap": 11233.78,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "BTN2019",
        "yCap": 11797.28,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "BTN2020",
        "yCap": 10547.05,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "BTN2021",
        "yCap": 10907.86,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bhutan",
        "Alpha-3": "BTN",
        "Alpha-2": "BT",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "BTN2022",
        "yCap": 10907.86,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "BOL1990",
        "yCap": 4437.87,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "BOL1991",
        "yCap": 4575.71,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "BOL1992",
        "yCap": 4556.99,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "BOL1993",
        "yCap": 4657.03,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "BOL1994",
        "yCap": 4779.84,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "BOL1995",
        "yCap": 4909.18,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "BOL1996",
        "yCap": 5028.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "BOL1997",
        "yCap": 5181.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "BOL1998",
        "yCap": 5343.96,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "BOL1999",
        "yCap": 5270.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "BOL2000",
        "yCap": 5305.62,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "BOL2001",
        "yCap": 5300.31,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "BOL2002",
        "yCap": 5337.77,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "BOL2003",
        "yCap": 5387.58,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "BOL2004",
        "yCap": 5515.66,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "BOL2005",
        "yCap": 5660.57,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "BOL2006",
        "yCap": 5829.37,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "BOL2007",
        "yCap": 5989.69,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "BOL2008",
        "yCap": 6248.93,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "BOL2009",
        "yCap": 6349.01,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "BOL2010",
        "yCap": 6499.82,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "BOL2011",
        "yCap": 6724.3,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "BOL2012",
        "yCap": 6952.74,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "BOL2013",
        "yCap": 7305.23,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "BOL2014",
        "yCap": 7581.6,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "BOL2015",
        "yCap": 7825.77,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "BOL2016",
        "yCap": 8034.17,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "BOL2017",
        "yCap": 8244.93,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "BOL2018",
        "yCap": 8466.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "BOL2019",
        "yCap": 8528.75,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "BOL2020",
        "yCap": 7679.93,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "BOL2021",
        "yCap": 8052.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bolivia",
        "Alpha-3": "BOL",
        "Alpha-2": "BO",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "BOL2022",
        "yCap": 8052.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "BIH1990",
        "yCap": 1729.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "BIH1991",
        "yCap": 1729.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "BIH1992",
        "yCap": 1729.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "BIH1993",
        "yCap": 1729.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "BIH1994",
        "yCap": 1729.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "BIH1995",
        "yCap": 2096.39,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "BIH1996",
        "yCap": 3801.91,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "BIH1997",
        "yCap": 4932.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "BIH1998",
        "yCap": 5608.89,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "BIH1999",
        "yCap": 6091.16,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "BIH2000",
        "yCap": 6825.44,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "BIH2001",
        "yCap": 6964.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "BIH2002",
        "yCap": 7308.97,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "BIH2003",
        "yCap": 7618.21,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "BIH2004",
        "yCap": 8180.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "BIH2005",
        "yCap": 8599.64,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "BIH2006",
        "yCap": 9146.11,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "BIH2007",
        "yCap": 9803.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "BIH2008",
        "yCap": 10505.8,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "BIH2009",
        "yCap": 10362.65,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "BIH2010",
        "yCap": 10635.19,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "BIH2011",
        "yCap": 10932.14,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "BIH2012",
        "yCap": 11045.21,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "BIH2013",
        "yCap": 11482.3,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "BIH2014",
        "yCap": 11766,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "BIH2015",
        "yCap": 12290.35,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "BIH2016",
        "yCap": 12835.31,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "BIH2017",
        "yCap": 13400.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "BIH2018",
        "yCap": 14064.33,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "BIH2019",
        "yCap": 14632.13,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "BIH2020",
        "yCap": 14356.42,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "BIH2021",
        "yCap": 15663.74,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bosnia and Herzegovina",
        "Alpha-3": "BIH",
        "Alpha-2": "BA",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "BIH2022",
        "yCap": 15663.74,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "BWA1990",
        "yCap": 9164.87,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "BWA1991",
        "yCap": 9569.46,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "BWA1992",
        "yCap": 9570.55,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "BWA1993",
        "yCap": 9476.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "BWA1994",
        "yCap": 9550.83,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "BWA1995",
        "yCap": 9956.81,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "BWA1996",
        "yCap": 10280.63,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "BWA1997",
        "yCap": 10880.85,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "BWA1998",
        "yCap": 10689.22,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "BWA1999",
        "yCap": 11474.06,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "BWA2000",
        "yCap": 11462.08,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "BWA2001",
        "yCap": 11262.9,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "BWA2002",
        "yCap": 11725.56,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "BWA2003",
        "yCap": 12054.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "BWA2004",
        "yCap": 12166.47,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "BWA2005",
        "yCap": 12494.22,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "BWA2006",
        "yCap": 13287.23,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "BWA2007",
        "yCap": 13783.38,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "BWA2008",
        "yCap": 13945.6,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "BWA2009",
        "yCap": 11729.57,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "BWA2010",
        "yCap": 12653.37,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "BWA2011",
        "yCap": 13250.25,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "BWA2012",
        "yCap": 12975.95,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "BWA2013",
        "yCap": 14144.52,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "BWA2014",
        "yCap": 14665.27,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "BWA2015",
        "yCap": 13682.7,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "BWA2016",
        "yCap": 14373.52,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "BWA2017",
        "yCap": 14656.77,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "BWA2018",
        "yCap": 14962.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "BWA2019",
        "yCap": 15117.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "BWA2020",
        "yCap": 13545.67,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "BWA2021",
        "yCap": 14840.91,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Botswana",
        "Alpha-3": "BWA",
        "Alpha-2": "BW",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "BWA2022",
        "yCap": 14840.91,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "BRA1990",
        "yCap": 10401.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "BRA1991",
        "yCap": 10328.96,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "BRA1992",
        "yCap": 10103.79,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "BRA1993",
        "yCap": 10431.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "BRA1994",
        "yCap": 10867.75,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "BRA1995",
        "yCap": 11151.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "BRA1996",
        "yCap": 11224.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "BRA1997",
        "yCap": 11432.65,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "BRA1998",
        "yCap": 11304.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "BRA1999",
        "yCap": 11196.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "BRA2000",
        "yCap": 11529.49,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "BRA2001",
        "yCap": 11536.36,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "BRA2002",
        "yCap": 11739.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "BRA2003",
        "yCap": 11733.41,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "BRA2004",
        "yCap": 12268.66,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "BRA2005",
        "yCap": 12520.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "BRA2006",
        "yCap": 12877.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "BRA2007",
        "yCap": 13518.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "BRA2008",
        "yCap": 14067.95,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "BRA2009",
        "yCap": 13916.96,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "BRA2010",
        "yCap": 14824.74,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "BRA2011",
        "yCap": 15271.47,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "BRA2012",
        "yCap": 15425.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "BRA2013",
        "yCap": 15751.48,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "BRA2014",
        "yCap": 15695.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "BRA2015",
        "yCap": 15011.58,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "BRA2016",
        "yCap": 14402.49,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "BRA2017",
        "yCap": 14477.86,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "BRA2018",
        "yCap": 14619.59,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "BRA2019",
        "yCap": 14685.13,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "BRA2020",
        "yCap": 14021.96,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "BRA2021",
        "yCap": 14592.35,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brazil",
        "Alpha-3": "BRA",
        "Alpha-2": "BR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "BRA2022",
        "yCap": 14592.35,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "VGB1990",
        "yCap": 14458,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "VGB1991",
        "yCap": 14562.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "VGB1992",
        "yCap": 14756.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "VGB1993",
        "yCap": 14965.32,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "VGB1994",
        "yCap": 15230.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "VGB1995",
        "yCap": 15335.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "VGB1996",
        "yCap": 15545.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "VGB1997",
        "yCap": 15961.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "VGB1998",
        "yCap": 16132.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "VGB1999",
        "yCap": 16282.35,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "VGB2000",
        "yCap": 16566.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "VGB2001",
        "yCap": 16630.31,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "VGB2002",
        "yCap": 16649.59,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "VGB2003",
        "yCap": 16884.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "VGB2004",
        "yCap": 17359.15,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "VGB2005",
        "yCap": 17714.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "VGB2006",
        "yCap": 18214.89,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "VGB2007",
        "yCap": 18690.28,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "VGB2008",
        "yCap": 18915.13,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "VGB2009",
        "yCap": 18152.51,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "VGB2010",
        "yCap": 18305.2,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "VGB2011",
        "yCap": 18677.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "VGB2012",
        "yCap": 18833.91,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "VGB2013",
        "yCap": 19012.88,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "VGB2014",
        "yCap": 19274.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "VGB2015",
        "yCap": 19451.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "VGB2016",
        "yCap": 19564.52,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "VGB2017",
        "yCap": 19627.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "VGB2018",
        "yCap": 19828.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "VGB2019",
        "yCap": 19983.88,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "VGB2020",
        "yCap": 17800.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "VGB2021",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "British Virgin Islands",
        "Alpha-3": "VGB",
        "Alpha-2": "VG",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "VGB2022",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "BRN1990",
        "yCap": 70358.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "BRN1991",
        "yCap": 70439.05,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "BRN1992",
        "yCap": 71781.1,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "BRN1993",
        "yCap": 70154.42,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "BRN1994",
        "yCap": 70570.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 5
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "BRN1995",
        "yCap": 71966.1,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "BRN1996",
        "yCap": 72320.99,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "BRN1997",
        "yCap": 69660.19,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "BRN1998",
        "yCap": 67770.17,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "BRN1999",
        "yCap": 68366.66,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "BRN2000",
        "yCap": 68865.79,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "BRN2001",
        "yCap": 69338.92,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "BRN2002",
        "yCap": 70631.87,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "BRN2003",
        "yCap": 71331.75,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "BRN2004",
        "yCap": 70415.42,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "BRN2005",
        "yCap": 69482.38,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "BRN2006",
        "yCap": 71352.89,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "BRN2007",
        "yCap": 70342.41,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "BRN2008",
        "yCap": 67934.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "BRN2009",
        "yCap": 65753.41,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "BRN2010",
        "yCap": 66484.24,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "BRN2011",
        "yCap": 68037.52,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "BRN2012",
        "yCap": 67792.75,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "BRN2013",
        "yCap": 65534.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "BRN2014",
        "yCap": 63131.19,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "BRN2015",
        "yCap": 62170.09,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "BRN2016",
        "yCap": 59980.99,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "BRN2017",
        "yCap": 60173.05,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "BRN2018",
        "yCap": 59650.23,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "BRN2019",
        "yCap": 61424.36,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "BRN2020",
        "yCap": 61603.55,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "BRN2021",
        "yCap": 60127.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Brunei Darussalam",
        "Alpha-3": "BRN",
        "Alpha-2": "BN",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "BRN2022",
        "yCap": 60127.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "BGR1990",
        "yCap": 12507.73,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "BGR1991",
        "yCap": 11565.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "BGR1992",
        "yCap": 10840.09,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "BGR1993",
        "yCap": 10765.16,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "BGR1994",
        "yCap": 10998.16,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "BGR1995",
        "yCap": 11363.34,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "BGR1996",
        "yCap": 12016.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "BGR1997",
        "yCap": 10383.49,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "BGR1998",
        "yCap": 10849.09,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "BGR1999",
        "yCap": 9994.06,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "BGR2000",
        "yCap": 10504.26,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "BGR2001",
        "yCap": 11125.19,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "BGR2002",
        "yCap": 12036.92,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "BGR2003",
        "yCap": 12768.05,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "BGR2004",
        "yCap": 13702.34,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "BGR2005",
        "yCap": 14780.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "BGR2006",
        "yCap": 15905.88,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "BGR2007",
        "yCap": 17089.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "BGR2008",
        "yCap": 18264.78,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "BGR2009",
        "yCap": 17767.54,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "BGR2010",
        "yCap": 18160.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "BGR2011",
        "yCap": 18661.5,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "BGR2012",
        "yCap": 18911.61,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "BGR2013",
        "yCap": 18911.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "BGR2014",
        "yCap": 19202.83,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "BGR2015",
        "yCap": 19988.25,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "BGR2016",
        "yCap": 20740.82,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "BGR2017",
        "yCap": 21469.97,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "BGR2018",
        "yCap": 22206.16,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "BGR2019",
        "yCap": 23266.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "BGR2020",
        "yCap": 22479.58,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "BGR2021",
        "yCap": 24393.78,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Bulgaria",
        "Alpha-3": "BGR",
        "Alpha-2": "BG",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "BGR2022",
        "yCap": 24393.78,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "BFA1990",
        "yCap": 981.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "BFA1991",
        "yCap": 1044.17,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "BFA1992",
        "yCap": 1021.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "BFA1993",
        "yCap": 1030.56,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "BFA1994",
        "yCap": 1018.12,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "BFA1995",
        "yCap": 1049.08,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "BFA1996",
        "yCap": 1135.26,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "BFA1997",
        "yCap": 1176.38,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "BFA1998",
        "yCap": 1228.12,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "BFA1999",
        "yCap": 1280.92,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "BFA2000",
        "yCap": 1266.74,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "BFA2001",
        "yCap": 1310.07,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "BFA2002",
        "yCap": 1325.7,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "BFA2003",
        "yCap": 1385.46,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "BFA2004",
        "yCap": 1402.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "BFA2005",
        "yCap": 1477.04,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "BFA2006",
        "yCap": 1521.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "BFA2007",
        "yCap": 1536.39,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "BFA2008",
        "yCap": 1578.35,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "BFA2009",
        "yCap": 1578.15,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "BFA2010",
        "yCap": 1661.87,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "BFA2011",
        "yCap": 1720.08,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "BFA2012",
        "yCap": 1776.39,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "BFA2013",
        "yCap": 1823.59,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "BFA2014",
        "yCap": 1846.65,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "BFA2015",
        "yCap": 1862.85,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "BFA2016",
        "yCap": 1916.76,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "BFA2017",
        "yCap": 1978.16,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "BFA2018",
        "yCap": 2051.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "BFA2019",
        "yCap": 2110.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "BFA2020",
        "yCap": 2093.73,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "BFA2021",
        "yCap": 2179.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burkina Faso",
        "Alpha-3": "BFA",
        "Alpha-2": "BF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "BFA2022",
        "yCap": 2179.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "BDI1990",
        "yCap": 1175.16,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "BDI1991",
        "yCap": 1209.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "BDI1992",
        "yCap": 1190.07,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "BDI1993",
        "yCap": 1153.54,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "BDI1994",
        "yCap": 1103.17,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "BDI1995",
        "yCap": 956.49,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "BDI1996",
        "yCap": 880.31,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "BDI1997",
        "yCap": 867.28,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "BDI1998",
        "yCap": 891.7,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "BDI1999",
        "yCap": 862.01,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "BDI2000",
        "yCap": 837.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "BDI2001",
        "yCap": 833.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "BDI2002",
        "yCap": 846.75,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "BDI2003",
        "yCap": 810.55,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "BDI2004",
        "yCap": 818.75,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "BDI2005",
        "yCap": 796.11,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "BDI2006",
        "yCap": 809.7,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "BDI2007",
        "yCap": 807.45,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "BDI2008",
        "yCap": 812.59,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "BDI2009",
        "yCap": 801.8,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "BDI2010",
        "yCap": 804.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "BDI2011",
        "yCap": 807.66,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "BDI2012",
        "yCap": 814.32,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "BDI2013",
        "yCap": 824.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "BDI2014",
        "yCap": 831.29,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "BDI2015",
        "yCap": 781.58,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "BDI2016",
        "yCap": 764.34,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "BDI2017",
        "yCap": 750.79,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "BDI2018",
        "yCap": 740.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "BDI2019",
        "yCap": 729.66,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "BDI2020",
        "yCap": 711.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "BDI2021",
        "yCap": 705.03,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Burundi",
        "Alpha-3": "BDI",
        "Alpha-2": "BI",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "BDI2022",
        "yCap": 705.03,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "CPV1990",
        "yCap": 1620.98,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "CPV1991",
        "yCap": 1607.77,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "CPV1992",
        "yCap": 1740.54,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "CPV1993",
        "yCap": 1844.76,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "CPV1994",
        "yCap": 2144.41,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "CPV1995",
        "yCap": 2391.26,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "CPV1996",
        "yCap": 2601.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "CPV1997",
        "yCap": 2826.22,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "CPV1998",
        "yCap": 3110.93,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "CPV1999",
        "yCap": 3387.62,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "CPV2000",
        "yCap": 3798.68,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "CPV2001",
        "yCap": 3819.22,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "CPV2002",
        "yCap": 3957.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "CPV2003",
        "yCap": 4064.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "CPV2004",
        "yCap": 4419.05,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "CPV2005",
        "yCap": 4664.66,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "CPV2006",
        "yCap": 4975.92,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "CPV2007",
        "yCap": 5664.39,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "CPV2008",
        "yCap": 5974.78,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "CPV2009",
        "yCap": 5838.22,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "CPV2010",
        "yCap": 5860.5,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "CPV2011",
        "yCap": 6020.22,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "CPV2012",
        "yCap": 6013.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "CPV2013",
        "yCap": 5993.12,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "CPV2014",
        "yCap": 5962,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "CPV2015",
        "yCap": 5955.61,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "CPV2016",
        "yCap": 6166.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "CPV2017",
        "yCap": 6320.34,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "CPV2018",
        "yCap": 6534.46,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "CPV2019",
        "yCap": 6835.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "CPV2020",
        "yCap": 5768.54,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "CPV2021",
        "yCap": 6114.13,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cabo Verde",
        "Alpha-3": "CPV",
        "Alpha-2": "CV",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "CPV2022",
        "yCap": 6114.13,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "KHM1990",
        "yCap": 1716.84,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "KHM1991",
        "yCap": 1716.84,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "KHM1992",
        "yCap": 1716.84,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "KHM1993",
        "yCap": 1716.84,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "KHM1994",
        "yCap": 1077.9,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "KHM1995",
        "yCap": 1153.93,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "KHM1996",
        "yCap": 1193.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "KHM1997",
        "yCap": 1214.01,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "KHM1998",
        "yCap": 1244.98,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "KHM1999",
        "yCap": 1376.05,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "KHM2000",
        "yCap": 1486.11,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "KHM2001",
        "yCap": 1578.63,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "KHM2002",
        "yCap": 1652.54,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "KHM2003",
        "yCap": 1761.42,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "KHM2004",
        "yCap": 1909.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "KHM2005",
        "yCap": 2124.84,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "KHM2006",
        "yCap": 2313.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "KHM2007",
        "yCap": 2505.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "KHM2008",
        "yCap": 2629.26,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "KHM2009",
        "yCap": 2592.16,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "KHM2010",
        "yCap": 2706.99,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "KHM2011",
        "yCap": 2856.53,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "KHM2012",
        "yCap": 3021.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "KHM2013",
        "yCap": 3197.53,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "KHM2014",
        "yCap": 3378.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "KHM2015",
        "yCap": 3565.25,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "KHM2016",
        "yCap": 3761.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "KHM2017",
        "yCap": 3972.72,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "KHM2018",
        "yCap": 4217.62,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "KHM2019",
        "yCap": 4464.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "KHM2020",
        "yCap": 4276.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "KHM2021",
        "yCap": 4354.57,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cambodia",
        "Alpha-3": "KHM",
        "Alpha-2": "KH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "KHM2022",
        "yCap": 4354.57,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "CMR1990",
        "yCap": 3483.19,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "CMR1991",
        "yCap": 3251.76,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "CMR1992",
        "yCap": 3059.79,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "CMR1993",
        "yCap": 2736.28,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "CMR1994",
        "yCap": 2709.08,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "CMR1995",
        "yCap": 2710.94,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "CMR1996",
        "yCap": 2749.52,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "CMR1997",
        "yCap": 2798.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "CMR1998",
        "yCap": 2854.37,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "CMR1999",
        "yCap": 2905.13,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "CMR2000",
        "yCap": 2937.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "CMR2001",
        "yCap": 2985.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "CMR2002",
        "yCap": 3036.77,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "CMR2003",
        "yCap": 3116.15,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "CMR2004",
        "yCap": 3245.5,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "CMR2005",
        "yCap": 3228.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "CMR2006",
        "yCap": 3261.45,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "CMR2007",
        "yCap": 3309.28,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "CMR2008",
        "yCap": 3308.32,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "CMR2009",
        "yCap": 3298.41,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "CMR2010",
        "yCap": 3298.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "CMR2011",
        "yCap": 3314.9,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "CMR2012",
        "yCap": 3371.98,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "CMR2013",
        "yCap": 3442.2,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "CMR2014",
        "yCap": 3530.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "CMR2015",
        "yCap": 3614.76,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "CMR2016",
        "yCap": 3667.32,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "CMR2017",
        "yCap": 3691.09,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "CMR2018",
        "yCap": 3732.5,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "CMR2019",
        "yCap": 3756.51,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "CMR2020",
        "yCap": 3665.51,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "CMR2021",
        "yCap": 3700.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cameroon",
        "Alpha-3": "CMR",
        "Alpha-2": "CM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "CMR2022",
        "yCap": 3700.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1990,
        "ID": "CAN1990",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1991,
        "ID": "CAN1991",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1992,
        "ID": "CAN1992",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1993,
        "ID": "CAN1993",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1994,
        "ID": "CAN1994",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1995,
        "ID": "CAN1995",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1996,
        "ID": "CAN1996",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1997,
        "ID": "CAN1997",
        "yCap": 36910.48,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1998,
        "ID": "CAN1998",
        "yCap": 38031.62,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1999,
        "ID": "CAN1999",
        "yCap": 39671.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2000,
        "ID": "CAN2000",
        "yCap": 41338.65,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2001,
        "ID": "CAN2001",
        "yCap": 41623.95,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2002,
        "ID": "CAN2002",
        "yCap": 42416.41,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2003,
        "ID": "CAN2003",
        "yCap": 42793.08,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2004,
        "ID": "CAN2004",
        "yCap": 43704.41,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2005,
        "ID": "CAN2005",
        "yCap": 44680.8,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2006,
        "ID": "CAN2006",
        "yCap": 45396.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2007,
        "ID": "CAN2007",
        "yCap": 45888.48,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2008,
        "ID": "CAN2008",
        "yCap": 45851.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2009,
        "ID": "CAN2009",
        "yCap": 44003.62,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2010,
        "ID": "CAN2010",
        "yCap": 44861.52,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2011,
        "ID": "CAN2011",
        "yCap": 45822.6,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2012,
        "ID": "CAN2012",
        "yCap": 46126.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2013,
        "ID": "CAN2013",
        "yCap": 46704.76,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2014,
        "ID": "CAN2014",
        "yCap": 47564.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2015,
        "ID": "CAN2015",
        "yCap": 47522.14,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2016,
        "ID": "CAN2016",
        "yCap": 47457.59,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2017,
        "ID": "CAN2017",
        "yCap": 48317.17,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2018,
        "ID": "CAN2018",
        "yCap": 48962.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2019,
        "ID": "CAN2019",
        "yCap": 49171.51,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2020,
        "ID": "CAN2020",
        "yCap": 46064.25,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2021,
        "ID": "CAN2021",
        "yCap": 47892.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Canada",
        "Alpha-3": "CAN",
        "Alpha-2": "CA",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2022,
        "ID": "CAN2022",
        "yCap": 47892.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "CYM1990",
        "yCap": 84778.38,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "CYM1991",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "CYM1992",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "CYM1993",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "CYM1994",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "CYM1995",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "CYM1996",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "CYM1997",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "CYM1998",
        "yCap": 84778.38,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "CYM1999",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "CYM2000",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "CYM2001",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "CYM2002",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "CYM2003",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "CYM2004",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "CYM2005",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "CYM2006",
        "yCap": 84778.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "CYM2007",
        "yCap": 84870.01,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "CYM2008",
        "yCap": 82125.11,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "CYM2009",
        "yCap": 74068.88,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "CYM2010",
        "yCap": 70095.9,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "CYM2011",
        "yCap": 69103.24,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "CYM2012",
        "yCap": 68270.04,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "CYM2013",
        "yCap": 67537.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "CYM2014",
        "yCap": 67762.36,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "CYM2015",
        "yCap": 68135.46,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "CYM2016",
        "yCap": 68824.43,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "CYM2017",
        "yCap": 69534.77,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "CYM2018",
        "yCap": 71046.02,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "CYM2019",
        "yCap": 72402.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "CYM2020",
        "yCap": 67093.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "CYM2021",
        "yCap": 67500.1,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cayman Islands",
        "Alpha-3": "CYM",
        "Alpha-2": "KY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "CYM2022",
        "yCap": 67500.1,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "CAF1990",
        "yCap": 1200.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "CAF1991",
        "yCap": 1157.38,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "CAF1992",
        "yCap": 1049.12,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "CAF1993",
        "yCap": 1019.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "CAF1994",
        "yCap": 1036.28,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "CAF1995",
        "yCap": 1080.77,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "CAF1996",
        "yCap": 1010.74,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "CAF1997",
        "yCap": 1035.11,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "CAF1998",
        "yCap": 1054.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "CAF1999",
        "yCap": 1062.3,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "CAF2000",
        "yCap": 1007.25,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "CAF2001",
        "yCap": 1028.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "CAF2002",
        "yCap": 1042.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "CAF2003",
        "yCap": 962.86,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "CAF2004",
        "yCap": 998.69,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "CAF2005",
        "yCap": 985.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "CAF2006",
        "yCap": 1011.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "CAF2007",
        "yCap": 1038.75,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "CAF2008",
        "yCap": 1038.34,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "CAF2009",
        "yCap": 1103.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "CAF2010",
        "yCap": 1130.9,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "CAF2011",
        "yCap": 1160.41,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "CAF2012",
        "yCap": 1208.52,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "CAF2013",
        "yCap": 764.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "CAF2014",
        "yCap": 765.26,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "CAF2015",
        "yCap": 795.04,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "CAF2016",
        "yCap": 818.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "CAF2017",
        "yCap": 839.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "CAF2018",
        "yCap": 854.65,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "CAF2019",
        "yCap": 861.77,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "CAF2020",
        "yCap": 847.77,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "CAF2021",
        "yCap": 837.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Central African Republic",
        "Alpha-3": "CAF",
        "Alpha-2": "CF",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "CAF2022",
        "yCap": 837.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "TCD1990",
        "yCap": 1027.24,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "TCD1991",
        "yCap": 1075.18,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "TCD1992",
        "yCap": 1113.22,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "TCD1993",
        "yCap": 917.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "TCD1994",
        "yCap": 987.99,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "TCD1995",
        "yCap": 957.25,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "TCD1996",
        "yCap": 936.12,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "TCD1997",
        "yCap": 955.78,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "TCD1998",
        "yCap": 987.98,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "TCD1999",
        "yCap": 947.7,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "TCD2000",
        "yCap": 907.83,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "TCD2001",
        "yCap": 980.47,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "TCD2002",
        "yCap": 1027.67,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "TCD2003",
        "yCap": 1133.07,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "TCD2004",
        "yCap": 1448.41,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "TCD2005",
        "yCap": 1632.95,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "TCD2006",
        "yCap": 1586.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "TCD2007",
        "yCap": 1583.7,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "TCD2008",
        "yCap": 1576.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "TCD2009",
        "yCap": 1586.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "TCD2010",
        "yCap": 1741.05,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "TCD2011",
        "yCap": 1682.66,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "TCD2012",
        "yCap": 1769.32,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "TCD2013",
        "yCap": 1804.82,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "TCD2014",
        "yCap": 1861.69,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "TCD2015",
        "yCap": 1853.26,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "TCD2016",
        "yCap": 1683.48,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "TCD2017",
        "yCap": 1579.76,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "TCD2018",
        "yCap": 1563.54,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "TCD2019",
        "yCap": 1562,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "TCD2020",
        "yCap": 1489.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "TCD2021",
        "yCap": 1425.49,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Chad",
        "Alpha-3": "TCD",
        "Alpha-2": "TD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "TCD2022",
        "yCap": 1425.49,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "CHI1990",
        "yCap": 21546.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "CHI1991",
        "yCap": 21372.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "CHI1992",
        "yCap": 21081.84,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "CHI1993",
        "yCap": 20893.97,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "CHI1994",
        "yCap": 21098.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "CHI1995",
        "yCap": 21483.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "CHI1996",
        "yCap": 21981.24,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "CHI1997",
        "yCap": 22717.27,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "CHI1998",
        "yCap": 23548.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "CHI1999",
        "yCap": 24300.48,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "CHI2000",
        "yCap": 25310.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "CHI2001",
        "yCap": 25961.38,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "CHI2002",
        "yCap": 26468.77,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "CHI2003",
        "yCap": 27049.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "CHI2004",
        "yCap": 28109.83,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "CHI2005",
        "yCap": 29015.44,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "CHI2006",
        "yCap": 30295.56,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "CHI2007",
        "yCap": 31705.85,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "CHI2008",
        "yCap": 31930.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "CHI2009",
        "yCap": 30195.77,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "CHI2010",
        "yCap": 30589.07,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "CHI2011",
        "yCap": 30918.19,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "CHI2012",
        "yCap": 30801.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "CHI2013",
        "yCap": 31011.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "CHI2014",
        "yCap": 31496.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "CHI2015",
        "yCap": 32309.12,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "CHI2016",
        "yCap": 32968.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "CHI2017",
        "yCap": 33882.6,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "CHI2018",
        "yCap": 34759.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "CHI2019",
        "yCap": 35523.11,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "CHI2020",
        "yCap": 34038.75,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "CHI2021",
        "yCap": 36006.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Channel Islands",
        "Alpha-3": "CHI",
        "Alpha-2": "JE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "CHI2022",
        "yCap": 36006.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "CHL1990",
        "yCap": 9701.82,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "CHL1991",
        "yCap": 10290.04,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "CHL1992",
        "yCap": 11256.21,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "CHL1993",
        "yCap": 11812.65,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "CHL1994",
        "yCap": 12221.51,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "CHL1995",
        "yCap": 13122.95,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "CHL1996",
        "yCap": 13825.16,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "CHL1997",
        "yCap": 14652.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "CHL1998",
        "yCap": 15074.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "CHL1999",
        "yCap": 14855.36,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "CHL2000",
        "yCap": 15415.75,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "CHL2001",
        "yCap": 15725.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "CHL2002",
        "yCap": 16053.65,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "CHL2003",
        "yCap": 16636.65,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "CHL2004",
        "yCap": 17571.02,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "CHL2005",
        "yCap": 18415.75,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "CHL2006",
        "yCap": 19339.49,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "CHL2007",
        "yCap": 20140.53,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "CHL2008",
        "yCap": 20695.56,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "CHL2009",
        "yCap": 20255.1,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "CHL2010",
        "yCap": 21225.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "CHL2011",
        "yCap": 22323.72,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "CHL2012",
        "yCap": 23467.98,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "CHL2013",
        "yCap": 24011.59,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "CHL2014",
        "yCap": 24197.18,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "CHL2015",
        "yCap": 24464.75,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "CHL2016",
        "yCap": 24599.37,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "CHL2017",
        "yCap": 24546.91,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "CHL2018",
        "yCap": 25071.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "CHL2019",
        "yCap": 24816.61,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "CHL2020",
        "yCap": 23017.69,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "CHL2021",
        "yCap": 25449.13,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Chile",
        "Alpha-3": "CHL",
        "Alpha-2": "CL",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "CHL2022",
        "yCap": 25449.13,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "CHN1990",
        "yCap": 1423.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "CHN1991",
        "yCap": 1534.71,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "CHN1992",
        "yCap": 1731.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "CHN1993",
        "yCap": 1949.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "CHN1994",
        "yCap": 2178.92,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "CHN1995",
        "yCap": 2391.48,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "CHN1996",
        "yCap": 2601.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "CHN1997",
        "yCap": 2812.71,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "CHN1998",
        "yCap": 3004.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "CHN1999",
        "yCap": 3206.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "CHN2000",
        "yCap": 3451.68,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "CHN2001",
        "yCap": 3712.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "CHN2002",
        "yCap": 4024.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "CHN2003",
        "yCap": 4400.83,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "CHN2004",
        "yCap": 4817.21,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "CHN2005",
        "yCap": 5334.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "CHN2006",
        "yCap": 5979.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "CHN2007",
        "yCap": 6795.17,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "CHN2008",
        "yCap": 7412.87,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "CHN2009",
        "yCap": 8069.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 0,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "CHN2010",
        "yCap": 8884.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "CHN2011",
        "yCap": 9680.1,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "CHN2012",
        "yCap": 10370.73,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "CHN2013",
        "yCap": 11101.94,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "CHN2014",
        "yCap": 11851.4,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "CHN2015",
        "yCap": 12612.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "CHN2016",
        "yCap": 13399.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "CHN2017",
        "yCap": 14243.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "CHN2018",
        "yCap": 15134,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "CHN2019",
        "yCap": 15977.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "CHN2020",
        "yCap": 16296.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "CHN2021",
        "yCap": 17602.7,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "China",
        "Alpha-3": "CHN",
        "Alpha-2": "CN",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "CHN2022",
        "yCap": 17602.7,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "COL1990",
        "yCap": 8434.96,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "COL1991",
        "yCap": 8430.22,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "COL1992",
        "yCap": 8598.99,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "COL1993",
        "yCap": 8885.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "COL1994",
        "yCap": 9220.5,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "COL1995",
        "yCap": 9518.26,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "COL1996",
        "yCap": 9538.27,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "COL1997",
        "yCap": 9691.03,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "COL1998",
        "yCap": 9578.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "COL1999",
        "yCap": 9023.62,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "COL2000",
        "yCap": 9138.32,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "COL2001",
        "yCap": 9146.4,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "COL2002",
        "yCap": 9232.63,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "COL2003",
        "yCap": 9453.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "COL2004",
        "yCap": 9816.28,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "COL2005",
        "yCap": 10150.71,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "COL2006",
        "yCap": 10692.73,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "COL2007",
        "yCap": 11272.58,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "COL2008",
        "yCap": 11507.53,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "COL2009",
        "yCap": 11507.72,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "COL2010",
        "yCap": 11890.2,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "COL2011",
        "yCap": 12578.02,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "COL2012",
        "yCap": 12934.97,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "COL2013",
        "yCap": 13465.08,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "COL2014",
        "yCap": 13938.23,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "COL2015",
        "yCap": 14215.69,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "COL2016",
        "yCap": 14358.17,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "COL2017",
        "yCap": 14334.91,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "COL2018",
        "yCap": 14426.43,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "COL2019",
        "yCap": 14616.14,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "COL2020",
        "yCap": 13387.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "COL2021",
        "yCap": 14648.59,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Colombia",
        "Alpha-3": "COL",
        "Alpha-2": "CO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "COL2022",
        "yCap": 14648.59,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "COM1990",
        "yCap": 2922.71,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "COM1991",
        "yCap": 2695.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "COM1992",
        "yCap": 2856.01,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "COM1993",
        "yCap": 2876.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "COM1994",
        "yCap": 2666.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "COM1995",
        "yCap": 2703.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "COM1996",
        "yCap": 2611.82,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "COM1997",
        "yCap": 2659.27,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "COM1998",
        "yCap": 2636.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "COM1999",
        "yCap": 2633.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "COM2000",
        "yCap": 2863.15,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "COM2001",
        "yCap": 2871.2,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "COM2002",
        "yCap": 2878.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "COM2003",
        "yCap": 2881.96,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "COM2004",
        "yCap": 2881.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "COM2005",
        "yCap": 2905.69,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "COM2006",
        "yCap": 2923.53,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "COM2007",
        "yCap": 2888.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "COM2008",
        "yCap": 2942.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "COM2009",
        "yCap": 2976.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "COM2010",
        "yCap": 3025.52,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "COM2011",
        "yCap": 3084.83,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "COM2012",
        "yCap": 3115.24,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "COM2013",
        "yCap": 3185.32,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "COM2014",
        "yCap": 3183.16,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "COM2015",
        "yCap": 3150.88,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "COM2016",
        "yCap": 3185.63,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "COM2017",
        "yCap": 3240.18,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "COM2018",
        "yCap": 3294.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "COM2019",
        "yCap": 3290.65,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "COM2020",
        "yCap": 3222.37,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "COM2021",
        "yCap": 3228.53,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Comoros",
        "Alpha-3": "COM",
        "Alpha-2": "KM",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "COM2022",
        "yCap": 3228.53,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "COD1990",
        "yCap": 1750.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "COD1991",
        "yCap": 1550.76,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "COD1992",
        "yCap": 1346.75,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "COD1993",
        "yCap": 1128.59,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "COD1994",
        "yCap": 1034.12,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "COD1995",
        "yCap": 998.69,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "COD1996",
        "yCap": 969.81,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "COD1997",
        "yCap": 900.97,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "COD1998",
        "yCap": 865.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "COD1999",
        "yCap": 805.28,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "COD2000",
        "yCap": 728.21,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "COD2001",
        "yCap": 691.71,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "COD2002",
        "yCap": 690.66,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "COD2003",
        "yCap": 708.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "COD2004",
        "yCap": 733.54,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "COD2005",
        "yCap": 754.66,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "COD2006",
        "yCap": 769.89,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "COD2007",
        "yCap": 792.19,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "COD2008",
        "yCap": 815.01,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "COD2009",
        "yCap": 811.93,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "COD2010",
        "yCap": 841.86,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "COD2011",
        "yCap": 870.07,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "COD2012",
        "yCap": 900.98,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "COD2013",
        "yCap": 944.64,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "COD2014",
        "yCap": 999.07,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "COD2015",
        "yCap": 1032.57,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "COD2016",
        "yCap": 1021.33,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "COD2017",
        "yCap": 1023.54,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "COD2018",
        "yCap": 1048.25,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "COD2019",
        "yCap": 1059.89,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "COD2020",
        "yCap": 1044.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "COD2021",
        "yCap": 1073.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Dem. Rep.",
        "Alpha-3": "COD",
        "Alpha-2": "CD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "COD2022",
        "yCap": 1073.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "COG1990",
        "yCap": 5007.32,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "COG1991",
        "yCap": 4986.7,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "COG1992",
        "yCap": 4978.35,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "COG1993",
        "yCap": 4790.46,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "COG1994",
        "yCap": 4400.02,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "COG1995",
        "yCap": 4453.43,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "COG1996",
        "yCap": 4522.01,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "COG1997",
        "yCap": 4402.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "COG1998",
        "yCap": 4470.35,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "COG1999",
        "yCap": 4240.16,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "COG2000",
        "yCap": 4390.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "COG2001",
        "yCap": 4389.35,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "COG2002",
        "yCap": 4484.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "COG2003",
        "yCap": 4397.33,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "COG2004",
        "yCap": 4398.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "COG2005",
        "yCap": 4571.79,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "COG2006",
        "yCap": 4755.03,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "COG2007",
        "yCap": 4280.02,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "COG2008",
        "yCap": 4401.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "COG2009",
        "yCap": 4720.41,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "COG2010",
        "yCap": 4977.97,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "COG2011",
        "yCap": 4925.38,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "COG2012",
        "yCap": 5267.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "COG2013",
        "yCap": 5105.17,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "COG2014",
        "yCap": 5319.39,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "COG2015",
        "yCap": 5009.43,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "COG2016",
        "yCap": 4363.75,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "COG2017",
        "yCap": 4073.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "COG2018",
        "yCap": 3786.41,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "COG2019",
        "yCap": 3695.06,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "COG2020",
        "yCap": 3384.65,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "COG2021",
        "yCap": 3234.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Congo, Rep.",
        "Alpha-3": "COG",
        "Alpha-2": "CG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "COG2022",
        "yCap": 3234.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "CRI1990",
        "yCap": 9810.55,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "CRI1991",
        "yCap": 9781.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "CRI1992",
        "yCap": 10416.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "CRI1993",
        "yCap": 10882.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "CRI1994",
        "yCap": 11100.71,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "CRI1995",
        "yCap": 11290.96,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "CRI1996",
        "yCap": 11182.48,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "CRI1997",
        "yCap": 11532.46,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "CRI1998",
        "yCap": 12092.48,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "CRI1999",
        "yCap": 12343.71,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "CRI2000",
        "yCap": 12570.7,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "CRI2001",
        "yCap": 12771.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "CRI2002",
        "yCap": 12986,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "CRI2003",
        "yCap": 13333.22,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "CRI2004",
        "yCap": 13713.01,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "CRI2005",
        "yCap": 14049.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "CRI2006",
        "yCap": 14864.78,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "CRI2007",
        "yCap": 15861.87,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "CRI2008",
        "yCap": 16385,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "CRI2009",
        "yCap": 16024.03,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "CRI2010",
        "yCap": 16667.02,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "CRI2011",
        "yCap": 17186.45,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "CRI2012",
        "yCap": 17809.94,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "CRI2013",
        "yCap": 18044.95,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "CRI2014",
        "yCap": 18480.65,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "CRI2015",
        "yCap": 18956.19,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "CRI2016",
        "yCap": 19553.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "CRI2017",
        "yCap": 20168.22,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "CRI2018",
        "yCap": 20503.28,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "CRI2019",
        "yCap": 20818.06,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "CRI2020",
        "yCap": 19824.35,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "CRI2021",
        "yCap": 21199.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Costa Rica",
        "Alpha-3": "CRI",
        "Alpha-2": "CR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "CRI2022",
        "yCap": 21199.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "CIV1990",
        "yCap": 4276.71,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "CIV1991",
        "yCap": 4119.78,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "CIV1992",
        "yCap": 3959.56,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "CIV1993",
        "yCap": 3810.04,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "CIV1994",
        "yCap": 3705.75,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "CIV1995",
        "yCap": 3831.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "CIV1996",
        "yCap": 3985.4,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "CIV1997",
        "yCap": 4220.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "CIV1998",
        "yCap": 4255.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "CIV1999",
        "yCap": 4176.6,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "CIV2000",
        "yCap": 4052.98,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "CIV2001",
        "yCap": 3863.17,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "CIV2002",
        "yCap": 3664.52,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "CIV2003",
        "yCap": 3407.98,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "CIV2004",
        "yCap": 3435.28,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "CIV2005",
        "yCap": 3391.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "CIV2006",
        "yCap": 3411,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "CIV2007",
        "yCap": 3374.85,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "CIV2008",
        "yCap": 3461.71,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "CIV2009",
        "yCap": 3511.29,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "CIV2010",
        "yCap": 3673.18,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "CIV2011",
        "yCap": 3404.52,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "CIV2012",
        "yCap": 3589.42,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "CIV2013",
        "yCap": 3894.52,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "CIV2014",
        "yCap": 4162.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "CIV2015",
        "yCap": 4347.78,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "CIV2016",
        "yCap": 4541.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "CIV2017",
        "yCap": 4750.94,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "CIV2018",
        "yCap": 4949.61,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "CIV2019",
        "yCap": 5126.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "CIV2020",
        "yCap": 5098.56,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "CIV2021",
        "yCap": 5325.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Cote d'Ivoire",
        "Alpha-3": "CIV",
        "Alpha-2": "CI",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "CIV2022",
        "yCap": 5325.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "HRV1990",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "HRV1991",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "HRV1992",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "HRV1993",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "HRV1994",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "HRV1995",
        "yCap": 15070.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "HRV1996",
        "yCap": 16234.41,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "HRV1997",
        "yCap": 17320.88,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "HRV1998",
        "yCap": 17734.51,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "HRV1999",
        "yCap": 17678.39,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "HRV2000",
        "yCap": 18364.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "HRV2001",
        "yCap": 19672.2,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "HRV2002",
        "yCap": 20793.76,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "HRV2003",
        "yCap": 21944.43,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "HRV2004",
        "yCap": 22849.65,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "HRV2005",
        "yCap": 23793.05,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "HRV2006",
        "yCap": 24949.94,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "HRV2007",
        "yCap": 26203.43,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "HRV2008",
        "yCap": 26731.17,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "HRV2009",
        "yCap": 24834.34,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "HRV2010",
        "yCap": 24586.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "HRV2011",
        "yCap": 24649.8,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "HRV2012",
        "yCap": 24148.75,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "HRV2013",
        "yCap": 24119.76,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "HRV2014",
        "yCap": 24114.22,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "HRV2015",
        "yCap": 24927.03,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "HRV2016",
        "yCap": 25995.19,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "HRV2017",
        "yCap": 27206.97,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "HRV2018",
        "yCap": 28219.96,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "HRV2019",
        "yCap": 29347.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "HRV2020",
        "yCap": 26945.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "HRV2021",
        "yCap": 31630.18,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Croatia",
        "Alpha-3": "HRV",
        "Alpha-2": "HR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "HRV2022",
        "yCap": 31630.18,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "CUB1990",
        "yCap": 14458,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "CUB1991",
        "yCap": 14562.97,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "CUB1992",
        "yCap": 14756.28,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "CUB1993",
        "yCap": 14965.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "CUB1994",
        "yCap": 15230.22,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "CUB1995",
        "yCap": 15335.78,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "CUB1996",
        "yCap": 15545.85,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "CUB1997",
        "yCap": 15961.58,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "CUB1998",
        "yCap": 16132.18,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "CUB1999",
        "yCap": 16282.35,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "CUB2000",
        "yCap": 16566.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "CUB2001",
        "yCap": 16630.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "CUB2002",
        "yCap": 16649.59,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "CUB2003",
        "yCap": 16884.42,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "CUB2004",
        "yCap": 17359.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "CUB2005",
        "yCap": 17714.1,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "CUB2006",
        "yCap": 18214.89,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "CUB2007",
        "yCap": 18690.28,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "CUB2008",
        "yCap": 18915.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "CUB2009",
        "yCap": 18152.51,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "CUB2010",
        "yCap": 18305.2,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "CUB2011",
        "yCap": 18677.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "CUB2012",
        "yCap": 18833.91,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "CUB2013",
        "yCap": 19012.88,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "CUB2014",
        "yCap": 19274.6,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "CUB2015",
        "yCap": 19451.33,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "CUB2016",
        "yCap": 19564.52,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "CUB2017",
        "yCap": 19627.35,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "CUB2018",
        "yCap": 19828.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "CUB2019",
        "yCap": 19983.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "CUB2020",
        "yCap": 17800.85,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "CUB2021",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cuba",
        "Alpha-3": "CUB",
        "Alpha-2": "CU",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "CUB2022",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "CUW1990",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "CUW1991",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "CUW1992",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "CUW1993",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "CUW1994",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "CUW1995",
        "yCap": 28613.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "CUW1996",
        "yCap": 28613.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "CUW1997",
        "yCap": 28613.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "CUW1998",
        "yCap": 28613.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "CUW1999",
        "yCap": 28613.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "CUW2000",
        "yCap": 28613.9,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "CUW2001",
        "yCap": 29474.59,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "CUW2002",
        "yCap": 29555.44,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "CUW2003",
        "yCap": 29035.65,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "CUW2004",
        "yCap": 28598.57,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "CUW2005",
        "yCap": 28067.08,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "CUW2006",
        "yCap": 27802.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "CUW2007",
        "yCap": 27932.59,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "CUW2008",
        "yCap": 28186.87,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "CUW2009",
        "yCap": 27855.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "CUW2010",
        "yCap": 27527.49,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "CUW2011",
        "yCap": 27305.37,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "CUW2012",
        "yCap": 27061.85,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "CUW2013",
        "yCap": 26539.61,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "CUW2014",
        "yCap": 25869.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "CUW2015",
        "yCap": 25604.93,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "CUW2016",
        "yCap": 25113.7,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "CUW2017",
        "yCap": 24599.94,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "CUW2018",
        "yCap": 24193.61,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "CUW2019",
        "yCap": 23659.23,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "CUW2020",
        "yCap": 19607.43,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "CUW2021",
        "yCap": 20783.09,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Curacao",
        "Alpha-3": "CUW",
        "Alpha-2": "CW",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "CUW2022",
        "yCap": 20783.09,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "CYP1990",
        "yCap": 25232.61,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "CYP1991",
        "yCap": 24769.66,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "CYP1992",
        "yCap": 26386.58,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "CYP1993",
        "yCap": 25937.31,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "CYP1994",
        "yCap": 26905.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "CYP1995",
        "yCap": 28631.47,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "CYP1996",
        "yCap": 28530.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "CYP1997",
        "yCap": 28871.87,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "CYP1998",
        "yCap": 30262.84,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "CYP1999",
        "yCap": 31421.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "CYP2000",
        "yCap": 32943.91,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "CYP2001",
        "yCap": 33878.89,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "CYP2002",
        "yCap": 34739.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "CYP2003",
        "yCap": 35220.45,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "CYP2004",
        "yCap": 36499.23,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "CYP2005",
        "yCap": 37723.33,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "CYP2006",
        "yCap": 38847.98,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "CYP2007",
        "yCap": 39968.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "CYP2008",
        "yCap": 40398.68,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "CYP2009",
        "yCap": 38536.04,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "CYP2010",
        "yCap": 38398.55,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "CYP2011",
        "yCap": 37587.21,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "CYP2012",
        "yCap": 35742.67,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "CYP2013",
        "yCap": 33465.83,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "CYP2014",
        "yCap": 33235.26,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "CYP2015",
        "yCap": 34567.8,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "CYP2016",
        "yCap": 36671.55,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "CYP2017",
        "yCap": 38415.11,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "CYP2018",
        "yCap": 40092.68,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "CYP2019",
        "yCap": 41739.46,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "CYP2020",
        "yCap": 39464.54,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "CYP2021",
        "yCap": 41694.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Cyprus",
        "Alpha-3": "CYP",
        "Alpha-2": "CY",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "CYP2022",
        "yCap": 41694.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "CZE1990",
        "yCap": 23585.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "CZE1991",
        "yCap": 20895.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "CZE1992",
        "yCap": 20768.79,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "CZE1993",
        "yCap": 20760.05,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "CZE1994",
        "yCap": 21356.31,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "CZE1995",
        "yCap": 22758.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "CZE1996",
        "yCap": 23756.98,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "CZE1997",
        "yCap": 23659.32,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "CZE1998",
        "yCap": 23597.31,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "CZE1999",
        "yCap": 23948.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "CZE2000",
        "yCap": 24976.51,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "CZE2001",
        "yCap": 25833.41,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "CZE2002",
        "yCap": 26289.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "CZE2003",
        "yCap": 27239.56,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "CZE2004",
        "yCap": 28542.25,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "CZE2005",
        "yCap": 30384.45,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "CZE2006",
        "yCap": 32352.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "CZE2007",
        "yCap": 33956.25,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "CZE2008",
        "yCap": 34580.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "CZE2009",
        "yCap": 32782.61,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "CZE2010",
        "yCap": 33483.14,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "CZE2011",
        "yCap": 34002.19,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "CZE2012",
        "yCap": 33688.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "CZE2013",
        "yCap": 33661.47,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "CZE2014",
        "yCap": 34386.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "CZE2015",
        "yCap": 36168.42,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "CZE2016",
        "yCap": 37014.96,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "CZE2017",
        "yCap": 38824.89,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "CZE2018",
        "yCap": 39941.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "CZE2019",
        "yCap": 40989.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "CZE2020",
        "yCap": 38639.98,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "CZE2021",
        "yCap": 40740.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Czechia",
        "Alpha-3": "CZE",
        "Alpha-2": "CZ",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "CZE2022",
        "yCap": 40740.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "DNK1990",
        "yCap": 39027.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "DNK1991",
        "yCap": 39469.32,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "DNK1992",
        "yCap": 40108.89,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "DNK1993",
        "yCap": 39979.76,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "DNK1994",
        "yCap": 41969.69,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "DNK1995",
        "yCap": 43015.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "DNK1996",
        "yCap": 44013.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "DNK1997",
        "yCap": 45260.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "DNK1998",
        "yCap": 46096.37,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "DNK1999",
        "yCap": 47298.54,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "DNK2000",
        "yCap": 48907.01,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "DNK2001",
        "yCap": 49133.22,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "DNK2002",
        "yCap": 49204.9,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "DNK2003",
        "yCap": 49262.64,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "DNK2004",
        "yCap": 50446.54,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "DNK2005",
        "yCap": 51483.27,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "DNK2006",
        "yCap": 53322.29,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "DNK2007",
        "yCap": 53569.03,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "DNK2008",
        "yCap": 52982.53,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "DNK2009",
        "yCap": 50114.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "DNK2010",
        "yCap": 50825.41,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "DNK2011",
        "yCap": 51293.21,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "DNK2012",
        "yCap": 51216.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "DNK2013",
        "yCap": 51479.27,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "DNK2014",
        "yCap": 52048.34,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "DNK2015",
        "yCap": 52892.65,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "DNK2016",
        "yCap": 54185.01,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "DNK2017",
        "yCap": 55356.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "DNK2018",
        "yCap": 56178.77,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "DNK2019",
        "yCap": 56813.96,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "DNK2020",
        "yCap": 55518.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "DNK2021",
        "yCap": 57962.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Denmark",
        "Alpha-3": "DNK",
        "Alpha-2": "DK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "DNK2022",
        "yCap": 57962.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "DJI1990",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "DJI1991",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "DJI1992",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "DJI1993",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "DJI1994",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "DJI1995",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "DJI1996",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "DJI1997",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "DJI1998",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "DJI1999",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "DJI2000",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "DJI2001",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "DJI2002",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "DJI2003",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "DJI2004",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "DJI2005",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "DJI2006",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "DJI2007",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "DJI2008",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "DJI2009",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "DJI2010",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "DJI2011",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "DJI2012",
        "yCap": 3664.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "DJI2013",
        "yCap": 3664.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "DJI2014",
        "yCap": 3854.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "DJI2015",
        "yCap": 4073.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "DJI2016",
        "yCap": 4291.3,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "DJI2017",
        "yCap": 4451.68,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "DJI2018",
        "yCap": 4589.38,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "DJI2019",
        "yCap": 4768.11,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "DJI2020",
        "yCap": 4753.89,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "DJI2021",
        "yCap": 4913.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Djibouti",
        "Alpha-3": "DJI",
        "Alpha-2": "DJ",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "DJI2022",
        "yCap": 4913.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "DMA1990",
        "yCap": 7855.82,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "DMA1991",
        "yCap": 8000.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "DMA1992",
        "yCap": 8166.65,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "DMA1993",
        "yCap": 8336.29,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "DMA1994",
        "yCap": 8335.67,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "DMA1995",
        "yCap": 8587.94,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "DMA1996",
        "yCap": 8859,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "DMA1997",
        "yCap": 9063.77,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "DMA1998",
        "yCap": 9425.37,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "DMA1999",
        "yCap": 9491.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "DMA2000",
        "yCap": 9763.94,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "DMA2001",
        "yCap": 9785.34,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "DMA2002",
        "yCap": 9493.43,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "DMA2003",
        "yCap": 10070,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "DMA2004",
        "yCap": 10357.23,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "DMA2005",
        "yCap": 10409.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "DMA2006",
        "yCap": 10884.25,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "DMA2007",
        "yCap": 11570.17,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "DMA2008",
        "yCap": 12392.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "DMA2009",
        "yCap": 12246.99,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "DMA2010",
        "yCap": 12335.11,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "DMA2011",
        "yCap": 12309.86,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "DMA2012",
        "yCap": 12153.69,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "DMA2013",
        "yCap": 12044.22,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "DMA2014",
        "yCap": 12516.38,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "DMA2015",
        "yCap": 12063.9,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "DMA2016",
        "yCap": 12385.27,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "DMA2017",
        "yCap": 11511.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "DMA2018",
        "yCap": 11849.3,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "DMA2019",
        "yCap": 12395.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "DMA2020",
        "yCap": 10255.76,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "DMA2021",
        "yCap": 10878.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominica",
        "Alpha-3": "DMA",
        "Alpha-2": "DM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "DMA2022",
        "yCap": 10878.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "DOM1990",
        "yCap": 6207.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "DOM1991",
        "yCap": 6137.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "DOM1992",
        "yCap": 6688.87,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "DOM1993",
        "yCap": 7040.49,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "DOM1994",
        "yCap": 7085.28,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "DOM1995",
        "yCap": 7349.36,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "DOM1996",
        "yCap": 7650.11,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "DOM1997",
        "yCap": 8189.05,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "DOM1998",
        "yCap": 8599.56,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "DOM1999",
        "yCap": 8970.39,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "DOM2000",
        "yCap": 9246.31,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "DOM2001",
        "yCap": 9333.57,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "DOM2002",
        "yCap": 9613.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "DOM2003",
        "yCap": 9351.32,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "DOM2004",
        "yCap": 9460.91,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "DOM2005",
        "yCap": 10215.5,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "DOM2006",
        "yCap": 11009.28,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "DOM2007",
        "yCap": 11677.25,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "DOM2008",
        "yCap": 11899.23,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "DOM2009",
        "yCap": 11856.05,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "DOM2010",
        "yCap": 12677.02,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "DOM2011",
        "yCap": 12905.29,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "DOM2012",
        "yCap": 13087.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "DOM2013",
        "yCap": 13555.51,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "DOM2014",
        "yCap": 14334.73,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "DOM2015",
        "yCap": 15145.47,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "DOM2016",
        "yCap": 15967.2,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "DOM2017",
        "yCap": 16524.53,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "DOM2018",
        "yCap": 17484.12,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "DOM2019",
        "yCap": 18171.06,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "DOM2020",
        "yCap": 16768.43,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "DOM2021",
        "yCap": 18626.08,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Dominican Republic",
        "Alpha-3": "DOM",
        "Alpha-2": "DO",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "DOM2022",
        "yCap": 18626.08,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "ECU1990",
        "yCap": 8120,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "ECU1991",
        "yCap": 8281.09,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "ECU1992",
        "yCap": 8279.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "ECU1993",
        "yCap": 8277.15,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "ECU1994",
        "yCap": 8466.25,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "ECU1995",
        "yCap": 8496.7,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "ECU1996",
        "yCap": 8487.09,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "ECU1997",
        "yCap": 8697.35,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "ECU1998",
        "yCap": 8825.49,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "ECU1999",
        "yCap": 8263.38,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "ECU2000",
        "yCap": 8211.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "ECU2001",
        "yCap": 8395.83,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "ECU2002",
        "yCap": 8589.28,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "ECU2003",
        "yCap": 8670.21,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "ECU2004",
        "yCap": 9220.33,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "ECU2005",
        "yCap": 9542.22,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "ECU2006",
        "yCap": 9792.42,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "ECU2007",
        "yCap": 9836.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "ECU2008",
        "yCap": 10284.95,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "ECU2009",
        "yCap": 10170.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "ECU2010",
        "yCap": 10355.82,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "ECU2011",
        "yCap": 10988.72,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "ECU2012",
        "yCap": 11424.15,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "ECU2013",
        "yCap": 11806.92,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "ECU2014",
        "yCap": 12073.81,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "ECU2015",
        "yCap": 11908.22,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "ECU2016",
        "yCap": 11587.83,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "ECU2017",
        "yCap": 11679.43,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "ECU2018",
        "yCap": 11608.42,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "ECU2019",
        "yCap": 11390.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "ECU2020",
        "yCap": 10356.97,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "ECU2021",
        "yCap": 10668.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ecuador",
        "Alpha-3": "ECU",
        "Alpha-2": "EC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "ECU2022",
        "yCap": 10668.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "EGY1990",
        "yCap": 5971.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "EGY1991",
        "yCap": 5895.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "EGY1992",
        "yCap": 6017.3,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "EGY1993",
        "yCap": 6051.33,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "EGY1994",
        "yCap": 6152.08,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "EGY1995",
        "yCap": 6298.12,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "EGY1996",
        "yCap": 6471.3,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "EGY1997",
        "yCap": 6681.16,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "EGY1998",
        "yCap": 6904,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "EGY1999",
        "yCap": 7168.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "EGY2000",
        "yCap": 7469.11,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "EGY2001",
        "yCap": 7575.76,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "EGY2002",
        "yCap": 7596.32,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "EGY2003",
        "yCap": 7676.94,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "EGY2004",
        "yCap": 7830.37,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "EGY2005",
        "yCap": 8019.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "EGY2006",
        "yCap": 8403.55,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "EGY2007",
        "yCap": 8825.25,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "EGY2008",
        "yCap": 9273.41,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "EGY2009",
        "yCap": 9518.78,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "EGY2010",
        "yCap": 9807.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "EGY2011",
        "yCap": 9762.97,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "EGY2012",
        "yCap": 9757.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "EGY2013",
        "yCap": 9742.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "EGY2014",
        "yCap": 9793.95,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "EGY2015",
        "yCap": 9999.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "EGY2016",
        "yCap": 10218.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "EGY2017",
        "yCap": 10435.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "EGY2018",
        "yCap": 10783.76,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "EGY2019",
        "yCap": 11180.7,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "EGY2020",
        "yCap": 11380.85,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "EGY2021",
        "yCap": 11566.05,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Egypt, Arab Rep.",
        "Alpha-3": "EGY",
        "Alpha-2": "EG",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "EGY2022",
        "yCap": 11566.05,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "SLV1990",
        "yCap": 5191.97,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "SLV1991",
        "yCap": 5178.95,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "SLV1992",
        "yCap": 5451.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "SLV1993",
        "yCap": 5688.11,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "SLV1994",
        "yCap": 5890.1,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "SLV1995",
        "yCap": 6109.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "SLV1996",
        "yCap": 6107.32,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "SLV1997",
        "yCap": 6249.92,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "SLV1998",
        "yCap": 6369.41,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "SLV1999",
        "yCap": 6464.53,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "SLV2000",
        "yCap": 6499.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "SLV2001",
        "yCap": 6524.2,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "SLV2002",
        "yCap": 6601.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "SLV2003",
        "yCap": 6687.27,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "SLV2004",
        "yCap": 6736.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "SLV2005",
        "yCap": 6916.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "SLV2006",
        "yCap": 7221.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "SLV2007",
        "yCap": 7343.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "SLV2008",
        "yCap": 7470.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "SLV2009",
        "yCap": 7287.18,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "SLV2010",
        "yCap": 7412.98,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "SLV2011",
        "yCap": 7665.95,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "SLV2012",
        "yCap": 7851.7,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "SLV2013",
        "yCap": 7995.63,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "SLV2014",
        "yCap": 8100.98,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "SLV2015",
        "yCap": 8266.72,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "SLV2016",
        "yCap": 8450.25,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "SLV2017",
        "yCap": 8617.92,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "SLV2018",
        "yCap": 8812.2,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "SLV2019",
        "yCap": 9021.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "SLV2020",
        "yCap": 8267.25,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "SLV2021",
        "yCap": 9086.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "El Salvador",
        "Alpha-3": "SLV",
        "Alpha-2": "SV",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "SLV2022",
        "yCap": 9086.05,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "GNQ1990",
        "yCap": 936.42,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "GNQ1991",
        "yCap": 893.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "GNQ1992",
        "yCap": 1159.74,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "GNQ1993",
        "yCap": 1240.91,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "GNQ1994",
        "yCap": 1394.79,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "GNQ1995",
        "yCap": 1578.05,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "GNQ1996",
        "yCap": 2530.2,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "GNQ1997",
        "yCap": 6084.62,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "GNQ1998",
        "yCap": 7242.22,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "GNQ1999",
        "yCap": 8749.43,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "GNQ2000",
        "yCap": 9890.82,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "GNQ2001",
        "yCap": 15389.16,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "GNQ2002",
        "yCap": 17534.85,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "GNQ2003",
        "yCap": 19081.93,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "GNQ2004",
        "yCap": 25164.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "GNQ2005",
        "yCap": 28075.18,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "GNQ2006",
        "yCap": 28879.35,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "GNQ2007",
        "yCap": 31770.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "GNQ2008",
        "yCap": 35688.65,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "GNQ2009",
        "yCap": 34479.89,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "GNQ2010",
        "yCap": 29944.26,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "GNQ2011",
        "yCap": 30502.6,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "GNQ2012",
        "yCap": 31680.66,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "GNQ2013",
        "yCap": 29143.02,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "GNQ2014",
        "yCap": 28106.2,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "GNQ2015",
        "yCap": 24563.5,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "GNQ2016",
        "yCap": 21566.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "GNQ2017",
        "yCap": 19617.84,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "GNQ2018",
        "yCap": 17764.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "GNQ2019",
        "yCap": 16240.37,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "GNQ2020",
        "yCap": 15132.38,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "GNQ2021",
        "yCap": 14637.01,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Equatorial Guinea",
        "Alpha-3": "GNQ",
        "Alpha-2": "GQ",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "GNQ2022",
        "yCap": 14637.01,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "ERI1990",
        "yCap": 3314.23,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "ERI1991",
        "yCap": 3321.14,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "ERI1992",
        "yCap": 3288.3,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "ERI1993",
        "yCap": 3252.75,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "ERI1994",
        "yCap": 3226.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "ERI1995",
        "yCap": 3295.19,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "ERI1996",
        "yCap": 3400.43,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "ERI1997",
        "yCap": 3587.25,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "ERI1998",
        "yCap": 3656.06,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "ERI1999",
        "yCap": 3675.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "ERI2000",
        "yCap": 3725.11,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "ERI2001",
        "yCap": 3854.99,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "ERI2002",
        "yCap": 3933.49,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "ERI2003",
        "yCap": 3987.22,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "ERI2004",
        "yCap": 4196.07,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "ERI2005",
        "yCap": 4372.53,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "ERI2006",
        "yCap": 4526.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "ERI2007",
        "yCap": 4735.79,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "ERI2008",
        "yCap": 4856.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "ERI2009",
        "yCap": 4779.56,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "ERI2010",
        "yCap": 4821.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "ERI2011",
        "yCap": 4976.13,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "ERI2012",
        "yCap": 5087.71,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "ERI2013",
        "yCap": 5149.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "ERI2014",
        "yCap": 5227.59,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "ERI2015",
        "yCap": 5177.97,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "ERI2016",
        "yCap": 5161.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "ERI2017",
        "yCap": 5176,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "ERI2018",
        "yCap": 5196.74,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "ERI2019",
        "yCap": 5220.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "ERI2020",
        "yCap": 4876.83,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "ERI2021",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eritrea",
        "Alpha-3": "ERI",
        "Alpha-2": "ER",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "ERI2022",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "EST1990",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "EST1991",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "EST1992",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "EST1993",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "EST1994",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "EST1995",
        "yCap": 12730.72,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "EST1996",
        "yCap": 13558.57,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "EST1997",
        "yCap": 15503.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "EST1998",
        "yCap": 16332.82,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "EST1999",
        "yCap": 16215.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "EST2000",
        "yCap": 17765.21,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "EST2001",
        "yCap": 18952.11,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "EST2002",
        "yCap": 20364.01,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "EST2003",
        "yCap": 22049.68,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "EST2004",
        "yCap": 23691.15,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "EST2005",
        "yCap": 26096.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "EST2006",
        "yCap": 28814.92,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "EST2007",
        "yCap": 31140.58,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "EST2008",
        "yCap": 29621.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "EST2009",
        "yCap": 25337.18,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "EST2010",
        "yCap": 26015.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "EST2011",
        "yCap": 27990.14,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "EST2012",
        "yCap": 28997.32,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "EST2013",
        "yCap": 29525.12,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "EST2014",
        "yCap": 30494.1,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "EST2015",
        "yCap": 31038.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "EST2016",
        "yCap": 32008.93,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "EST2017",
        "yCap": 33821.93,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "EST2018",
        "yCap": 34979.87,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "EST2019",
        "yCap": 36153.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "EST2020",
        "yCap": 35883.27,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "EST2021",
        "yCap": 38717.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Estonia",
        "Alpha-3": "EST",
        "Alpha-2": "EE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "EST2022",
        "yCap": 38717.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "SWZ1990",
        "yCap": 4909.27,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "SWZ1991",
        "yCap": 4795.23,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "SWZ1992",
        "yCap": 4760.25,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "SWZ1993",
        "yCap": 4762.5,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "SWZ1994",
        "yCap": 4875.37,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "SWZ1995",
        "yCap": 5111.52,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "SWZ1996",
        "yCap": 5198.79,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "SWZ1997",
        "yCap": 5267.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "SWZ1998",
        "yCap": 5326.94,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "SWZ1999",
        "yCap": 5412.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "SWZ2000",
        "yCap": 5443.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "SWZ2001",
        "yCap": 5443.28,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "SWZ2002",
        "yCap": 5630.8,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "SWZ2003",
        "yCap": 5805.16,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "SWZ2004",
        "yCap": 5976.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "SWZ2005",
        "yCap": 6298.53,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "SWZ2006",
        "yCap": 6639.72,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "SWZ2007",
        "yCap": 6894.09,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "SWZ2008",
        "yCap": 6913.35,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "SWZ2009",
        "yCap": 6989.38,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "SWZ2010",
        "yCap": 7221.34,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "SWZ2011",
        "yCap": 7347.2,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "SWZ2012",
        "yCap": 7701.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "SWZ2013",
        "yCap": 7949.59,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "SWZ2014",
        "yCap": 7969.21,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "SWZ2015",
        "yCap": 8088.73,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "SWZ2016",
        "yCap": 8113.24,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "SWZ2017",
        "yCap": 8213.92,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "SWZ2018",
        "yCap": 8343,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "SWZ2019",
        "yCap": 8501.27,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "SWZ2020",
        "yCap": 8290.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "SWZ2021",
        "yCap": 8856.83,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Eswatini",
        "Alpha-3": "SWZ",
        "Alpha-2": "SZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "SWZ2022",
        "yCap": 8856.83,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "ETH1990",
        "yCap": 767.17,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "ETH1991",
        "yCap": 683.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "ETH1992",
        "yCap": 598.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "ETH1993",
        "yCap": 655.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "ETH1994",
        "yCap": 653.89,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "ETH1995",
        "yCap": 671.54,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "ETH1996",
        "yCap": 731.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "ETH1997",
        "yCap": 730.87,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "ETH1998",
        "yCap": 684.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "ETH1999",
        "yCap": 698.19,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "ETH2000",
        "yCap": 719,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "ETH2001",
        "yCap": 756.27,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "ETH2002",
        "yCap": 745.54,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "ETH2003",
        "yCap": 708.53,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "ETH2004",
        "yCap": 781.91,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "ETH2005",
        "yCap": 849.85,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "ETH2006",
        "yCap": 915.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "ETH2007",
        "yCap": 991.88,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "ETH2008",
        "yCap": 1068.14,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "ETH2009",
        "yCap": 1130.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "ETH2010",
        "yCap": 1236.48,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "ETH2011",
        "yCap": 1336.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "ETH2012",
        "yCap": 1411.14,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "ETH2013",
        "yCap": 1518.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "ETH2014",
        "yCap": 1629.19,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "ETH2015",
        "yCap": 1750.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "ETH2016",
        "yCap": 1864.49,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "ETH2017",
        "yCap": 1987.97,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "ETH2018",
        "yCap": 2067.46,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "ETH2019",
        "yCap": 2181.66,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "ETH2020",
        "yCap": 2253.24,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "ETH2021",
        "yCap": 2319.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ethiopia",
        "Alpha-3": "ETH",
        "Alpha-2": "ET",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "ETH2022",
        "yCap": 2319.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "FRO1990",
        "yCap": 21546.26,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "FRO1991",
        "yCap": 21372.8,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "FRO1992",
        "yCap": 21081.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "FRO1993",
        "yCap": 20893.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "FRO1994",
        "yCap": 21098.63,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "FRO1995",
        "yCap": 21483.99,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "FRO1996",
        "yCap": 21981.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "FRO1997",
        "yCap": 22717.27,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "FRO1998",
        "yCap": 23548.9,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "FRO1999",
        "yCap": 24300.48,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "FRO2000",
        "yCap": 25310.46,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "FRO2001",
        "yCap": 25961.38,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "FRO2002",
        "yCap": 26468.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "FRO2003",
        "yCap": 27049.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "FRO2004",
        "yCap": 28109.83,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "FRO2005",
        "yCap": 29015.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "FRO2006",
        "yCap": 30295.56,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "FRO2007",
        "yCap": 31705.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "FRO2008",
        "yCap": 31930.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "FRO2009",
        "yCap": 30195.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "FRO2010",
        "yCap": 30589.07,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "FRO2011",
        "yCap": 30918.19,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "FRO2012",
        "yCap": 30801.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "FRO2013",
        "yCap": 31011.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "FRO2014",
        "yCap": 31496.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "FRO2015",
        "yCap": 32309.12,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "FRO2016",
        "yCap": 32968.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "FRO2017",
        "yCap": 33882.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "FRO2018",
        "yCap": 34759.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "FRO2019",
        "yCap": 35523.11,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "FRO2020",
        "yCap": 34038.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "FRO2021",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Faroe Islands",
        "Alpha-3": "FRO",
        "Alpha-2": "FO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "FRO2022",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "FJI1990",
        "yCap": 7889.88,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "FJI1991",
        "yCap": 7633.79,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "FJI1992",
        "yCap": 8078.59,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "FJI1993",
        "yCap": 8226.21,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "FJI1994",
        "yCap": 8628.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "FJI1995",
        "yCap": 8827.8,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "FJI1996",
        "yCap": 9201.68,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "FJI1997",
        "yCap": 8909.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "FJI1998",
        "yCap": 8921.26,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "FJI1999",
        "yCap": 9594.64,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "FJI2000",
        "yCap": 9328.58,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "FJI2001",
        "yCap": 9415.5,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "FJI2002",
        "yCap": 9618.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "FJI2003",
        "yCap": 9619.75,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "FJI2004",
        "yCap": 10031.56,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "FJI2005",
        "yCap": 10006.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "FJI2006",
        "yCap": 10097.96,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "FJI2007",
        "yCap": 9927.02,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "FJI2008",
        "yCap": 9961.51,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "FJI2009",
        "yCap": 9772.74,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "FJI2010",
        "yCap": 10019.41,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "FJI2011",
        "yCap": 10254.36,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "FJI2012",
        "yCap": 10368.22,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "FJI2013",
        "yCap": 10830.61,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "FJI2014",
        "yCap": 11411.18,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "FJI2015",
        "yCap": 11903.5,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "FJI2016",
        "yCap": 12179.07,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "FJI2017",
        "yCap": 12822.01,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "FJI2018",
        "yCap": 13311.17,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "FJI2019",
        "yCap": 13241.35,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "FJI2020",
        "yCap": 10966.93,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "FJI2021",
        "yCap": 10359.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Fiji",
        "Alpha-3": "FJI",
        "Alpha-2": "FJ",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "FJI2022",
        "yCap": 10359.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "FIN1990",
        "yCap": 32939.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "FIN1991",
        "yCap": 30831.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "FIN1992",
        "yCap": 29648.74,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "FIN1993",
        "yCap": 29310.31,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "FIN1994",
        "yCap": 30340.82,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "FIN1995",
        "yCap": 31499.81,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "FIN1996",
        "yCap": 32548.02,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "FIN1997",
        "yCap": 34506.77,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "FIN1998",
        "yCap": 36293.39,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "FIN1999",
        "yCap": 37795.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "FIN2000",
        "yCap": 39894.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "FIN2001",
        "yCap": 40842.34,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "FIN2002",
        "yCap": 41439.02,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "FIN2003",
        "yCap": 42168.69,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "FIN2004",
        "yCap": 43724.97,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "FIN2005",
        "yCap": 44786.91,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "FIN2006",
        "yCap": 46412.2,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "FIN2007",
        "yCap": 48664.27,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "FIN2008",
        "yCap": 48817.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "FIN2009",
        "yCap": 44662.1,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "FIN2010",
        "yCap": 45874.66,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "FIN2011",
        "yCap": 46825.82,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "FIN2012",
        "yCap": 45952.25,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "FIN2013",
        "yCap": 45328.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "FIN2014",
        "yCap": 44976.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "FIN2015",
        "yCap": 45072.59,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "FIN2016",
        "yCap": 46206.79,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "FIN2017",
        "yCap": 47570.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "FIN2018",
        "yCap": 48048.52,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "FIN2019",
        "yCap": 48583.43,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "FIN2020",
        "yCap": 47443.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "FIN2021",
        "yCap": 48753.35,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Finland",
        "Alpha-3": "FIN",
        "Alpha-2": "FI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "FIN2022",
        "yCap": 48753.35,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "FRA1990",
        "yCap": 33843.02,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "FRA1991",
        "yCap": 33898.23,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "FRA1992",
        "yCap": 34269.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "FRA1993",
        "yCap": 33906.22,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "FRA1994",
        "yCap": 34576.32,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "FRA1995",
        "yCap": 35176.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "FRA1996",
        "yCap": 35546.58,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "FRA1997",
        "yCap": 36247.6,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "FRA1998",
        "yCap": 37409.4,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "FRA1999",
        "yCap": 38490.04,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "FRA2000",
        "yCap": 39726.49,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "FRA2001",
        "yCap": 40220.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "FRA2002",
        "yCap": 40381.46,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "FRA2003",
        "yCap": 40425.64,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "FRA2004",
        "yCap": 41265.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "FRA2005",
        "yCap": 41638.03,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "FRA2006",
        "yCap": 42362.99,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "FRA2007",
        "yCap": 43123.5,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "FRA2008",
        "yCap": 42993.06,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "FRA2009",
        "yCap": 41544.02,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "FRA2010",
        "yCap": 42145.68,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "FRA2011",
        "yCap": 42862.42,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "FRA2012",
        "yCap": 42789.05,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "FRA2013",
        "yCap": 42813.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "FRA2014",
        "yCap": 43021.39,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "FRA2015",
        "yCap": 43345.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "FRA2016",
        "yCap": 43705.15,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "FRA2017",
        "yCap": 44577.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "FRA2018",
        "yCap": 45245.96,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "FRA2019",
        "yCap": 45922.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "FRA2020",
        "yCap": 42233.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "FRA2021",
        "yCap": 44993.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "France",
        "Alpha-3": "FRA",
        "Alpha-2": "FR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "FRA2022",
        "yCap": 44993.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "PYF1990",
        "yCap": 12367.59,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "PYF1991",
        "yCap": 12505.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "PYF1992",
        "yCap": 12903.06,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "PYF1993",
        "yCap": 13250.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "PYF1994",
        "yCap": 13652.2,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "PYF1995",
        "yCap": 14030.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "PYF1996",
        "yCap": 14250.36,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "PYF1997",
        "yCap": 14345.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "PYF1998",
        "yCap": 13907.32,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "PYF1999",
        "yCap": 14177.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "PYF2000",
        "yCap": 14657.18,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "PYF2001",
        "yCap": 14767.58,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "PYF2002",
        "yCap": 15202.57,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "PYF2003",
        "yCap": 15690.84,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "PYF2004",
        "yCap": 16635.31,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "PYF2005",
        "yCap": 17160.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "PYF2006",
        "yCap": 17930.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "PYF2007",
        "yCap": 18645.08,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "PYF2008",
        "yCap": 18659.79,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "PYF2009",
        "yCap": 18352.43,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "PYF2010",
        "yCap": 19761.77,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "PYF2011",
        "yCap": 20971.66,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "PYF2012",
        "yCap": 21618,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "PYF2013",
        "yCap": 22402.01,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "PYF2014",
        "yCap": 22596.64,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "PYF2015",
        "yCap": 21826.58,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "PYF2016",
        "yCap": 22024.82,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "PYF2017",
        "yCap": 22747.82,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "PYF2018",
        "yCap": 23336.48,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "PYF2019",
        "yCap": 23421.25,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "PYF2020",
        "yCap": 20593.57,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "PYF2021",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "French Polynesia",
        "Alpha-3": "PYF",
        "Alpha-2": "PF",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "PYF2022",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "GAB1990",
        "yCap": 17559.01,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "GAB1991",
        "yCap": 18134.82,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "GAB1992",
        "yCap": 17109.4,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "GAB1993",
        "yCap": 17318.16,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "GAB1994",
        "yCap": 17497.65,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "GAB1995",
        "yCap": 17903.78,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "GAB1996",
        "yCap": 18091.05,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "GAB1997",
        "yCap": 18655.65,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "GAB1998",
        "yCap": 18825.36,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "GAB1999",
        "yCap": 16716.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "GAB2000",
        "yCap": 15987.09,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "GAB2001",
        "yCap": 15907.86,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "GAB2002",
        "yCap": 15453.05,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "GAB2003",
        "yCap": 15379.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "GAB2004",
        "yCap": 15062.64,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "GAB2005",
        "yCap": 15028.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "GAB2006",
        "yCap": 14177.09,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "GAB2007",
        "yCap": 14570.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "GAB2008",
        "yCap": 13646.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "GAB2009",
        "yCap": 13221.77,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "GAB2010",
        "yCap": 13682.85,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "GAB2011",
        "yCap": 14145.65,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "GAB2012",
        "yCap": 14368,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "GAB2013",
        "yCap": 14655.37,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "GAB2014",
        "yCap": 14785.4,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "GAB2015",
        "yCap": 14892.04,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "GAB2016",
        "yCap": 14783.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "GAB2017",
        "yCap": 14478.13,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "GAB2018",
        "yCap": 14254.46,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "GAB2019",
        "yCap": 14478,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "GAB2020",
        "yCap": 13903.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "GAB2021",
        "yCap": 13813.72,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gabon",
        "Alpha-3": "GAB",
        "Alpha-2": "GA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "GAB2022",
        "yCap": 13813.72,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "GMB1990",
        "yCap": 2080.16,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "GMB1991",
        "yCap": 2059.83,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "GMB1992",
        "yCap": 2047.03,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "GMB1993",
        "yCap": 2034.4,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "GMB1994",
        "yCap": 1974.06,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "GMB1995",
        "yCap": 1933.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "GMB1996",
        "yCap": 1919.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "GMB1997",
        "yCap": 1955.32,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "GMB1998",
        "yCap": 1965.28,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "GMB1999",
        "yCap": 2030.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "GMB2000",
        "yCap": 2081.22,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "GMB2001",
        "yCap": 2139.56,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "GMB2002",
        "yCap": 2011.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "GMB2003",
        "yCap": 2089.62,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "GMB2004",
        "yCap": 2173.16,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "GMB2005",
        "yCap": 2060.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "GMB2006",
        "yCap": 1988.1,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "GMB2007",
        "yCap": 1986.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "GMB2008",
        "yCap": 2046.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "GMB2009",
        "yCap": 2115.61,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "GMB2010",
        "yCap": 2172.19,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "GMB2011",
        "yCap": 1934.73,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "GMB2012",
        "yCap": 1974.09,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "GMB2013",
        "yCap": 1969.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "GMB2014",
        "yCap": 1885.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "GMB2015",
        "yCap": 1905.82,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "GMB2016",
        "yCap": 1889.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "GMB2017",
        "yCap": 1927.04,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "GMB2018",
        "yCap": 2012.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "GMB2019",
        "yCap": 2083.31,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "GMB2020",
        "yCap": 2042.62,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "GMB2021",
        "yCap": 2076.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Gambia, The",
        "Alpha-3": "GMB",
        "Alpha-2": "GM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "GMB2022",
        "yCap": 2076.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "GEO1990",
        "yCap": 11135.46,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "GEO1991",
        "yCap": 8724.29,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "GEO1992",
        "yCap": 4770,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "GEO1993",
        "yCap": 3346.57,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "GEO1994",
        "yCap": 3045.04,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "GEO1995",
        "yCap": 3243.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "GEO1996",
        "yCap": 3740.49,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "GEO1997",
        "yCap": 4268.7,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "GEO1998",
        "yCap": 4511.49,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "GEO1999",
        "yCap": 4737.41,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "GEO2000",
        "yCap": 4919.23,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "GEO2001",
        "yCap": 5236.22,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "GEO2002",
        "yCap": 5572.62,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "GEO2003",
        "yCap": 6230.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "GEO2004",
        "yCap": 6632.81,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "GEO2005",
        "yCap": 7315.2,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "GEO2006",
        "yCap": 8049.91,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "GEO2007",
        "yCap": 9109.9,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "GEO2008",
        "yCap": 9358.61,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "GEO2009",
        "yCap": 9097.42,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "GEO2010",
        "yCap": 9736.73,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "GEO2011",
        "yCap": 10541.47,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "GEO2012",
        "yCap": 11295.75,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "GEO2013",
        "yCap": 11740.09,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "GEO2014",
        "yCap": 12254.65,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "GEO2015",
        "yCap": 12605.14,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "GEO2016",
        "yCap": 12963.74,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "GEO2017",
        "yCap": 13589.71,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "GEO2018",
        "yCap": 14253.41,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "GEO2019",
        "yCap": 14989.26,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "GEO2020",
        "yCap": 13966.33,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "GEO2021",
        "yCap": 15486.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Georgia",
        "Alpha-3": "GEO",
        "Alpha-2": "GE",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "GEO2022",
        "yCap": 15486.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "DEU1990",
        "yCap": 36699.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "DEU1991",
        "yCap": 38294.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "DEU1992",
        "yCap": 38734.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "DEU1993",
        "yCap": 38105.23,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "DEU1994",
        "yCap": 38881.57,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "DEU1995",
        "yCap": 39366.09,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "DEU1996",
        "yCap": 39568.6,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "DEU1997",
        "yCap": 40218.85,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "DEU1998",
        "yCap": 41022.61,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "DEU1999",
        "yCap": 41769.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "DEU2000",
        "yCap": 42928.18,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "DEU2001",
        "yCap": 43576.64,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "DEU2002",
        "yCap": 43417.31,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "DEU2003",
        "yCap": 43089.47,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "DEU2004",
        "yCap": 43605.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "DEU2005",
        "yCap": 43949.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "DEU2006",
        "yCap": 45678.08,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "DEU2007",
        "yCap": 47100.61,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "DEU2008",
        "yCap": 47643.22,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "DEU2009",
        "yCap": 45044.49,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "DEU2010",
        "yCap": 46999.24,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "DEU2011",
        "yCap": 49757.92,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "DEU2012",
        "yCap": 49872.45,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "DEU2013",
        "yCap": 49954.17,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "DEU2014",
        "yCap": 50845.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "DEU2015",
        "yCap": 51159.3,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "DEU2016",
        "yCap": 51879.67,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "DEU2017",
        "yCap": 53071.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "DEU2018",
        "yCap": 53431.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "DEU2019",
        "yCap": 53874.32,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "DEU2020",
        "yCap": 51840.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "DEU2021",
        "yCap": 53179.65,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Germany",
        "Alpha-3": "DEU",
        "Alpha-2": "DE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "DEU2022",
        "yCap": 53179.65,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "GHA1990",
        "yCap": 2299.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "GHA1991",
        "yCap": 2360.51,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "GHA1992",
        "yCap": 2391.99,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "GHA1993",
        "yCap": 2447.41,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "GHA1994",
        "yCap": 2469.24,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "GHA1995",
        "yCap": 2512.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "GHA1996",
        "yCap": 2568.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "GHA1997",
        "yCap": 2613.73,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "GHA1998",
        "yCap": 2671.27,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "GHA1999",
        "yCap": 2721.6,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "GHA2000",
        "yCap": 2752.16,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "GHA2001",
        "yCap": 2787.13,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "GHA2002",
        "yCap": 2833.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "GHA2003",
        "yCap": 2901.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "GHA2004",
        "yCap": 2982.89,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "GHA2005",
        "yCap": 3075.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "GHA2006",
        "yCap": 3187.58,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "GHA2007",
        "yCap": 3240.59,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "GHA2008",
        "yCap": 3447.27,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "GHA2009",
        "yCap": 3523.79,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "GHA2010",
        "yCap": 3709.4,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "GHA2011",
        "yCap": 4128.56,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "GHA2012",
        "yCap": 4402.55,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "GHA2013",
        "yCap": 4610.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "GHA2014",
        "yCap": 4628.9,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "GHA2015",
        "yCap": 4616.62,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "GHA2016",
        "yCap": 4662.01,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "GHA2017",
        "yCap": 4929.57,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "GHA2018",
        "yCap": 5125.25,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "GHA2019",
        "yCap": 5345.94,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "GHA2020",
        "yCap": 5263.53,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "GHA2021",
        "yCap": 5435.24,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ghana",
        "Alpha-3": "GHA",
        "Alpha-2": "GH",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "GHA2022",
        "yCap": 5435.24,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "GIB1990",
        "yCap": 21546.26,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "GIB1991",
        "yCap": 21372.8,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "GIB1992",
        "yCap": 21081.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "GIB1993",
        "yCap": 20893.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "GIB1994",
        "yCap": 21098.63,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "GIB1995",
        "yCap": 21483.99,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "GIB1996",
        "yCap": 21981.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "GIB1997",
        "yCap": 22717.27,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "GIB1998",
        "yCap": 23548.9,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "GIB1999",
        "yCap": 24300.48,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "GIB2000",
        "yCap": 25310.46,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "GIB2001",
        "yCap": 25961.38,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "GIB2002",
        "yCap": 26468.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "GIB2003",
        "yCap": 27049.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "GIB2004",
        "yCap": 28109.83,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "GIB2005",
        "yCap": 29015.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "GIB2006",
        "yCap": 30295.56,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "GIB2007",
        "yCap": 31705.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "GIB2008",
        "yCap": 31930.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "GIB2009",
        "yCap": 30195.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "GIB2010",
        "yCap": 30589.07,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "GIB2011",
        "yCap": 30918.19,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "GIB2012",
        "yCap": 30801.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "GIB2013",
        "yCap": 31011.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "GIB2014",
        "yCap": 31496.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "GIB2015",
        "yCap": 32309.12,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "GIB2016",
        "yCap": 32968.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "GIB2017",
        "yCap": 33882.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "GIB2018",
        "yCap": 34759.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "GIB2019",
        "yCap": 35523.11,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "GIB2020",
        "yCap": 34038.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "GIB2021",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Gibraltar",
        "Alpha-3": "GIB",
        "Alpha-2": "GI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "GIB2022",
        "yCap": 36006.99,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "GRC1990",
        "yCap": 24262.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "GRC1991",
        "yCap": 24716.52,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "GRC1992",
        "yCap": 24700.13,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "GRC1993",
        "yCap": 24162.37,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "GRC1994",
        "yCap": 24522.53,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "GRC1995",
        "yCap": 24920.73,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "GRC1996",
        "yCap": 25521.28,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "GRC1997",
        "yCap": 26534.5,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "GRC1998",
        "yCap": 27415.63,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "GRC1999",
        "yCap": 28149.84,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "GRC2000",
        "yCap": 29133.84,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "GRC2001",
        "yCap": 30180.23,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "GRC2002",
        "yCap": 31249.4,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "GRC2003",
        "yCap": 32981.35,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "GRC2004",
        "yCap": 34564.91,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "GRC2005",
        "yCap": 34670.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "GRC2006",
        "yCap": 36520.05,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "GRC2007",
        "yCap": 37619.66,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "GRC2008",
        "yCap": 37394.17,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "GRC2009",
        "yCap": 35691.95,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "GRC2010",
        "yCap": 33693.21,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "GRC2011",
        "yCap": 30318.4,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "GRC2012",
        "yCap": 28322.57,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "GRC2013",
        "yCap": 27810.91,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "GRC2014",
        "yCap": 28129.96,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "GRC2015",
        "yCap": 28260.39,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "GRC2016",
        "yCap": 28239.92,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "GRC2017",
        "yCap": 28604.86,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "GRC2018",
        "yCap": 29141.17,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "GRC2019",
        "yCap": 29721.59,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "GRC2020",
        "yCap": 27103.54,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "GRC2021",
        "yCap": 29548.04,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greece",
        "Alpha-3": "GRC",
        "Alpha-2": "GR",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "GRC2022",
        "yCap": 29548.04,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "GRL1990",
        "yCap": 21546.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "GRL1991",
        "yCap": 21372.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "GRL1992",
        "yCap": 21081.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "GRL1993",
        "yCap": 20893.97,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "GRL1994",
        "yCap": 21098.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "GRL1995",
        "yCap": 21483.99,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "GRL1996",
        "yCap": 21981.24,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "GRL1997",
        "yCap": 22717.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "GRL1998",
        "yCap": 23548.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "GRL1999",
        "yCap": 24300.48,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "GRL2000",
        "yCap": 25310.46,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "GRL2001",
        "yCap": 25961.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "GRL2002",
        "yCap": 26468.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "GRL2003",
        "yCap": 27049.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "GRL2004",
        "yCap": 28109.83,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "GRL2005",
        "yCap": 29015.44,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "GRL2006",
        "yCap": 30295.56,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "GRL2007",
        "yCap": 31705.85,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "GRL2008",
        "yCap": 31930.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "GRL2009",
        "yCap": 30195.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "GRL2010",
        "yCap": 30589.07,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "GRL2011",
        "yCap": 30918.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "GRL2012",
        "yCap": 30801.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "GRL2013",
        "yCap": 31011.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "GRL2014",
        "yCap": 31496.58,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "GRL2015",
        "yCap": 32309.12,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "GRL2016",
        "yCap": 32968.77,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "GRL2017",
        "yCap": 33882.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "GRL2018",
        "yCap": 34759.68,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "GRL2019",
        "yCap": 35523.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "GRL2020",
        "yCap": 34038.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "GRL2021",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Greenland",
        "Alpha-3": "GRL",
        "Alpha-2": "GL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "GRL2022",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "GRD1990",
        "yCap": 8571.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "GRD1991",
        "yCap": 8628.73,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "GRD1992",
        "yCap": 8446.74,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "GRD1993",
        "yCap": 8189.45,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "GRD1994",
        "yCap": 8247.34,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "GRD1995",
        "yCap": 8351.25,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "GRD1996",
        "yCap": 8656.51,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "GRD1997",
        "yCap": 9030.13,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "GRD1998",
        "yCap": 10029.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "GRD1999",
        "yCap": 10658.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "GRD2000",
        "yCap": 11116.61,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "GRD2001",
        "yCap": 10840.76,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "GRD2002",
        "yCap": 11182.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "GRD2003",
        "yCap": 12183.87,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "GRD2004",
        "yCap": 12019.22,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "GRD2005",
        "yCap": 13523.78,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "GRD2006",
        "yCap": 12897.59,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "GRD2007",
        "yCap": 13597.08,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "GRD2008",
        "yCap": 13634.03,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "GRD2009",
        "yCap": 12645.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "GRD2010",
        "yCap": 12493.88,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "GRD2011",
        "yCap": 12493.16,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "GRD2012",
        "yCap": 12242.95,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "GRD2013",
        "yCap": 12420.15,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "GRD2014",
        "yCap": 13215.91,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "GRD2015",
        "yCap": 13948.47,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "GRD2016",
        "yCap": 14351.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "GRD2017",
        "yCap": 14869.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "GRD2018",
        "yCap": 15401.73,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "GRD2019",
        "yCap": 15393.79,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "GRD2020",
        "yCap": 13175.42,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "GRD2021",
        "yCap": 13688.26,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Grenada",
        "Alpha-3": "GRD",
        "Alpha-2": "GD",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "GRD2022",
        "yCap": 13688.26,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "GUM1990",
        "yCap": 12367.59,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "GUM1991",
        "yCap": 12505.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "GUM1992",
        "yCap": 12903.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "GUM1993",
        "yCap": 13250.15,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "GUM1994",
        "yCap": 13652.2,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "GUM1995",
        "yCap": 14030.41,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "GUM1996",
        "yCap": 14250.36,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "GUM1997",
        "yCap": 14345.62,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "GUM1998",
        "yCap": 13907.32,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "GUM1999",
        "yCap": 14177.28,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "GUM2000",
        "yCap": 14657.18,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "GUM2001",
        "yCap": 14767.58,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "GUM2002",
        "yCap": 15202.57,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "GUM2003",
        "yCap": 15690.84,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "GUM2004",
        "yCap": 16635.31,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "GUM2005",
        "yCap": 17160.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "GUM2006",
        "yCap": 17930.46,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "GUM2007",
        "yCap": 18645.08,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "GUM2008",
        "yCap": 18659.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "GUM2009",
        "yCap": 18352.43,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "GUM2010",
        "yCap": 19761.77,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "GUM2011",
        "yCap": 20971.66,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "GUM2012",
        "yCap": 21618,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "GUM2013",
        "yCap": 22402.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "GUM2014",
        "yCap": 22596.64,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "GUM2015",
        "yCap": 21826.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "GUM2016",
        "yCap": 22024.82,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "GUM2017",
        "yCap": 22747.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "GUM2018",
        "yCap": 23336.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "GUM2019",
        "yCap": 23421.25,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "GUM2020",
        "yCap": 20593.57,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "GUM2021",
        "yCap": 21482.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guam",
        "Alpha-3": "GUM",
        "Alpha-2": "GU",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "GUM2022",
        "yCap": 21482.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "GTM1990",
        "yCap": 5561.07,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "GTM1991",
        "yCap": 5611.54,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "GTM1992",
        "yCap": 5730.65,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "GTM1993",
        "yCap": 5805.71,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "GTM1994",
        "yCap": 5891.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "GTM1995",
        "yCap": 6033.04,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "GTM1996",
        "yCap": 6064.02,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "GTM1997",
        "yCap": 6181.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "GTM1998",
        "yCap": 6338.34,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "GTM1999",
        "yCap": 6428.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "GTM2000",
        "yCap": 6499.88,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "GTM2001",
        "yCap": 6493.61,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "GTM2002",
        "yCap": 6589.74,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "GTM2003",
        "yCap": 6612.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "GTM2004",
        "yCap": 6676.7,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "GTM2005",
        "yCap": 6753.68,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "GTM2006",
        "yCap": 6972.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "GTM2007",
        "yCap": 7269.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "GTM2008",
        "yCap": 7365.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "GTM2009",
        "yCap": 7262.5,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "GTM2010",
        "yCap": 7335.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "GTM2011",
        "yCap": 7503.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "GTM2012",
        "yCap": 7590.76,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "GTM2013",
        "yCap": 7734.12,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "GTM2014",
        "yCap": 7939.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "GTM2015",
        "yCap": 8125.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "GTM2016",
        "yCap": 8206.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "GTM2017",
        "yCap": 8322.22,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "GTM2018",
        "yCap": 8469.12,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "GTM2019",
        "yCap": 8671.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "GTM2020",
        "yCap": 8390.22,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "GTM2021",
        "yCap": 8926.7,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guatemala",
        "Alpha-3": "GTM",
        "Alpha-2": "GT",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "GTM2022",
        "yCap": 8926.7,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "GIN1990",
        "yCap": 1507.2,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "GIN1991",
        "yCap": 1485.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "GIN1992",
        "yCap": 1485.42,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "GIN1993",
        "yCap": 1512.98,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "GIN1994",
        "yCap": 1526.26,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "GIN1995",
        "yCap": 1552.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "GIN1996",
        "yCap": 1576.42,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "GIN1997",
        "yCap": 1624.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "GIN1998",
        "yCap": 1652,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "GIN1999",
        "yCap": 1676.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "GIN2000",
        "yCap": 1685.28,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "GIN2001",
        "yCap": 1724.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "GIN2002",
        "yCap": 1785.57,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "GIN2003",
        "yCap": 1767.79,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "GIN2004",
        "yCap": 1771.05,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "GIN2005",
        "yCap": 1788.39,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "GIN2006",
        "yCap": 1772.71,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "GIN2007",
        "yCap": 1850.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "GIN2008",
        "yCap": 1881.27,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "GIN2009",
        "yCap": 1815.32,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "GIN2010",
        "yCap": 1856.49,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "GIN2011",
        "yCap": 1912.82,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "GIN2012",
        "yCap": 1976.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "GIN2013",
        "yCap": 2005.38,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "GIN2014",
        "yCap": 2028.51,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "GIN2015",
        "yCap": 2053.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "GIN2016",
        "yCap": 2217.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "GIN2017",
        "yCap": 2383.58,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "GIN2018",
        "yCap": 2471.72,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "GIN2019",
        "yCap": 2545.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "GIN2020",
        "yCap": 2604.12,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "GIN2021",
        "yCap": 2640.34,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea",
        "Alpha-3": "GIN",
        "Alpha-2": "GN",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "GIN2022",
        "yCap": 2640.34,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "GNB1990",
        "yCap": 1998.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "GNB1991",
        "yCap": 2057.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "GNB1992",
        "yCap": 2020.74,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "GNB1993",
        "yCap": 1997.08,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "GNB1994",
        "yCap": 1998.84,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "GNB1995",
        "yCap": 2035.22,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "GNB1996",
        "yCap": 2224.75,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "GNB1997",
        "yCap": 2324.84,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "GNB1998",
        "yCap": 1641.03,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "GNB1999",
        "yCap": 1627.08,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "GNB2000",
        "yCap": 1681.41,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "GNB2001",
        "yCap": 1681.96,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "GNB2002",
        "yCap": 1628.73,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "GNB2003",
        "yCap": 1600.67,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "GNB2004",
        "yCap": 1606.58,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "GNB2005",
        "yCap": 1635.41,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "GNB2006",
        "yCap": 1632.51,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "GNB2007",
        "yCap": 1643.38,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "GNB2008",
        "yCap": 1652.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "GNB2009",
        "yCap": 1665.2,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "GNB2010",
        "yCap": 1697.5,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "GNB2011",
        "yCap": 1787.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "GNB2012",
        "yCap": 1710.02,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "GNB2013",
        "yCap": 1718.86,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "GNB2014",
        "yCap": 1690.09,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "GNB2015",
        "yCap": 1748.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "GNB2016",
        "yCap": 1811.3,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "GNB2017",
        "yCap": 1872.31,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "GNB2018",
        "yCap": 1851.89,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "GNB2019",
        "yCap": 1890.53,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "GNB2020",
        "yCap": 1803.63,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "GNB2021",
        "yCap": 1831.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guinea-Bissau",
        "Alpha-3": "GNB",
        "Alpha-2": "GW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "GNB2022",
        "yCap": 1831.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "GUY1990",
        "yCap": 4818.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "GUY1991",
        "yCap": 5131.02,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "GUY1992",
        "yCap": 5522.38,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "GUY1993",
        "yCap": 5956.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "GUY1994",
        "yCap": 6444.22,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "GUY1995",
        "yCap": 6749.16,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "GUY1996",
        "yCap": 7267.89,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "GUY1997",
        "yCap": 7700.34,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "GUY1998",
        "yCap": 7556.18,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "GUY1999",
        "yCap": 7766.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "GUY2000",
        "yCap": 7649.95,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "GUY2001",
        "yCap": 7816.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "GUY2002",
        "yCap": 7899.71,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "GUY2003",
        "yCap": 7847.3,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "GUY2004",
        "yCap": 7971.43,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "GUY2005",
        "yCap": 7823.27,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "GUY2006",
        "yCap": 8239.12,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "GUY2007",
        "yCap": 8853.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "GUY2008",
        "yCap": 9036.89,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "GUY2009",
        "yCap": 9399.3,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "GUY2010",
        "yCap": 9831.81,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "GUY2011",
        "yCap": 10394.14,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "GUY2012",
        "yCap": 10946.45,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "GUY2013",
        "yCap": 11293.95,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "GUY2014",
        "yCap": 11427.87,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "GUY2015",
        "yCap": 11446.76,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "GUY2016",
        "yCap": 11819.15,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "GUY2017",
        "yCap": 12193.62,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "GUY2018",
        "yCap": 12374.22,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "GUY2019",
        "yCap": 12820.49,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "GUY2020",
        "yCap": 18430.57,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "GUY2021",
        "yCap": 21925.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Guyana",
        "Alpha-3": "GUY",
        "Alpha-2": "GY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "GUY2022",
        "yCap": 21925.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "HTI1990",
        "yCap": 3598.98,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "HTI1991",
        "yCap": 3593.64,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "HTI1992",
        "yCap": 3336.84,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "HTI1993",
        "yCap": 3095.56,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "HTI1994",
        "yCap": 2674.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "HTI1995",
        "yCap": 2884.89,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "HTI1996",
        "yCap": 2948.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "HTI1997",
        "yCap": 2973.39,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "HTI1998",
        "yCap": 2983.34,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "HTI1999",
        "yCap": 3008.93,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "HTI2000",
        "yCap": 2980.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "HTI2001",
        "yCap": 2917.11,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "HTI2002",
        "yCap": 2896.81,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "HTI2003",
        "yCap": 2946.38,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "HTI2004",
        "yCap": 2859.13,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "HTI2005",
        "yCap": 2898.32,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "HTI2006",
        "yCap": 2900.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "HTI2007",
        "yCap": 2987.13,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "HTI2008",
        "yCap": 3017.07,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "HTI2009",
        "yCap": 3143.77,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "HTI2010",
        "yCap": 2932.28,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "HTI2011",
        "yCap": 3047.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "HTI2012",
        "yCap": 3015.86,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "HTI2013",
        "yCap": 3099.48,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "HTI2014",
        "yCap": 3106.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "HTI2015",
        "yCap": 3141.05,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "HTI2016",
        "yCap": 3153.18,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "HTI2017",
        "yCap": 3187.79,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "HTI2018",
        "yCap": 3197.14,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "HTI2019",
        "yCap": 3101.59,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "HTI2020",
        "yCap": 2959.09,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "HTI2021",
        "yCap": 2870.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Haiti",
        "Alpha-3": "HTI",
        "Alpha-2": "HT",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "HTI2022",
        "yCap": 2870.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "HND1990",
        "yCap": 3912.7,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "HND1991",
        "yCap": 3678.26,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "HND1992",
        "yCap": 3793.71,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "HND1993",
        "yCap": 3928.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "HND1994",
        "yCap": 3827.99,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "HND1995",
        "yCap": 3953.1,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "HND1996",
        "yCap": 3916.74,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "HND1997",
        "yCap": 3985.11,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "HND1998",
        "yCap": 4020.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "HND1999",
        "yCap": 3886.99,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "HND2000",
        "yCap": 4058.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "HND2001",
        "yCap": 4058.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "HND2002",
        "yCap": 4101.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "HND2003",
        "yCap": 4179.42,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "HND2004",
        "yCap": 4330.74,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "HND2005",
        "yCap": 4482.76,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "HND2006",
        "yCap": 4665.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "HND2007",
        "yCap": 4842.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "HND2008",
        "yCap": 4936.87,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "HND2009",
        "yCap": 4714.68,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "HND2010",
        "yCap": 4790.11,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "HND2011",
        "yCap": 4874.87,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "HND2012",
        "yCap": 4978.07,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "HND2013",
        "yCap": 5020.94,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "HND2014",
        "yCap": 5079.7,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "HND2015",
        "yCap": 5180.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "HND2016",
        "yCap": 5287.25,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "HND2017",
        "yCap": 5447.7,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "HND2018",
        "yCap": 5561.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "HND2019",
        "yCap": 5613.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "HND2020",
        "yCap": 5028.15,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "HND2021",
        "yCap": 5572.18,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Honduras",
        "Alpha-3": "HND",
        "Alpha-2": "HN",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "HND2022",
        "yCap": 5572.18,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "HKG1990",
        "yCap": 28797.69,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "HKG1991",
        "yCap": 30188.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "HKG1992",
        "yCap": 31802.44,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "HKG1993",
        "yCap": 33199.34,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "HKG1994",
        "yCap": 34419.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "HKG1995",
        "yCap": 34545.53,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "HKG1996",
        "yCap": 34452.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "HKG1997",
        "yCap": 35909.79,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "HKG1998",
        "yCap": 33516.36,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "HKG1999",
        "yCap": 34029.94,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "HKG2000",
        "yCap": 36316.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "HKG2001",
        "yCap": 36251.76,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "HKG2002",
        "yCap": 36689.49,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "HKG2003",
        "yCap": 37885.55,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "HKG2004",
        "yCap": 40861.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "HKG2005",
        "yCap": 43689.34,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "HKG2006",
        "yCap": 46462.46,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "HKG2007",
        "yCap": 49042.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "HKG2008",
        "yCap": 49787.64,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "HKG2009",
        "yCap": 48458.87,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "HKG2010",
        "yCap": 51359.81,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "HKG2011",
        "yCap": 53471.8,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "HKG2012",
        "yCap": 53783.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "HKG2013",
        "yCap": 55229.61,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "HKG2014",
        "yCap": 56358.04,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "HKG2015",
        "yCap": 57214.69,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "HKG2016",
        "yCap": 58098.39,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "HKG2017",
        "yCap": 59842.21,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "HKG2018",
        "yCap": 61055.34,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "HKG2019",
        "yCap": 59588.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "HKG2020",
        "yCap": 55888.69,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "HKG2021",
        "yCap": 59978.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Hong Kong SAR, China",
        "Alpha-3": "HKG",
        "Alpha-2": "HK",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "HKG2022",
        "yCap": 59978.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "HUN1990",
        "yCap": 16429.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "HUN1991",
        "yCap": 16429.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "HUN1992",
        "yCap": 15932.5,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "HUN1993",
        "yCap": 15858.79,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "HUN1994",
        "yCap": 16348.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "HUN1995",
        "yCap": 16615.16,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "HUN1996",
        "yCap": 16657.44,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "HUN1997",
        "yCap": 17215.66,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "HUN1998",
        "yCap": 17928.73,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "HUN1999",
        "yCap": 18531.75,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "HUN2000",
        "yCap": 19412.18,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "HUN2001",
        "yCap": 20249.45,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "HUN2002",
        "yCap": 21270.01,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "HUN2003",
        "yCap": 22200.1,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "HUN2004",
        "yCap": 23362.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "HUN2005",
        "yCap": 24414.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "HUN2006",
        "yCap": 25417.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "HUN2007",
        "yCap": 25527.56,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "HUN2008",
        "yCap": 25829.03,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "HUN2009",
        "yCap": 24162.27,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "HUN2010",
        "yCap": 24477.58,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "HUN2011",
        "yCap": 25005.29,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "HUN2012",
        "yCap": 24820.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "HUN2013",
        "yCap": 25337.59,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "HUN2014",
        "yCap": 26481.17,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "HUN2015",
        "yCap": 27528.31,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "HUN2016",
        "yCap": 28217.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "HUN2017",
        "yCap": 29501.12,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "HUN2018",
        "yCap": 31122.5,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "HUN2019",
        "yCap": 32649.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "HUN2020",
        "yCap": 31231.6,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "HUN2021",
        "yCap": 33593.15,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Hungary",
        "Alpha-3": "HUN",
        "Alpha-2": "HU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "HUN2022",
        "yCap": 33593.15,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "ISL1990",
        "yCap": 33666.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "ISL1991",
        "yCap": 33666.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "ISL1992",
        "yCap": 33666.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "ISL1993",
        "yCap": 33666.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "ISL1994",
        "yCap": 33666.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "ISL1995",
        "yCap": 33666.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "ISL1996",
        "yCap": 35014.2,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "ISL1997",
        "yCap": 36732.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "ISL1998",
        "yCap": 39017.44,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "ISL1999",
        "yCap": 40103.87,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "ISL2000",
        "yCap": 41530.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "ISL2001",
        "yCap": 42626.92,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "ISL2002",
        "yCap": 42484.52,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "ISL2003",
        "yCap": 43095.36,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "ISL2004",
        "yCap": 46051.74,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "ISL2005",
        "yCap": 48104.65,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "ISL2006",
        "yCap": 49956.85,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "ISL2007",
        "yCap": 52827.01,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "ISL2008",
        "yCap": 52999.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "ISL2009",
        "yCap": 48770.95,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "ISL2010",
        "yCap": 47457.62,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "ISL2011",
        "yCap": 48186.17,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "ISL2012",
        "yCap": 48440.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "ISL2013",
        "yCap": 50168.69,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "ISL2014",
        "yCap": 50450.74,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "ISL2015",
        "yCap": 52142.93,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "ISL2016",
        "yCap": 54665.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "ISL2017",
        "yCap": 55638.49,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "ISL2018",
        "yCap": 56816.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "ISL2019",
        "yCap": 56923.17,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "ISL2020",
        "yCap": 52174.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "ISL2021",
        "yCap": 53586.16,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Iceland",
        "Alpha-3": "ISL",
        "Alpha-2": "IS",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "ISL2022",
        "yCap": 53586.16,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "IND1990",
        "yCap": 1819.02,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "IND1991",
        "yCap": 1800.01,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "IND1992",
        "yCap": 1859.71,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "IND1993",
        "yCap": 1908.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "IND1994",
        "yCap": 1994.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "IND1995",
        "yCap": 2103.73,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "IND1996",
        "yCap": 2218.82,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "IND1997",
        "yCap": 2264.8,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "IND1998",
        "yCap": 2359.89,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "IND1999",
        "yCap": 2521.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "IND2000",
        "yCap": 2571.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "IND2001",
        "yCap": 2646.88,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "IND2002",
        "yCap": 2699.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "IND2003",
        "yCap": 2861.57,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "IND2004",
        "yCap": 3037.06,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "IND2005",
        "yCap": 3225.54,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "IND2006",
        "yCap": 3432.82,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "IND2007",
        "yCap": 3642,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "IND2008",
        "yCap": 3701.4,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "IND2009",
        "yCap": 3937.24,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "IND2010",
        "yCap": 4213.36,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "IND2011",
        "yCap": 4374.23,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "IND2012",
        "yCap": 4551.86,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "IND2013",
        "yCap": 4780.12,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "IND2014",
        "yCap": 5071.05,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "IND2015",
        "yCap": 5411.88,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "IND2016",
        "yCap": 5789.68,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "IND2017",
        "yCap": 6112.07,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "IND2018",
        "yCap": 6436.15,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "IND2019",
        "yCap": 6608.62,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "IND2020",
        "yCap": 6114.03,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "IND2021",
        "yCap": 6592.04,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "India",
        "Alpha-3": "IND",
        "Alpha-2": "IN",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "IND2022",
        "yCap": 6592.04,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "IDN1990",
        "yCap": 4519.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "IDN1991",
        "yCap": 4742.62,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "IDN1992",
        "yCap": 4965.13,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "IDN1993",
        "yCap": 5200.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "IDN1994",
        "yCap": 5500.55,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "IDN1995",
        "yCap": 5856.21,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "IDN1996",
        "yCap": 6212.67,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "IDN1997",
        "yCap": 6401.22,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "IDN1998",
        "yCap": 5474.6,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "IDN1999",
        "yCap": 5435.76,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "IDN2000",
        "yCap": 5621.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "IDN2001",
        "yCap": 5744.49,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "IDN2002",
        "yCap": 5921.08,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "IDN2003",
        "yCap": 6121.67,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "IDN2004",
        "yCap": 6348.29,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "IDN2005",
        "yCap": 6625.61,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "IDN2006",
        "yCap": 6899.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "IDN2007",
        "yCap": 7242.02,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "IDN2008",
        "yCap": 7578.21,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "IDN2009",
        "yCap": 7828.81,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "IDN2010",
        "yCap": 8212.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "IDN2011",
        "yCap": 8610.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "IDN2012",
        "yCap": 9015.8,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "IDN2013",
        "yCap": 9402.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "IDN2014",
        "yCap": 9759.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "IDN2015",
        "yCap": 10121.84,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "IDN2016",
        "yCap": 10519.3,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "IDN2017",
        "yCap": 10941.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "IDN2018",
        "yCap": 11397.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "IDN2019",
        "yCap": 11857.79,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "IDN2020",
        "yCap": 11515.74,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "IDN2021",
        "yCap": 11858.15,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Indonesia",
        "Alpha-3": "IDN",
        "Alpha-2": "ID",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "IDN2022",
        "yCap": 11858.15,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "IRN1990",
        "yCap": 9442.25,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "IRN1991",
        "yCap": 10239.68,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "IRN1992",
        "yCap": 10331.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "IRN1993",
        "yCap": 10114.04,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "IRN1994",
        "yCap": 9904.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "IRN1995",
        "yCap": 10006.93,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "IRN1996",
        "yCap": 10503.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "IRN1997",
        "yCap": 10495.13,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "IRN1998",
        "yCap": 10547.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "IRN1999",
        "yCap": 10589.98,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "IRN2000",
        "yCap": 11026.1,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "IRN2001",
        "yCap": 11098.45,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "IRN2002",
        "yCap": 11878.86,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "IRN2003",
        "yCap": 12785.93,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "IRN2004",
        "yCap": 13126.58,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "IRN2005",
        "yCap": 13328.96,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "IRN2006",
        "yCap": 13780.73,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "IRN2007",
        "yCap": 14689.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "IRN2008",
        "yCap": 14525.76,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "IRN2009",
        "yCap": 14473.83,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "IRN2010",
        "yCap": 15099.46,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "IRN2011",
        "yCap": 15302.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "IRN2012",
        "yCap": 14541.85,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "IRN2013",
        "yCap": 14113.46,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "IRN2014",
        "yCap": 14538.52,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "IRN2015",
        "yCap": 14010.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "IRN2016",
        "yCap": 14968.6,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "IRN2017",
        "yCap": 15163.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "IRN2018",
        "yCap": 14628.95,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "IRN2019",
        "yCap": 14084.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "IRN2020",
        "yCap": 14432.36,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "IRN2021",
        "yCap": 15004.69,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iran, Islamic Rep.",
        "Alpha-3": "IRN",
        "Alpha-2": "IR",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "IRN2022",
        "yCap": 15004.69,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1990,
        "ID": "IRQ1990",
        "yCap": 7449.47,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1991,
        "ID": "IRQ1991",
        "yCap": 2650.09,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1992,
        "ID": "IRQ1992",
        "yCap": 3410.74,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1993,
        "ID": "IRQ1993",
        "yCap": 4234.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1994,
        "ID": "IRQ1994",
        "yCap": 4190.64,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1995,
        "ID": "IRQ1995",
        "yCap": 4136.33,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1996,
        "ID": "IRQ1996",
        "yCap": 4445.65,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1997,
        "ID": "IRQ1997",
        "yCap": 5222.93,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1998,
        "ID": "IRQ1998",
        "yCap": 6822.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1999,
        "ID": "IRQ1999",
        "yCap": 7763.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2000,
        "ID": "IRQ2000",
        "yCap": 8779.79,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2001,
        "ID": "IRQ2001",
        "yCap": 8654.45,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2002,
        "ID": "IRQ2002",
        "yCap": 7693.86,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2003,
        "ID": "IRQ2003",
        "yCap": 4726.97,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2004,
        "ID": "IRQ2004",
        "yCap": 7044.69,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2005,
        "ID": "IRQ2005",
        "yCap": 6952.89,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2006,
        "ID": "IRQ2006",
        "yCap": 7292.89,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2007,
        "ID": "IRQ2007",
        "yCap": 7493.84,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2008,
        "ID": "IRQ2008",
        "yCap": 7955.7,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2009,
        "ID": "IRQ2009",
        "yCap": 7933.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2010,
        "ID": "IRQ2010",
        "yCap": 8178.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2011,
        "ID": "IRQ2011",
        "yCap": 8493.08,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2012,
        "ID": "IRQ2012",
        "yCap": 9251.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2013,
        "ID": "IRQ2013",
        "yCap": 9503.88,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2014,
        "ID": "IRQ2014",
        "yCap": 9194.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2015,
        "ID": "IRQ2015",
        "yCap": 9371.21,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2016,
        "ID": "IRQ2016",
        "yCap": 10404.2,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2017,
        "ID": "IRQ2017",
        "yCap": 9976.85,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2018,
        "ID": "IRQ2018",
        "yCap": 9995.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2019,
        "ID": "IRQ2019",
        "yCap": 10299.32,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2020,
        "ID": "IRQ2020",
        "yCap": 8919.8,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2021,
        "ID": "IRQ2021",
        "yCap": 8962.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Iraq",
        "Alpha-3": "IRQ",
        "Alpha-2": "IQ",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2022,
        "ID": "IRQ2022",
        "yCap": 8962.39,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "IRL1990",
        "yCap": 26706.26,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "IRL1991",
        "yCap": 27065.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "IRL1992",
        "yCap": 27780.23,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "IRL1993",
        "yCap": 28386,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "IRL1994",
        "yCap": 29901.75,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "IRL1995",
        "yCap": 32614.97,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "IRL1996",
        "yCap": 34745.8,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "IRL1997",
        "yCap": 38190.5,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "IRL1998",
        "yCap": 41107.3,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "IRL1999",
        "yCap": 44926.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "IRL2000",
        "yCap": 48500.35,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "IRL2001",
        "yCap": 50266.96,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "IRL2002",
        "yCap": 52342.86,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "IRL2003",
        "yCap": 53049.17,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "IRL2004",
        "yCap": 55623.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "IRL2005",
        "yCap": 57549,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "IRL2006",
        "yCap": 58812.31,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "IRL2007",
        "yCap": 60170.42,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "IRL2008",
        "yCap": 56312.5,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "IRL2009",
        "yCap": 52902.89,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "IRL2010",
        "yCap": 53500.91,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "IRL2011",
        "yCap": 53712.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "IRL2012",
        "yCap": 53482.2,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "IRL2013",
        "yCap": 53800.21,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "IRL2014",
        "yCap": 58027.84,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "IRL2015",
        "yCap": 71490.8,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "IRL2016",
        "yCap": 72106.8,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "IRL2017",
        "yCap": 77749.2,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "IRL2018",
        "yCap": 83340.39,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "IRL2019",
        "yCap": 86681.27,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "IRL2020",
        "yCap": 91099.75,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "IRL2021",
        "yCap": 102496.22,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Ireland",
        "Alpha-3": "IRL",
        "Alpha-2": "IE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "IRL2022",
        "yCap": 102496.22,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "IMN1990",
        "yCap": 21546.26,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "IMN1991",
        "yCap": 21372.8,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "IMN1992",
        "yCap": 21081.84,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "IMN1993",
        "yCap": 20893.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "IMN1994",
        "yCap": 21098.63,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "IMN1995",
        "yCap": 21483.99,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "IMN1996",
        "yCap": 21981.24,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "IMN1997",
        "yCap": 22717.27,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "IMN1998",
        "yCap": 23548.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "IMN1999",
        "yCap": 24300.48,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "IMN2000",
        "yCap": 25310.46,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "IMN2001",
        "yCap": 25961.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "IMN2002",
        "yCap": 26468.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "IMN2003",
        "yCap": 27049.29,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "IMN2004",
        "yCap": 28109.83,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "IMN2005",
        "yCap": 29015.44,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "IMN2006",
        "yCap": 30295.56,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "IMN2007",
        "yCap": 31705.85,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "IMN2008",
        "yCap": 31930.82,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "IMN2009",
        "yCap": 30195.77,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "IMN2010",
        "yCap": 30589.07,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "IMN2011",
        "yCap": 30918.19,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "IMN2012",
        "yCap": 30801.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "IMN2013",
        "yCap": 31011.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "IMN2014",
        "yCap": 31496.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "IMN2015",
        "yCap": 32309.12,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "IMN2016",
        "yCap": 32968.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "IMN2017",
        "yCap": 33882.6,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "IMN2018",
        "yCap": 34759.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "IMN2019",
        "yCap": 35523.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "IMN2020",
        "yCap": 34038.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "IMN2021",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Isle of Man",
        "Alpha-3": "IMN",
        "Alpha-2": "IM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "IMN2022",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "ISR1990",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "ISR1991",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "ISR1992",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "ISR1993",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "ISR1994",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "ISR1995",
        "yCap": 26668.62,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "ISR1996",
        "yCap": 27520.05,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "ISR1997",
        "yCap": 27858.4,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "ISR1998",
        "yCap": 28349.15,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "ISR1999",
        "yCap": 28556.8,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "ISR2000",
        "yCap": 30223.88,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "ISR2001",
        "yCap": 29618.42,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "ISR2002",
        "yCap": 28994.7,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "ISR2003",
        "yCap": 28872.82,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "ISR2004",
        "yCap": 29724.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "ISR2005",
        "yCap": 30412.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "ISR2006",
        "yCap": 31547.23,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "ISR2007",
        "yCap": 32861.67,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "ISR2008",
        "yCap": 33332.59,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "ISR2009",
        "yCap": 32832.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "ISR2010",
        "yCap": 34065.84,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "ISR2011",
        "yCap": 35301.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "ISR2012",
        "yCap": 35552.85,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "ISR2013",
        "yCap": 36436.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "ISR2014",
        "yCap": 37144.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "ISR2015",
        "yCap": 37321.02,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "ISR2016",
        "yCap": 38251.3,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "ISR2017",
        "yCap": 39121.3,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "ISR2018",
        "yCap": 39936.77,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "ISR2019",
        "yCap": 40810.05,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "ISR2020",
        "yCap": 39351.9,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "ISR2021",
        "yCap": 42061.24,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Israel",
        "Alpha-3": "ISR",
        "Alpha-2": "IL",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "ISR2022",
        "yCap": 42061.24,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "ITA1990",
        "yCap": 36585.68,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "ITA1991",
        "yCap": 37122.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "ITA1992",
        "yCap": 37407.11,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "ITA1993",
        "yCap": 37065.43,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "ITA1994",
        "yCap": 37855.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "ITA1995",
        "yCap": 38947.2,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "ITA1996",
        "yCap": 39429.5,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "ITA1997",
        "yCap": 40129.9,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "ITA1998",
        "yCap": 40844.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "ITA1999",
        "yCap": 41501.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "ITA2000",
        "yCap": 43053.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "ITA2001",
        "yCap": 43869.43,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "ITA2002",
        "yCap": 43915.39,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "ITA2003",
        "yCap": 43781.22,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "ITA2004",
        "yCap": 44118.04,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "ITA2005",
        "yCap": 44260.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "ITA2006",
        "yCap": 44918.17,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "ITA2007",
        "yCap": 45356.54,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "ITA2008",
        "yCap": 44623.6,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "ITA2009",
        "yCap": 42074.92,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "ITA2010",
        "yCap": 42664.36,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "ITA2011",
        "yCap": 42892.31,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "ITA2012",
        "yCap": 41501.71,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "ITA2013",
        "yCap": 40268.11,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "ITA2014",
        "yCap": 39898.53,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "ITA2015",
        "yCap": 40247.83,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "ITA2016",
        "yCap": 40837.74,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "ITA2017",
        "yCap": 41581.12,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "ITA2018",
        "yCap": 42045.92,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "ITA2019",
        "yCap": 42739.05,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "ITA2020",
        "yCap": 39065.28,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "ITA2021",
        "yCap": 41929.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Italy",
        "Alpha-3": "ITA",
        "Alpha-2": "IT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "ITA2022",
        "yCap": 41929.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "JAM1990",
        "yCap": 8692.5,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "JAM1991",
        "yCap": 9038.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "JAM1992",
        "yCap": 9128.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "JAM1993",
        "yCap": 9889.12,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "JAM1994",
        "yCap": 9924.54,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "JAM1995",
        "yCap": 10055.8,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "JAM1996",
        "yCap": 9951.09,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "JAM1997",
        "yCap": 9752.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "JAM1998",
        "yCap": 9445.08,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "JAM1999",
        "yCap": 9471.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "JAM2000",
        "yCap": 9496.54,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "JAM2001",
        "yCap": 9575.88,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "JAM2002",
        "yCap": 9719.6,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "JAM2003",
        "yCap": 10027.37,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "JAM2004",
        "yCap": 10110.54,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "JAM2005",
        "yCap": 10151.98,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "JAM2006",
        "yCap": 10396.59,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "JAM2007",
        "yCap": 10500.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "JAM2008",
        "yCap": 10376.1,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "JAM2009",
        "yCap": 9885.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "JAM2010",
        "yCap": 9700.03,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "JAM2011",
        "yCap": 9823.77,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "JAM2012",
        "yCap": 9715.21,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "JAM2013",
        "yCap": 9718.62,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "JAM2014",
        "yCap": 9745.55,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "JAM2015",
        "yCap": 9800.51,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "JAM2016",
        "yCap": 9906.04,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "JAM2017",
        "yCap": 9984.58,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "JAM2018",
        "yCap": 10160.76,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "JAM2019",
        "yCap": 10244.37,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "JAM2020",
        "yCap": 9198.15,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "JAM2021",
        "yCap": 9596.56,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Jamaica",
        "Alpha-3": "JAM",
        "Alpha-2": "JM",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "JAM2022",
        "yCap": 9596.56,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "JPN1990",
        "yCap": 32846.39,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "JPN1991",
        "yCap": 33870.37,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "JPN1992",
        "yCap": 34048.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "JPN1993",
        "yCap": 33782.74,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "JPN1994",
        "yCap": 34053.52,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "JPN1995",
        "yCap": 34867.58,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "JPN1996",
        "yCap": 35878.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "JPN1997",
        "yCap": 36144.62,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "JPN1998",
        "yCap": 35588.63,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "JPN1999",
        "yCap": 35405.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "JPN2000",
        "yCap": 36323.1,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "JPN2001",
        "yCap": 36375.59,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "JPN2002",
        "yCap": 36306.33,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "JPN2003",
        "yCap": 36784.88,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "JPN2004",
        "yCap": 37576.39,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "JPN2005",
        "yCap": 38250.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "JPN2006",
        "yCap": 38751.01,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "JPN2007",
        "yCap": 39280.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "JPN2008",
        "yCap": 38781.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "JPN2009",
        "yCap": 36577.86,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "JPN2010",
        "yCap": 38069.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "JPN2011",
        "yCap": 38149.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "JPN2012",
        "yCap": 38735.9,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "JPN2013",
        "yCap": 39569.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "JPN2014",
        "yCap": 39739.54,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "JPN2015",
        "yCap": 40402.58,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "JPN2016",
        "yCap": 40727.97,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "JPN2017",
        "yCap": 41444.22,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "JPN2018",
        "yCap": 41739.2,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "JPN2019",
        "yCap": 41697.41,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "JPN2020",
        "yCap": 39935.46,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "JPN2021",
        "yCap": 40784.38,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Japan",
        "Alpha-3": "JPN",
        "Alpha-2": "JP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "JPN2022",
        "yCap": 40784.38,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1990,
        "ID": "JOR1990",
        "yCap": 8127.63,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1991,
        "ID": "JOR1991",
        "yCap": 7839.87,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1992,
        "ID": "JOR1992",
        "yCap": 8500.03,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1993,
        "ID": "JOR1993",
        "yCap": 8426.27,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1994,
        "ID": "JOR1994",
        "yCap": 8426.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1995,
        "ID": "JOR1995",
        "yCap": 8587.85,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1996,
        "ID": "JOR1996",
        "yCap": 8480.82,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1997,
        "ID": "JOR1997",
        "yCap": 8530.06,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1998,
        "ID": "JOR1998",
        "yCap": 8586.14,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1999,
        "ID": "JOR1999",
        "yCap": 8686.44,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2000,
        "ID": "JOR2000",
        "yCap": 8866.48,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2001,
        "ID": "JOR2001",
        "yCap": 9140.07,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2002,
        "ID": "JOR2002",
        "yCap": 9463.04,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2003,
        "ID": "JOR2003",
        "yCap": 9636.59,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2004,
        "ID": "JOR2004",
        "yCap": 10204.41,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2005,
        "ID": "JOR2005",
        "yCap": 10751.77,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2006,
        "ID": "JOR2006",
        "yCap": 10862.46,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2007,
        "ID": "JOR2007",
        "yCap": 11028.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2008,
        "ID": "JOR2008",
        "yCap": 11540.4,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2009,
        "ID": "JOR2009",
        "yCap": 11856.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2010,
        "ID": "JOR2010",
        "yCap": 11866.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2011,
        "ID": "JOR2011",
        "yCap": 11885.24,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2012,
        "ID": "JOR2012",
        "yCap": 12001.99,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2013,
        "ID": "JOR2013",
        "yCap": 11542.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2014,
        "ID": "JOR2014",
        "yCap": 10605.35,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2015,
        "ID": "JOR2015",
        "yCap": 9912.71,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2016,
        "ID": "JOR2016",
        "yCap": 9633.1,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2017,
        "ID": "JOR2017",
        "yCap": 9629.1,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2018,
        "ID": "JOR2018",
        "yCap": 9584.51,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2019,
        "ID": "JOR2019",
        "yCap": 9551.17,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2020,
        "ID": "JOR2020",
        "yCap": 9203.38,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2021,
        "ID": "JOR2021",
        "yCap": 9223.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Jordan",
        "Alpha-3": "JOR",
        "Alpha-2": "JO",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2022,
        "ID": "JOR2022",
        "yCap": 9223.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "KAZ1990",
        "yCap": 13475.64,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "KAZ1991",
        "yCap": 11917.71,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "KAZ1992",
        "yCap": 11294.74,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "KAZ1993",
        "yCap": 10292.2,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "KAZ1994",
        "yCap": 9126.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "KAZ1995",
        "yCap": 8552.45,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "KAZ1996",
        "yCap": 8726.54,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "KAZ1997",
        "yCap": 9016,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "KAZ1998",
        "yCap": 8998.9,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "KAZ1999",
        "yCap": 9330.56,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "KAZ2000",
        "yCap": 10275.76,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "KAZ2001",
        "yCap": 11682.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "KAZ2002",
        "yCap": 12827.23,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "KAZ2003",
        "yCap": 13973.08,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "KAZ2004",
        "yCap": 15208.44,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "KAZ2005",
        "yCap": 16536.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "KAZ2006",
        "yCap": 18112.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "KAZ2007",
        "yCap": 19500.48,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "KAZ2008",
        "yCap": 19770.22,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "KAZ2009",
        "yCap": 19614.74,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "KAZ2010",
        "yCap": 20751.26,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "KAZ2011",
        "yCap": 21970.08,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "KAZ2012",
        "yCap": 22702.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "KAZ2013",
        "yCap": 23720.82,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "KAZ2014",
        "yCap": 24355.76,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "KAZ2015",
        "yCap": 24290.42,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "KAZ2016",
        "yCap": 24210.86,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "KAZ2017",
        "yCap": 24862.97,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "KAZ2018",
        "yCap": 25544.34,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "KAZ2019",
        "yCap": 26351.8,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "KAZ2020",
        "yCap": 25361.51,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "KAZ2021",
        "yCap": 26110.53,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kazakhstan",
        "Alpha-3": "KAZ",
        "Alpha-2": "KZ",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "KAZ2022",
        "yCap": 26110.53,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "KEN1990",
        "yCap": 3655.36,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "KEN1991",
        "yCap": 3590.74,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "KEN1992",
        "yCap": 3455.49,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "KEN1993",
        "yCap": 3367.17,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "KEN1994",
        "yCap": 3357.71,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "KEN1995",
        "yCap": 3408.54,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "KEN1996",
        "yCap": 3455.1,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "KEN1997",
        "yCap": 3379.9,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "KEN1998",
        "yCap": 3398.47,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "KEN1999",
        "yCap": 3380.78,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "KEN2000",
        "yCap": 3303.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "KEN2001",
        "yCap": 3325.91,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "KEN2002",
        "yCap": 3244.18,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "KEN2003",
        "yCap": 3241.67,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "KEN2004",
        "yCap": 3306.79,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "KEN2005",
        "yCap": 3399.4,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "KEN2006",
        "yCap": 3513.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "KEN2007",
        "yCap": 3644.33,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "KEN2008",
        "yCap": 3545.59,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "KEN2009",
        "yCap": 3555.99,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "KEN2010",
        "yCap": 3735.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "KEN2011",
        "yCap": 3824.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "KEN2012",
        "yCap": 3899.17,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "KEN2013",
        "yCap": 3950.88,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "KEN2014",
        "yCap": 4055.11,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "KEN2015",
        "yCap": 4163.92,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "KEN2016",
        "yCap": 4244.86,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "KEN2017",
        "yCap": 4312.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "KEN2018",
        "yCap": 4464.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "KEN2019",
        "yCap": 4601.21,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "KEN2020",
        "yCap": 4498.38,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "KEN2021",
        "yCap": 4743.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Kenya",
        "Alpha-3": "KEN",
        "Alpha-2": "KE",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "KEN2022",
        "yCap": 4743.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "KIR1990",
        "yCap": 2045.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "KIR1991",
        "yCap": 2005.51,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "KIR1992",
        "yCap": 1989.63,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "KIR1993",
        "yCap": 1976.68,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "KIR1994",
        "yCap": 1981.43,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "KIR1995",
        "yCap": 1951.84,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "KIR1996",
        "yCap": 1952.54,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "KIR1997",
        "yCap": 1950.33,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "KIR1998",
        "yCap": 2041.96,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "KIR1999",
        "yCap": 1976.37,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "KIR2000",
        "yCap": 2063.56,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "KIR2001",
        "yCap": 1994.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "KIR2002",
        "yCap": 2028.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "KIR2003",
        "yCap": 2027.56,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "KIR2004",
        "yCap": 1954.68,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "KIR2005",
        "yCap": 2010.93,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "KIR2006",
        "yCap": 1971.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "KIR2007",
        "yCap": 1973.75,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "KIR2008",
        "yCap": 1895.89,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "KIR2009",
        "yCap": 1874.51,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "KIR2010",
        "yCap": 1819.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "KIR2011",
        "yCap": 1819.66,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "KIR2012",
        "yCap": 1883.17,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "KIR2013",
        "yCap": 1932.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "KIR2014",
        "yCap": 1883.23,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "KIR2015",
        "yCap": 2038.49,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "KIR2016",
        "yCap": 1997.82,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "KIR2017",
        "yCap": 1962.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "KIR2018",
        "yCap": 2033.73,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "KIR2019",
        "yCap": 1990.52,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "KIR2020",
        "yCap": 1944.85,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "KIR2021",
        "yCap": 1937.09,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kiribati",
        "Alpha-3": "KIR",
        "Alpha-2": "KI",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "KIR2022",
        "yCap": 1937.09,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1990,
        "ID": "PRK1990",
        "yCap": 12367.59,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1991,
        "ID": "PRK1991",
        "yCap": 12505.46,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1992,
        "ID": "PRK1992",
        "yCap": 12903.06,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1993,
        "ID": "PRK1993",
        "yCap": 13250.15,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1994,
        "ID": "PRK1994",
        "yCap": 13652.2,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1995,
        "ID": "PRK1995",
        "yCap": 14030.41,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1996,
        "ID": "PRK1996",
        "yCap": 14250.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1997,
        "ID": "PRK1997",
        "yCap": 14345.62,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1998,
        "ID": "PRK1998",
        "yCap": 13907.32,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 1999,
        "ID": "PRK1999",
        "yCap": 14177.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2000,
        "ID": "PRK2000",
        "yCap": 14657.18,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2001,
        "ID": "PRK2001",
        "yCap": 14767.58,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2002,
        "ID": "PRK2002",
        "yCap": 15202.57,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2003,
        "ID": "PRK2003",
        "yCap": 15690.84,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2004,
        "ID": "PRK2004",
        "yCap": 16635.31,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2005,
        "ID": "PRK2005",
        "yCap": 17160.26,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2006,
        "ID": "PRK2006",
        "yCap": 17930.46,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2007,
        "ID": "PRK2007",
        "yCap": 18645.08,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2008,
        "ID": "PRK2008",
        "yCap": 18659.79,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2009,
        "ID": "PRK2009",
        "yCap": 18352.43,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2010,
        "ID": "PRK2010",
        "yCap": 19761.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2011,
        "ID": "PRK2011",
        "yCap": 20971.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2012,
        "ID": "PRK2012",
        "yCap": 21618,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2013,
        "ID": "PRK2013",
        "yCap": 22402.01,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2014,
        "ID": "PRK2014",
        "yCap": 22596.64,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2015,
        "ID": "PRK2015",
        "yCap": 21826.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2016,
        "ID": "PRK2016",
        "yCap": 22024.82,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2017,
        "ID": "PRK2017",
        "yCap": 22747.82,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2018,
        "ID": "PRK2018",
        "yCap": 23336.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2019,
        "ID": "PRK2019",
        "yCap": 23421.25,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2020,
        "ID": "PRK2020",
        "yCap": 20593.57,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2021,
        "ID": "PRK2021",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Korea, Dem. People's Rep.",
        "Alpha-3": "PRK",
        "Alpha-2": "KP",
        "Region": "EAP",
        "IncGroup": "Low",
        "RegIncGrp": "EAP-Low",
        "Time": 2022,
        "ID": "PRK2022",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "KOR1990",
        "yCap": 12656.4,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "KOR1991",
        "yCap": 13882.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "KOR1992",
        "yCap": 14590.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "KOR1993",
        "yCap": 15436.39,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "KOR1994",
        "yCap": 16698.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "KOR1995",
        "yCap": 18120.49,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "KOR1996",
        "yCap": 19364.94,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "KOR1997",
        "yCap": 20367.97,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "KOR1998",
        "yCap": 19184.22,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "KOR1999",
        "yCap": 21232.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "KOR2000",
        "yCap": 22963.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "KOR2001",
        "yCap": 23893.9,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "KOR2002",
        "yCap": 25591.4,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "KOR2003",
        "yCap": 26260.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "KOR2004",
        "yCap": 27515.95,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "KOR2005",
        "yCap": 28640.71,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "KOR2006",
        "yCap": 29990.52,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "KOR2007",
        "yCap": 31569.93,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "KOR2008",
        "yCap": 32275.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "KOR2009",
        "yCap": 32363.97,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "KOR2010",
        "yCap": 34394.49,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "KOR2011",
        "yCap": 35388.98,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "KOR2012",
        "yCap": 36049.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "KOR2013",
        "yCap": 37021.13,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "KOR2014",
        "yCap": 37967.48,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "KOR2015",
        "yCap": 38828.74,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "KOR2016",
        "yCap": 39814.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "KOR2017",
        "yCap": 40957.42,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "KOR2018",
        "yCap": 41965.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "KOR2019",
        "yCap": 42758.59,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "KOR2020",
        "yCap": 42396.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "KOR2021",
        "yCap": 44232.21,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Korea, Rep.",
        "Alpha-3": "KOR",
        "Alpha-2": "KR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "KOR2022",
        "yCap": 44232.21,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "XKX1990",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "XKX1991",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "XKX1992",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "XKX1993",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "XKX1994",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "XKX1995",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "XKX1996",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "XKX1997",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "XKX1998",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "XKX1999",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "XKX2000",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "XKX2001",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "XKX2002",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "XKX2003",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "XKX2004",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "XKX2005",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "XKX2006",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "XKX2007",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "XKX2008",
        "yCap": 7032.45,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "XKX2009",
        "yCap": 7327.44,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "XKX2010",
        "yCap": 7627.89,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "XKX2011",
        "yCap": 8040.59,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "XKX2012",
        "yCap": 8105.37,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "XKX2013",
        "yCap": 8486.57,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "XKX2014",
        "yCap": 8796.63,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "XKX2015",
        "yCap": 9445.11,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "XKX2016",
        "yCap": 10031.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "XKX2017",
        "yCap": 10436.17,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "XKX2018",
        "yCap": 10755.17,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "XKX2019",
        "yCap": 11318.46,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "XKX2020",
        "yCap": 10706.51,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "XKX2021",
        "yCap": 11884.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kosovo",
        "Alpha-3": "XKX",
        "Alpha-2": "XK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "XKX2022",
        "yCap": 11884.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "KWT1990",
        "yCap": 41446.96,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "KWT1991",
        "yCap": 41446.96,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "KWT1992",
        "yCap": 41446.96,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "KWT1993",
        "yCap": 54426.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "KWT1994",
        "yCap": 59468.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "KWT1995",
        "yCap": 61826.12,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "KWT1996",
        "yCap": 60443.92,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "KWT1997",
        "yCap": 59894.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "KWT1998",
        "yCap": 60105.81,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "KWT1999",
        "yCap": 57210.54,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "KWT2000",
        "yCap": 58117.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "KWT2001",
        "yCap": 56580.99,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "KWT2002",
        "yCap": 56701.06,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "KWT2003",
        "yCap": 64811.18,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "KWT2004",
        "yCap": 69723.62,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "KWT2005",
        "yCap": 74294.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "KWT2006",
        "yCap": 75551.11,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "KWT2007",
        "yCap": 75498.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "KWT2008",
        "yCap": 73162.9,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "KWT2009",
        "yCap": 64468.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "KWT2010",
        "yCap": 59780.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "KWT2011",
        "yCap": 61356.94,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "KWT2012",
        "yCap": 60588.16,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "KWT2013",
        "yCap": 57051.74,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "KWT2014",
        "yCap": 55583.56,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "KWT2015",
        "yCap": 53808.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "KWT2016",
        "yCap": 53476.12,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "KWT2017",
        "yCap": 50007.3,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "KWT2018",
        "yCap": 48943.01,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "KWT2019",
        "yCap": 47314.8,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "KWT2020",
        "yCap": 43922.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "KWT2021",
        "yCap": 43922.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kuwait",
        "Alpha-3": "KWT",
        "Alpha-2": "KW",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "KWT2022",
        "yCap": 43922.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1990,
        "ID": "KGZ1990",
        "yCap": 5158.32,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1991,
        "ID": "KGZ1991",
        "yCap": 4671.52,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1992,
        "ID": "KGZ1992",
        "yCap": 3978.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1993,
        "ID": "KGZ1993",
        "yCap": 3362.83,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1994,
        "ID": "KGZ1994",
        "yCap": 2688.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1995,
        "ID": "KGZ1995",
        "yCap": 2517.28,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1996,
        "ID": "KGZ1996",
        "yCap": 2656.02,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1997,
        "ID": "KGZ1997",
        "yCap": 2877.1,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1998,
        "ID": "KGZ1998",
        "yCap": 2893.42,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1999,
        "ID": "KGZ1999",
        "yCap": 2954.95,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2000,
        "ID": "KGZ2000",
        "yCap": 3078.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2001,
        "ID": "KGZ2001",
        "yCap": 3212.13,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2002,
        "ID": "KGZ2002",
        "yCap": 3182.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2003,
        "ID": "KGZ2003",
        "yCap": 3370.43,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2004,
        "ID": "KGZ2004",
        "yCap": 3563.88,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2005,
        "ID": "KGZ2005",
        "yCap": 3517.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2006,
        "ID": "KGZ2006",
        "yCap": 3588.09,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2007,
        "ID": "KGZ2007",
        "yCap": 3857.65,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2008,
        "ID": "KGZ2008",
        "yCap": 4142.21,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2009,
        "ID": "KGZ2009",
        "yCap": 4210.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2010,
        "ID": "KGZ2010",
        "yCap": 4141.08,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2011,
        "ID": "KGZ2011",
        "yCap": 4334.66,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2012,
        "ID": "KGZ2012",
        "yCap": 4259.32,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2013,
        "ID": "KGZ2013",
        "yCap": 4631.4,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2014,
        "ID": "KGZ2014",
        "yCap": 4722.09,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2015,
        "ID": "KGZ2015",
        "yCap": 4805.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2016,
        "ID": "KGZ2016",
        "yCap": 4912.38,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2017,
        "ID": "KGZ2017",
        "yCap": 5046.69,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2018,
        "ID": "KGZ2018",
        "yCap": 5133.15,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2019,
        "ID": "KGZ2019",
        "yCap": 5258.37,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2020,
        "ID": "KGZ2020",
        "yCap": 4726.2,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2021,
        "ID": "KGZ2021",
        "yCap": 4814.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Kyrgyz Republic",
        "Alpha-3": "KGZ",
        "Alpha-2": "KG",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2022,
        "ID": "KGZ2022",
        "yCap": 4814.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "LAO1990",
        "yCap": 1939.09,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "LAO1991",
        "yCap": 1966.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "LAO1992",
        "yCap": 2019.39,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "LAO1993",
        "yCap": 2082.1,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "LAO1994",
        "yCap": 2194.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "LAO1995",
        "yCap": 2291.87,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "LAO1996",
        "yCap": 2394.07,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "LAO1997",
        "yCap": 2505.62,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "LAO1998",
        "yCap": 2557.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "LAO1999",
        "yCap": 2696.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "LAO2000",
        "yCap": 2804.82,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "LAO2001",
        "yCap": 2918.39,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "LAO2002",
        "yCap": 3043.48,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "LAO2003",
        "yCap": 3181.05,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "LAO2004",
        "yCap": 3336.9,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "LAO2005",
        "yCap": 3522.29,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "LAO2006",
        "yCap": 3765.65,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "LAO2007",
        "yCap": 3988.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "LAO2008",
        "yCap": 4234,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "LAO2009",
        "yCap": 4482.9,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "LAO2010",
        "yCap": 4793.22,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "LAO2011",
        "yCap": 5103.55,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "LAO2012",
        "yCap": 5434.83,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "LAO2013",
        "yCap": 5789.28,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "LAO2014",
        "yCap": 6145.5,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "LAO2015",
        "yCap": 6499.07,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "LAO2016",
        "yCap": 6850.58,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "LAO2017",
        "yCap": 7211.26,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "LAO2018",
        "yCap": 7546.33,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "LAO2019",
        "yCap": 7840.07,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "LAO2020",
        "yCap": 7763.95,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "LAO2021",
        "yCap": 7846.98,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lao PDR",
        "Alpha-3": "LAO",
        "Alpha-2": "LA",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "LAO2022",
        "yCap": 7846.98,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "LVA1990",
        "yCap": 9599.15,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "LVA1991",
        "yCap": 9599.15,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "LVA1992",
        "yCap": 9599.15,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "LVA1993",
        "yCap": 9599.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "LVA1994",
        "yCap": 9599.15,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "LVA1995",
        "yCap": 9599.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "LVA1996",
        "yCap": 9959.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "LVA1997",
        "yCap": 10947.78,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "LVA1998",
        "yCap": 11751.71,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "LVA1999",
        "yCap": 12174.3,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "LVA2000",
        "yCap": 12989.9,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "LVA2001",
        "yCap": 13990.84,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "LVA2002",
        "yCap": 15157.62,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "LVA2003",
        "yCap": 16593.92,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "LVA2004",
        "yCap": 18165.75,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "LVA2005",
        "yCap": 20331.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "LVA2006",
        "yCap": 22975.56,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "LVA2007",
        "yCap": 25466.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "LVA2008",
        "yCap": 24899.65,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "LVA2009",
        "yCap": 21704.32,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "LVA2010",
        "yCap": 21173.4,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "LVA2011",
        "yCap": 22117.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "LVA2012",
        "yCap": 23970.45,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "LVA2013",
        "yCap": 24715.07,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "LVA2014",
        "yCap": 25423.49,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "LVA2015",
        "yCap": 26628.35,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "LVA2016",
        "yCap": 27509.34,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "LVA2017",
        "yCap": 28673.56,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "LVA2018",
        "yCap": 30051.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "LVA2019",
        "yCap": 31038.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "LVA2020",
        "yCap": 30568.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "LVA2021",
        "yCap": 32081.45,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Latvia",
        "Alpha-3": "LVA",
        "Alpha-2": "LV",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "LVA2022",
        "yCap": 32081.45,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "LBN1990",
        "yCap": 5862.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "LBN1991",
        "yCap": 8587.34,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "LBN1992",
        "yCap": 9788.92,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "LBN1993",
        "yCap": 10634.39,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "LBN1994",
        "yCap": 11291.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "LBN1995",
        "yCap": 11802.42,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "LBN1996",
        "yCap": 12891.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "LBN1997",
        "yCap": 12795.05,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "LBN1998",
        "yCap": 13037.97,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "LBN1999",
        "yCap": 12752.06,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "LBN2000",
        "yCap": 12711.95,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "LBN2001",
        "yCap": 12993.86,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "LBN2002",
        "yCap": 13264.99,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "LBN2003",
        "yCap": 13516.33,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "LBN2004",
        "yCap": 14198.55,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "LBN2005",
        "yCap": 14365.59,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "LBN2006",
        "yCap": 14350.75,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "LBN2007",
        "yCap": 15394.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "LBN2008",
        "yCap": 16522.33,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "LBN2009",
        "yCap": 17979.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "LBN2010",
        "yCap": 19239.56,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "LBN2011",
        "yCap": 19216.97,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "LBN2012",
        "yCap": 19202.55,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "LBN2013",
        "yCap": 18180.37,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "LBN2014",
        "yCap": 16863.64,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "LBN2015",
        "yCap": 16611.72,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "LBN2016",
        "yCap": 17248.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "LBN2017",
        "yCap": 17808.08,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "LBN2018",
        "yCap": 17970.94,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "LBN2019",
        "yCap": 17172.2,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "LBN2020",
        "yCap": 13780.96,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "LBN2021",
        "yCap": 12977.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lebanon",
        "Alpha-3": "LBN",
        "Alpha-2": "LB",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "LBN2022",
        "yCap": 12977.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "LSO1990",
        "yCap": 1265.92,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "LSO1991",
        "yCap": 1331.53,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "LSO1992",
        "yCap": 1401.93,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "LSO1993",
        "yCap": 1430.38,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "LSO1994",
        "yCap": 1495.95,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "LSO1995",
        "yCap": 1526.45,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "LSO1996",
        "yCap": 1594.56,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "LSO1997",
        "yCap": 1638.96,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "LSO1998",
        "yCap": 1653.22,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "LSO1999",
        "yCap": 1653.92,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "LSO2000",
        "yCap": 1714.25,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "LSO2001",
        "yCap": 1774.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "LSO2002",
        "yCap": 1789.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "LSO2003",
        "yCap": 1874.94,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "LSO2004",
        "yCap": 1914.02,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "LSO2005",
        "yCap": 1988.33,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "LSO2006",
        "yCap": 2073.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "LSO2007",
        "yCap": 2152.66,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "LSO2008",
        "yCap": 2258.38,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "LSO2009",
        "yCap": 2214.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "LSO2010",
        "yCap": 2315.28,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "LSO2011",
        "yCap": 2404.36,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "LSO2012",
        "yCap": 2535.47,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "LSO2013",
        "yCap": 2557,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "LSO2014",
        "yCap": 2574.3,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "LSO2015",
        "yCap": 2625.64,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "LSO2016",
        "yCap": 2688.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "LSO2017",
        "yCap": 2571.69,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "LSO2018",
        "yCap": 2541.45,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "LSO2019",
        "yCap": 2533.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "LSO2020",
        "yCap": 2292.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "LSO2021",
        "yCap": 2295.23,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Lesotho",
        "Alpha-3": "LSO",
        "Alpha-2": "LS",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "LSO2022",
        "yCap": 2295.23,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "LBR1990",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "LBR1991",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "LBR1992",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "LBR1993",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "LBR1994",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "LBR1995",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "LBR1996",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "LBR1997",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "LBR1998",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "LBR1999",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "LBR2000",
        "yCap": 1725.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "LBR2001",
        "yCap": 1724.06,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "LBR2002",
        "yCap": 1742.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "LBR2003",
        "yCap": 1207.73,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "LBR2004",
        "yCap": 1224.57,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "LBR2005",
        "yCap": 1232.46,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "LBR2006",
        "yCap": 1258.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "LBR2007",
        "yCap": 1311.45,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "LBR2008",
        "yCap": 1349.03,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "LBR2009",
        "yCap": 1376.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "LBR2010",
        "yCap": 1418.68,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "LBR2011",
        "yCap": 1475.84,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "LBR2012",
        "yCap": 1538.41,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "LBR2013",
        "yCap": 1635.96,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "LBR2014",
        "yCap": 1613.87,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "LBR2015",
        "yCap": 1581.06,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "LBR2016",
        "yCap": 1525.46,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "LBR2017",
        "yCap": 1533.41,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "LBR2018",
        "yCap": 1521.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "LBR2019",
        "yCap": 1455.64,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "LBR2020",
        "yCap": 1383.83,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "LBR2021",
        "yCap": 1423.23,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Liberia",
        "Alpha-3": "LBR",
        "Alpha-2": "LR",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "LBR2022",
        "yCap": 1423.23,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1990,
        "ID": "LBY1990",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1991,
        "ID": "LBY1991",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1992,
        "ID": "LBY1992",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1993,
        "ID": "LBY1993",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1994,
        "ID": "LBY1994",
        "yCap": 26668.44,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1995,
        "ID": "LBY1995",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1996,
        "ID": "LBY1996",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1997,
        "ID": "LBY1997",
        "yCap": 26668.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1998,
        "ID": "LBY1998",
        "yCap": 26668.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 1999,
        "ID": "LBY1999",
        "yCap": 26668.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2000,
        "ID": "LBY2000",
        "yCap": 27130.47,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2001,
        "ID": "LBY2001",
        "yCap": 26040.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2002,
        "ID": "LBY2002",
        "yCap": 25173.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2003,
        "ID": "LBY2003",
        "yCap": 27744.88,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2004,
        "ID": "LBY2004",
        "yCap": 28244.26,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2005,
        "ID": "LBY2005",
        "yCap": 30782.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2006,
        "ID": "LBY2006",
        "yCap": 32040.95,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2007,
        "ID": "LBY2007",
        "yCap": 33345.95,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2008,
        "ID": "LBY2008",
        "yCap": 32590.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2009,
        "ID": "LBY2009",
        "yCap": 30510.86,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2010,
        "ID": "LBY2010",
        "yCap": 31394.16,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2011,
        "ID": "LBY2011",
        "yCap": 16356.36,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2012,
        "ID": "LBY2012",
        "yCap": 32214.91,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2013,
        "ID": "LBY2013",
        "yCap": 25907.76,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2014,
        "ID": "LBY2014",
        "yCap": 19569.9,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2015,
        "ID": "LBY2015",
        "yCap": 19108.94,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2016,
        "ID": "LBY2016",
        "yCap": 18554.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2017,
        "ID": "LBY2017",
        "yCap": 24212.91,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2018,
        "ID": "LBY2018",
        "yCap": 25734.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2019,
        "ID": "LBY2019",
        "yCap": 22535.45,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2020,
        "ID": "LBY2020",
        "yCap": 16924.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2021,
        "ID": "LBY2021",
        "yCap": 21965.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Libya",
        "Alpha-3": "LBY",
        "Alpha-2": "LY",
        "Region": "MENA",
        "IncGroup": "UpMid",
        "RegIncGrp": "MENA-UpMid",
        "Time": 2022,
        "ID": "LBY2022",
        "yCap": 21965.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "LIE1990",
        "yCap": 21546.26,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "LIE1991",
        "yCap": 21372.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "LIE1992",
        "yCap": 21081.84,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "LIE1993",
        "yCap": 20893.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "LIE1994",
        "yCap": 21098.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "LIE1995",
        "yCap": 21483.99,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "LIE1996",
        "yCap": 21981.24,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "LIE1997",
        "yCap": 22717.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "LIE1998",
        "yCap": 23548.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "LIE1999",
        "yCap": 24300.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "LIE2000",
        "yCap": 25310.46,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "LIE2001",
        "yCap": 25961.38,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "LIE2002",
        "yCap": 26468.77,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "LIE2003",
        "yCap": 27049.29,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "LIE2004",
        "yCap": 28109.83,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "LIE2005",
        "yCap": 29015.44,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "LIE2006",
        "yCap": 30295.56,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "LIE2007",
        "yCap": 31705.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "LIE2008",
        "yCap": 31930.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "LIE2009",
        "yCap": 30195.77,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "LIE2010",
        "yCap": 30589.07,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "LIE2011",
        "yCap": 30918.19,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "LIE2012",
        "yCap": 30801.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "LIE2013",
        "yCap": 31011.79,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "LIE2014",
        "yCap": 31496.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "LIE2015",
        "yCap": 32309.12,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "LIE2016",
        "yCap": 32968.77,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "LIE2017",
        "yCap": 33882.6,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "LIE2018",
        "yCap": 34759.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "LIE2019",
        "yCap": 35523.11,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "LIE2020",
        "yCap": 34038.75,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "LIE2021",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Liechtenstein",
        "Alpha-3": "LIE",
        "Alpha-2": "LI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "LIE2022",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "LTU1990",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "LTU1991",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "LTU1992",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "LTU1993",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "LTU1994",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "LTU1995",
        "yCap": 10640.36,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "LTU1996",
        "yCap": 11274.64,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "LTU1997",
        "yCap": 12302.1,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "LTU1998",
        "yCap": 13317.91,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "LTU1999",
        "yCap": 13259.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "LTU2000",
        "yCap": 13846.68,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "LTU2001",
        "yCap": 14872.38,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "LTU2002",
        "yCap": 16004.44,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "LTU2003",
        "yCap": 17839.78,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "LTU2004",
        "yCap": 19226.47,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "LTU2005",
        "yCap": 21053.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "LTU2006",
        "yCap": 22978,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "LTU2007",
        "yCap": 25835.37,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "LTU2008",
        "yCap": 26784.88,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "LTU2009",
        "yCap": 23065.06,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "LTU2010",
        "yCap": 23942.76,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "LTU2011",
        "yCap": 25968.58,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "LTU2012",
        "yCap": 27330.9,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "LTU2013",
        "yCap": 28589.03,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "LTU2014",
        "yCap": 29855.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "LTU2015",
        "yCap": 30748.2,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "LTU2016",
        "yCap": 31925.8,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "LTU2017",
        "yCap": 33761.87,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "LTU2018",
        "yCap": 35446.71,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "LTU2019",
        "yCap": 37184.45,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "LTU2020",
        "yCap": 37166.41,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "LTU2021",
        "yCap": 39305.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Lithuania",
        "Alpha-3": "LTU",
        "Alpha-2": "LT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "LTU2022",
        "yCap": 39305.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "LUX1990",
        "yCap": 70860.82,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "LUX1991",
        "yCap": 75961.67,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "LUX1992",
        "yCap": 76323.3,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "LUX1993",
        "yCap": 78468.92,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "LUX1994",
        "yCap": 80365.22,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "LUX1995",
        "yCap": 80379.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "LUX1996",
        "yCap": 80401.03,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "LUX1997",
        "yCap": 83702.81,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "LUX1998",
        "yCap": 88185.8,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "LUX1999",
        "yCap": 94115.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "LUX2000",
        "yCap": 99301.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "LUX2001",
        "yCap": 101143.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "LUX2002",
        "yCap": 103317.33,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "LUX2003",
        "yCap": 104743,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "LUX2004",
        "yCap": 107634.84,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "LUX2005",
        "yCap": 108632.36,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "LUX2006",
        "yCap": 113346.04,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "LUX2007",
        "yCap": 120647.82,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "LUX2008",
        "yCap": 118154.67,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "LUX2009",
        "yCap": 112230.08,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "LUX2010",
        "yCap": 114343.99,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "LUX2011",
        "yCap": 112998.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "LUX2012",
        "yCap": 112137.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "LUX2013",
        "yCap": 113050.66,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "LUX2014",
        "yCap": 113313.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "LUX2015",
        "yCap": 113182.73,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "LUX2016",
        "yCap": 116283.7,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "LUX2017",
        "yCap": 114985.84,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "LUX2018",
        "yCap": 114164.47,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "LUX2019",
        "yCap": 114542.5,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "LUX2020",
        "yCap": 111751.31,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "LUX2021",
        "yCap": 115683.49,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Luxembourg",
        "Alpha-3": "LUX",
        "Alpha-2": "LU",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "LUX2022",
        "yCap": 115683.49,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "MAC1990",
        "yCap": 47880.27,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "MAC1991",
        "yCap": 47966.59,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "MAC1992",
        "yCap": 52889.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "MAC1993",
        "yCap": 54413,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "MAC1994",
        "yCap": 55550.13,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "MAC1995",
        "yCap": 56243.99,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "MAC1996",
        "yCap": 54947.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "MAC1997",
        "yCap": 53808.52,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "MAC1998",
        "yCap": 50481.48,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "MAC1999",
        "yCap": 48509.75,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "MAC2000",
        "yCap": 50539.92,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "MAC2001",
        "yCap": 51144.12,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "MAC2002",
        "yCap": 54395.48,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "MAC2003",
        "yCap": 59037.94,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "MAC2004",
        "yCap": 72717.07,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "MAC2005",
        "yCap": 76483.91,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "MAC2006",
        "yCap": 84436.75,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "MAC2007",
        "yCap": 94134.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "MAC2008",
        "yCap": 94804.92,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "MAC2009",
        "yCap": 93540.67,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "MAC2010",
        "yCap": 114042.5,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "MAC2011",
        "yCap": 135365.39,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "MAC2012",
        "yCap": 144891.34,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "MAC2013",
        "yCap": 157602.48,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "MAC2014",
        "yCap": 151616.4,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "MAC2015",
        "yCap": 116855.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "MAC2016",
        "yCap": 113941.67,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "MAC2017",
        "yCap": 122977.51,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "MAC2018",
        "yCap": 128437.32,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "MAC2019",
        "yCap": 122822.12,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "MAC2020",
        "yCap": 55158.71,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "MAC2021",
        "yCap": 64797,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Macao SAR, China",
        "Alpha-3": "MAC",
        "Alpha-2": "MO",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "MAC2022",
        "yCap": 64797,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "MDG1990",
        "yCap": 1808.74,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "MDG1991",
        "yCap": 1644.51,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "MDG1992",
        "yCap": 1614.09,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "MDG1993",
        "yCap": 1597.77,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "MDG1994",
        "yCap": 1547.65,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "MDG1995",
        "yCap": 1524.49,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "MDG1996",
        "yCap": 1508.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "MDG1997",
        "yCap": 1515.45,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "MDG1998",
        "yCap": 1526.07,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "MDG1999",
        "yCap": 1549.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "MDG2000",
        "yCap": 1569.62,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "MDG2001",
        "yCap": 1614.39,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "MDG2002",
        "yCap": 1372.81,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "MDG2003",
        "yCap": 1463.57,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "MDG2004",
        "yCap": 1496.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "MDG2005",
        "yCap": 1522.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "MDG2006",
        "yCap": 1557.97,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "MDG2007",
        "yCap": 1599.44,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "MDG2008",
        "yCap": 1657.82,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "MDG2009",
        "yCap": 1546.37,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "MDG2010",
        "yCap": 1511.99,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "MDG2011",
        "yCap": 1493.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "MDG2012",
        "yCap": 1497.01,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "MDG2013",
        "yCap": 1491.07,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "MDG2014",
        "yCap": 1500.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "MDG2015",
        "yCap": 1508.37,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "MDG2016",
        "yCap": 1528.56,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "MDG2017",
        "yCap": 1548.16,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "MDG2018",
        "yCap": 1557.32,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "MDG2019",
        "yCap": 1585.47,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "MDG2020",
        "yCap": 1436.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "MDG2021",
        "yCap": 1463.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Madagascar",
        "Alpha-3": "MDG",
        "Alpha-2": "MG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "MDG2022",
        "yCap": 1463.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "MWI1990",
        "yCap": 939.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "MWI1991",
        "yCap": 990.85,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "MWI1992",
        "yCap": 892.43,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "MWI1993",
        "yCap": 965.38,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "MWI1994",
        "yCap": 877.25,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "MWI1995",
        "yCap": 1025.93,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "MWI1996",
        "yCap": 1079.88,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "MWI1997",
        "yCap": 1099.28,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "MWI1998",
        "yCap": 1118.72,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "MWI1999",
        "yCap": 1127.38,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "MWI2000",
        "yCap": 1119.1,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "MWI2001",
        "yCap": 1038.51,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "MWI2002",
        "yCap": 1030.56,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "MWI2003",
        "yCap": 1062.01,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "MWI2004",
        "yCap": 1090.41,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "MWI2005",
        "yCap": 1095.66,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "MWI2006",
        "yCap": 1115.44,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "MWI2007",
        "yCap": 1188.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "MWI2008",
        "yCap": 1242.86,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "MWI2009",
        "yCap": 1307.81,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "MWI2010",
        "yCap": 1357.87,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "MWI2011",
        "yCap": 1383.58,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "MWI2012",
        "yCap": 1370.3,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "MWI2013",
        "yCap": 1401.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "MWI2014",
        "yCap": 1440.81,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "MWI2015",
        "yCap": 1440.84,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "MWI2016",
        "yCap": 1437.04,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "MWI2017",
        "yCap": 1454.78,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "MWI2018",
        "yCap": 1478.42,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "MWI2019",
        "yCap": 1517.7,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "MWI2020",
        "yCap": 1489.6,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "MWI2021",
        "yCap": 1491.13,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malawi",
        "Alpha-3": "MWI",
        "Alpha-2": "MW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "MWI2022",
        "yCap": 1491.13,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "MYS1990",
        "yCap": 10607.77,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "MYS1991",
        "yCap": 11297.59,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "MYS1992",
        "yCap": 11963.27,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "MYS1993",
        "yCap": 12785.83,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "MYS1994",
        "yCap": 13579.71,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "MYS1995",
        "yCap": 14508.46,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "MYS1996",
        "yCap": 15533.76,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "MYS1997",
        "yCap": 16231.8,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "MYS1998",
        "yCap": 14650.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "MYS1999",
        "yCap": 15161.41,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "MYS2000",
        "yCap": 16089.86,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "MYS2001",
        "yCap": 15762.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "MYS2002",
        "yCap": 16199.73,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "MYS2003",
        "yCap": 16723.93,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "MYS2004",
        "yCap": 17439.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "MYS2005",
        "yCap": 17951.39,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "MYS2006",
        "yCap": 18535.05,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "MYS2007",
        "yCap": 19278.42,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "MYS2008",
        "yCap": 19792.27,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "MYS2009",
        "yCap": 19110.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "MYS2010",
        "yCap": 20171.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "MYS2011",
        "yCap": 20900.32,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "MYS2012",
        "yCap": 21690.46,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "MYS2013",
        "yCap": 22350.91,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "MYS2014",
        "yCap": 23328.34,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "MYS2015",
        "yCap": 24151.26,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "MYS2016",
        "yCap": 24859.8,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "MYS2017",
        "yCap": 25935.14,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "MYS2018",
        "yCap": 26835.81,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "MYS2019",
        "yCap": 27674.4,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "MYS2020",
        "yCap": 25830.97,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "MYS2021",
        "yCap": 26333.16,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malaysia",
        "Alpha-3": "MYS",
        "Alpha-2": "MY",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "MYS2022",
        "yCap": 26333.16,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1990,
        "ID": "MDV1990",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1991,
        "ID": "MDV1991",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1992,
        "ID": "MDV1992",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1993,
        "ID": "MDV1993",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1994,
        "ID": "MDV1994",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1995,
        "ID": "MDV1995",
        "yCap": 10430.79,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1996,
        "ID": "MDV1996",
        "yCap": 11010.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1997,
        "ID": "MDV1997",
        "yCap": 11704.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1998,
        "ID": "MDV1998",
        "yCap": 12362.97,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 1999,
        "ID": "MDV1999",
        "yCap": 12922.8,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2000,
        "ID": "MDV2000",
        "yCap": 13210.96,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2001,
        "ID": "MDV2001",
        "yCap": 12477.22,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2002,
        "ID": "MDV2002",
        "yCap": 13156.99,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2003,
        "ID": "MDV2003",
        "yCap": 14717.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2004,
        "ID": "MDV2004",
        "yCap": 15351.7,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2005,
        "ID": "MDV2005",
        "yCap": 13124.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2006,
        "ID": "MDV2006",
        "yCap": 16162.28,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2007,
        "ID": "MDV2007",
        "yCap": 16834.74,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2008,
        "ID": "MDV2008",
        "yCap": 17788.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2009,
        "ID": "MDV2009",
        "yCap": 15927.8,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2010,
        "ID": "MDV2010",
        "yCap": 16492.53,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2011,
        "ID": "MDV2011",
        "yCap": 17290.21,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2012,
        "ID": "MDV2012",
        "yCap": 17126.34,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2013,
        "ID": "MDV2013",
        "yCap": 17768.61,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2014,
        "ID": "MDV2014",
        "yCap": 18338.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2015,
        "ID": "MDV2015",
        "yCap": 18051.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2016,
        "ID": "MDV2016",
        "yCap": 18406.27,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2017,
        "ID": "MDV2017",
        "yCap": 18973.57,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2018,
        "ID": "MDV2018",
        "yCap": 19789.49,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2019,
        "ID": "MDV2019",
        "yCap": 20574.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2020,
        "ID": "MDV2020",
        "yCap": 13419.33,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2021,
        "ID": "MDV2021",
        "yCap": 18765.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Maldives",
        "Alpha-3": "MDV",
        "Alpha-2": "MV",
        "Region": "SA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SA-UpMid",
        "Time": 2022,
        "ID": "MDV2022",
        "yCap": 18765.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "MLI1990",
        "yCap": 1336.57,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "MLI1991",
        "yCap": 1464.26,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "MLI1992",
        "yCap": 1388.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "MLI1993",
        "yCap": 1403.25,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "MLI1994",
        "yCap": 1425.51,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "MLI1995",
        "yCap": 1408.29,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "MLI1996",
        "yCap": 1476.29,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "MLI1997",
        "yCap": 1513.61,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "MLI1998",
        "yCap": 1588.17,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "MLI1999",
        "yCap": 1633.12,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "MLI2000",
        "yCap": 1585.35,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "MLI2001",
        "yCap": 1774.68,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "MLI2002",
        "yCap": 1773.35,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "MLI2003",
        "yCap": 1873.99,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "MLI2004",
        "yCap": 1842.06,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "MLI2005",
        "yCap": 1898.62,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "MLI2006",
        "yCap": 1922.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "MLI2007",
        "yCap": 1925.06,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "MLI2008",
        "yCap": 1951.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "MLI2009",
        "yCap": 1980.06,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "MLI2010",
        "yCap": 2018.6,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "MLI2011",
        "yCap": 2017.14,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "MLI2012",
        "yCap": 1942.74,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "MLI2013",
        "yCap": 1930.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "MLI2014",
        "yCap": 2002.37,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "MLI2015",
        "yCap": 2060.1,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "MLI2016",
        "yCap": 2112.19,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "MLI2017",
        "yCap": 2153.85,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "MLI2018",
        "yCap": 2185.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "MLI2019",
        "yCap": 2219.05,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "MLI2020",
        "yCap": 2123.83,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "MLI2021",
        "yCap": 2120.62,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mali",
        "Alpha-3": "MLI",
        "Alpha-2": "ML",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "MLI2022",
        "yCap": 2120.62,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "MLT1990",
        "yCap": 16127.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "MLT1991",
        "yCap": 16681.18,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "MLT1992",
        "yCap": 17284.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "MLT1993",
        "yCap": 17879.45,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "MLT1994",
        "yCap": 18713.99,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "MLT1995",
        "yCap": 19762.67,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "MLT1996",
        "yCap": 20374.97,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "MLT1997",
        "yCap": 21284.39,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "MLT1998",
        "yCap": 22230.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "MLT1999",
        "yCap": 23142.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "MLT2000",
        "yCap": 27518.67,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "MLT2001",
        "yCap": 26991.88,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "MLT2002",
        "yCap": 27478.62,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "MLT2003",
        "yCap": 28410.77,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "MLT2004",
        "yCap": 28259.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "MLT2005",
        "yCap": 29030.47,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "MLT2006",
        "yCap": 29651.05,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "MLT2007",
        "yCap": 30958.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "MLT2008",
        "yCap": 31934.73,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "MLT2009",
        "yCap": 31335.74,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "MLT2010",
        "yCap": 32910.81,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "MLT2011",
        "yCap": 32924.42,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "MLT2012",
        "yCap": 33973.59,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "MLT2013",
        "yCap": 35333.39,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "MLT2014",
        "yCap": 37278.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "MLT2015",
        "yCap": 39896.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "MLT2016",
        "yCap": 40312.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "MLT2017",
        "yCap": 43508.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "MLT2018",
        "yCap": 44607.64,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "MLT2019",
        "yCap": 45425.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "MLT2020",
        "yCap": 40733.7,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "MLT2021",
        "yCap": 44650.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Malta",
        "Alpha-3": "MLT",
        "Alpha-2": "MT",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "MLT2022",
        "yCap": 44650.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "MHL1990",
        "yCap": 3426.38,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "MHL1991",
        "yCap": 3356.07,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "MHL1992",
        "yCap": 3523.68,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "MHL1993",
        "yCap": 3664.42,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "MHL1994",
        "yCap": 3810.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "MHL1995",
        "yCap": 4052.89,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "MHL1996",
        "yCap": 3576.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "MHL1997",
        "yCap": 3293.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "MHL1998",
        "yCap": 3216.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "MHL1999",
        "yCap": 3137.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "MHL2000",
        "yCap": 3156.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "MHL2001",
        "yCap": 3360.39,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "MHL2002",
        "yCap": 3480.8,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "MHL2003",
        "yCap": 3424.75,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "MHL2004",
        "yCap": 3473.91,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "MHL2005",
        "yCap": 3541.88,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "MHL2006",
        "yCap": 3557.49,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "MHL2007",
        "yCap": 3689.74,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "MHL2008",
        "yCap": 3421.66,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "MHL2009",
        "yCap": 3562.11,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "MHL2010",
        "yCap": 3767.68,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "MHL2011",
        "yCap": 3787.65,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "MHL2012",
        "yCap": 3793.69,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "MHL2013",
        "yCap": 3998.47,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "MHL2014",
        "yCap": 4026.85,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "MHL2015",
        "yCap": 4195.83,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "MHL2016",
        "yCap": 4376.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "MHL2017",
        "yCap": 4634.94,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "MHL2018",
        "yCap": 4955.13,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "MHL2019",
        "yCap": 5647.07,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "MHL2020",
        "yCap": 5710.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "MHL2021",
        "yCap": 5961.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Marshall Islands",
        "Alpha-3": "MHL",
        "Alpha-2": "MH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "MHL2022",
        "yCap": 5961.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "MRT1990",
        "yCap": 4611.94,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "MRT1991",
        "yCap": 4557.47,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "MRT1992",
        "yCap": 4467.28,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "MRT1993",
        "yCap": 4540.37,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "MRT1994",
        "yCap": 4252.87,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "MRT1995",
        "yCap": 4542.66,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "MRT1996",
        "yCap": 4711.05,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "MRT1997",
        "yCap": 4419.58,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "MRT1998",
        "yCap": 4424.41,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "MRT1999",
        "yCap": 4464.21,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "MRT2000",
        "yCap": 4170.9,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "MRT2001",
        "yCap": 4037.45,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "MRT2002",
        "yCap": 4006.36,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "MRT2003",
        "yCap": 4192.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "MRT2004",
        "yCap": 4296.54,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "MRT2005",
        "yCap": 4562.73,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "MRT2006",
        "yCap": 5278.55,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "MRT2007",
        "yCap": 5055.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "MRT2008",
        "yCap": 4914.83,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "MRT2009",
        "yCap": 4787.45,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "MRT2010",
        "yCap": 4773.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "MRT2011",
        "yCap": 4825.09,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "MRT2012",
        "yCap": 4885.69,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "MRT2013",
        "yCap": 4943.23,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "MRT2014",
        "yCap": 5020.14,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "MRT2015",
        "yCap": 5151.9,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "MRT2016",
        "yCap": 5080.81,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "MRT2017",
        "yCap": 5259.07,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "MRT2018",
        "yCap": 5367.22,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "MRT2019",
        "yCap": 5505.65,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "MRT2020",
        "yCap": 5314.85,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "MRT2021",
        "yCap": 5307.52,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mauritania",
        "Alpha-3": "MRT",
        "Alpha-2": "MR",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "MRT2022",
        "yCap": 5307.52,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "MUS1990",
        "yCap": 8233.91,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "MUS1991",
        "yCap": 8506.8,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "MUS1992",
        "yCap": 8942.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "MUS1993",
        "yCap": 9286.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "MUS1994",
        "yCap": 9535.74,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "MUS1995",
        "yCap": 9859.45,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "MUS1996",
        "yCap": 10304.45,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "MUS1997",
        "yCap": 10755.01,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "MUS1998",
        "yCap": 11288.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "MUS1999",
        "yCap": 11437.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "MUS2000",
        "yCap": 12254.3,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "MUS2001",
        "yCap": 12564.85,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "MUS2002",
        "yCap": 12679.43,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "MUS2003",
        "yCap": 13333.9,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "MUS2004",
        "yCap": 13824.3,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "MUS2005",
        "yCap": 13986.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "MUS2006",
        "yCap": 14599.26,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "MUS2007",
        "yCap": 15365.21,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "MUS2008",
        "yCap": 16134.47,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "MUS2009",
        "yCap": 16625.14,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "MUS2010",
        "yCap": 17311.63,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "MUS2011",
        "yCap": 17988.68,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "MUS2012",
        "yCap": 18566.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "MUS2013",
        "yCap": 19147.68,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "MUS2014",
        "yCap": 19844.49,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "MUS2015",
        "yCap": 20549.63,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "MUS2016",
        "yCap": 21328.69,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "MUS2017",
        "yCap": 22148.62,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "MUS2018",
        "yCap": 23023.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "MUS2019",
        "yCap": 23681.58,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "MUS2020",
        "yCap": 20224.22,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "MUS2021",
        "yCap": 20967.95,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mauritius",
        "Alpha-3": "MUS",
        "Alpha-2": "MU",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "MUS2022",
        "yCap": 20967.95,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "MEX1990",
        "yCap": 15355.63,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "MEX1991",
        "yCap": 15689.66,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "MEX1992",
        "yCap": 15931.48,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "MEX1993",
        "yCap": 15930.48,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "MEX1994",
        "yCap": 16402.26,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "MEX1995",
        "yCap": 15087.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "MEX1996",
        "yCap": 15825.09,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "MEX1997",
        "yCap": 16618.91,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "MEX1998",
        "yCap": 17184.94,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "MEX1999",
        "yCap": 17370.81,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "MEX2000",
        "yCap": 17942.78,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "MEX2001",
        "yCap": 17596.79,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "MEX2002",
        "yCap": 17324.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "MEX2003",
        "yCap": 17315.46,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "MEX2004",
        "yCap": 17731.8,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "MEX2005",
        "yCap": 17883.54,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "MEX2006",
        "yCap": 18434.89,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "MEX2007",
        "yCap": 18610.73,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "MEX2008",
        "yCap": 18586.47,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "MEX2009",
        "yCap": 17387.66,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "MEX2010",
        "yCap": 18036.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "MEX2011",
        "yCap": 18432.37,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "MEX2012",
        "yCap": 18838.78,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "MEX2013",
        "yCap": 18844.03,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "MEX2014",
        "yCap": 19141.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "MEX2015",
        "yCap": 19542.89,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "MEX2016",
        "yCap": 19830.96,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "MEX2017",
        "yCap": 20032.41,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "MEX2018",
        "yCap": 20278.22,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "MEX2019",
        "yCap": 20064.5,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "MEX2020",
        "yCap": 18327.99,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "MEX2021",
        "yCap": 19086.1,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mexico",
        "Alpha-3": "MEX",
        "Alpha-2": "MX",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "MEX2022",
        "yCap": 19086.1,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "FSM1990",
        "yCap": 2925.31,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "FSM1991",
        "yCap": 3085.5,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "FSM1992",
        "yCap": 3132.26,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "FSM1993",
        "yCap": 3287.03,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "FSM1994",
        "yCap": 3184.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "FSM1995",
        "yCap": 3362.49,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "FSM1996",
        "yCap": 3242.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "FSM1997",
        "yCap": 3036.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "FSM1998",
        "yCap": 3114.91,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "FSM1999",
        "yCap": 3153.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "FSM2000",
        "yCap": 3301.39,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "FSM2001",
        "yCap": 3365.75,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "FSM2002",
        "yCap": 3382.83,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "FSM2003",
        "yCap": 3441.76,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "FSM2004",
        "yCap": 3346.73,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "FSM2005",
        "yCap": 3430.4,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "FSM2006",
        "yCap": 3447.47,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "FSM2007",
        "yCap": 3407.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "FSM2008",
        "yCap": 3351.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "FSM2009",
        "yCap": 3414.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "FSM2010",
        "yCap": 3500.3,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "FSM2011",
        "yCap": 3601.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "FSM2012",
        "yCap": 3523.39,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "FSM2013",
        "yCap": 3381.95,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "FSM2014",
        "yCap": 3291.4,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "FSM2015",
        "yCap": 3429.74,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "FSM2016",
        "yCap": 3446.04,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "FSM2017",
        "yCap": 3522.29,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "FSM2018",
        "yCap": 3513.87,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "FSM2019",
        "yCap": 3540.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "FSM2020",
        "yCap": 3455.72,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "FSM2021",
        "yCap": 3315.23,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Micronesia, Fed. Sts.",
        "Alpha-3": "FSM",
        "Alpha-2": "FM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "FSM2022",
        "yCap": 3315.23,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "MDA1990",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "MDA1991",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "MDA1992",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "MDA1993",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "MDA1994",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "MDA1995",
        "yCap": 5694.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "MDA1996",
        "yCap": 5370.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "MDA1997",
        "yCap": 5479.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "MDA1998",
        "yCap": 5122.8,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "MDA1999",
        "yCap": 4958.03,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "MDA2000",
        "yCap": 5072.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "MDA2001",
        "yCap": 5394.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "MDA2002",
        "yCap": 5828.57,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "MDA2003",
        "yCap": 6230.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "MDA2004",
        "yCap": 6708.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "MDA2005",
        "yCap": 7229.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "MDA2006",
        "yCap": 7597.22,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "MDA2007",
        "yCap": 7843.29,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "MDA2008",
        "yCap": 8471.18,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "MDA2009",
        "yCap": 7972.97,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "MDA2010",
        "yCap": 8547.58,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "MDA2011",
        "yCap": 9050.13,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "MDA2012",
        "yCap": 8997.93,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "MDA2013",
        "yCap": 9814.32,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "MDA2014",
        "yCap": 10311.29,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "MDA2015",
        "yCap": 10355.54,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "MDA2016",
        "yCap": 10938.58,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "MDA2017",
        "yCap": 11651.19,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "MDA2018",
        "yCap": 12367.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "MDA2019",
        "yCap": 13030.18,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "MDA2020",
        "yCap": 12201.78,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "MDA2021",
        "yCap": 14009.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Moldova",
        "Alpha-3": "MDA",
        "Alpha-2": "MD",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "MDA2022",
        "yCap": 14009.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "MCO1990",
        "yCap": 21546.26,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "MCO1991",
        "yCap": 21372.8,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "MCO1992",
        "yCap": 21081.84,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "MCO1993",
        "yCap": 20893.97,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "MCO1994",
        "yCap": 21098.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "MCO1995",
        "yCap": 21483.99,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "MCO1996",
        "yCap": 21981.24,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "MCO1997",
        "yCap": 22717.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "MCO1998",
        "yCap": 23548.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "MCO1999",
        "yCap": 24300.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "MCO2000",
        "yCap": 25310.46,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "MCO2001",
        "yCap": 25961.38,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "MCO2002",
        "yCap": 26468.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "MCO2003",
        "yCap": 27049.29,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "MCO2004",
        "yCap": 28109.83,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "MCO2005",
        "yCap": 29015.44,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "MCO2006",
        "yCap": 30295.56,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "MCO2007",
        "yCap": 31705.85,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "MCO2008",
        "yCap": 31930.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "MCO2009",
        "yCap": 30195.77,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "MCO2010",
        "yCap": 30589.07,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "MCO2011",
        "yCap": 30918.19,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "MCO2012",
        "yCap": 30801.86,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "MCO2013",
        "yCap": 31011.79,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "MCO2014",
        "yCap": 31496.58,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "MCO2015",
        "yCap": 32309.12,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "MCO2016",
        "yCap": 32968.77,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "MCO2017",
        "yCap": 33882.6,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "MCO2018",
        "yCap": 34759.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "MCO2019",
        "yCap": 35523.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "MCO2020",
        "yCap": 34038.75,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "MCO2021",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Monaco",
        "Alpha-3": "MCO",
        "Alpha-2": "MC",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "MCO2022",
        "yCap": 36006.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "MNG1990",
        "yCap": 5030.07,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "MNG1991",
        "yCap": 4512.11,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "MNG1992",
        "yCap": 4027.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "MNG1993",
        "yCap": 3842.05,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "MNG1994",
        "yCap": 3870.86,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "MNG1995",
        "yCap": 4066.75,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "MNG1996",
        "yCap": 4110.55,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "MNG1997",
        "yCap": 4226.37,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "MNG1998",
        "yCap": 4325.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "MNG1999",
        "yCap": 4415.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "MNG2000",
        "yCap": 4424.65,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "MNG2001",
        "yCap": 4515.47,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "MNG2002",
        "yCap": 4687.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "MNG2003",
        "yCap": 4972.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "MNG2004",
        "yCap": 5453.99,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "MNG2005",
        "yCap": 5800.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "MNG2006",
        "yCap": 6243.61,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "MNG2007",
        "yCap": 6818.99,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "MNG2008",
        "yCap": 7346.28,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "MNG2009",
        "yCap": 7163.8,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "MNG2010",
        "yCap": 7518.83,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "MNG2011",
        "yCap": 8685.78,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "MNG2012",
        "yCap": 9586.71,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "MNG2013",
        "yCap": 10504.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "MNG2014",
        "yCap": 11107.99,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "MNG2015",
        "yCap": 11134.8,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "MNG2016",
        "yCap": 11058.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "MNG2017",
        "yCap": 11431.5,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "MNG2018",
        "yCap": 12052.29,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "MNG2019",
        "yCap": 12458.02,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "MNG2020",
        "yCap": 11666.78,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "MNG2021",
        "yCap": 11668.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Mongolia",
        "Alpha-3": "MNG",
        "Alpha-2": "MN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "MNG2022",
        "yCap": 11668.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "MNE1990",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "MNE1991",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "MNE1992",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "MNE1993",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "MNE1994",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "MNE1995",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "MNE1996",
        "yCap": 12579.29,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "MNE1997",
        "yCap": 12579.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "MNE1998",
        "yCap": 13236.02,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "MNE1999",
        "yCap": 12024.7,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "MNE2000",
        "yCap": 12419.01,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "MNE2001",
        "yCap": 12505.18,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "MNE2002",
        "yCap": 12692.3,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "MNE2003",
        "yCap": 12955.59,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "MNE2004",
        "yCap": 13505.06,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "MNE2005",
        "yCap": 14048.85,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "MNE2006",
        "yCap": 15233.39,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "MNE2007",
        "yCap": 16248.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "MNE2008",
        "yCap": 17391.04,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "MNE2009",
        "yCap": 16348.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "MNE2010",
        "yCap": 16764.36,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "MNE2011",
        "yCap": 17287.43,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "MNE2012",
        "yCap": 16802.41,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "MNE2013",
        "yCap": 17381.75,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "MNE2014",
        "yCap": 17674.63,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "MNE2015",
        "yCap": 18263.62,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "MNE2016",
        "yCap": 18797.91,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "MNE2017",
        "yCap": 19682.29,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "MNE2018",
        "yCap": 20686.59,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "MNE2019",
        "yCap": 21533.96,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "MNE2020",
        "yCap": 18258.98,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "MNE2021",
        "yCap": 20598.82,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Montenegro",
        "Alpha-3": "MNE",
        "Alpha-2": "ME",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "MNE2022",
        "yCap": 20598.82,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "Mar-90",
        "yCap": 3595.33,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "Mar-91",
        "yCap": 3790,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "Mar-92",
        "yCap": 3649.87,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "Mar-93",
        "yCap": 3565.51,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "Mar-94",
        "yCap": 3883.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "Mar-95",
        "yCap": 3617.81,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "Mar-96",
        "yCap": 4003.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "Mar-97",
        "yCap": 3882.96,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "Mar-98",
        "yCap": 4103.55,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "Mar-99",
        "yCap": 4089.88,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "Mar-00",
        "yCap": 4111.65,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "Mar-01",
        "yCap": 4353.85,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "Mar-02",
        "yCap": 4431.3,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "Mar-03",
        "yCap": 4636.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "Mar-04",
        "yCap": 4797.68,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "Mar-05",
        "yCap": 4889.11,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "Mar-06",
        "yCap": 5189.35,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "Mar-07",
        "yCap": 5302.17,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "Mar-08",
        "yCap": 5543.09,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "Mar-09",
        "yCap": 5703.03,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "Mar-10",
        "yCap": 5841.88,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "Mar-11",
        "yCap": 6064.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "Mar-12",
        "yCap": 6161,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "Mar-13",
        "yCap": 6352.43,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "Mar-14",
        "yCap": 7462.15,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "Mar-15",
        "yCap": 7687.69,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "Mar-16",
        "yCap": 7632.19,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "Mar-17",
        "yCap": 7921.62,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "Mar-18",
        "yCap": 8072.09,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "Mar-19",
        "yCap": 8217.6,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "Mar-20",
        "yCap": 7546,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "Mar-21",
        "yCap": 8058.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Morocco",
        "Alpha-3": "MAR",
        "Alpha-2": "MA",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "Mar-22",
        "yCap": 8058.4,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "MOZ1990",
        "yCap": 460.12,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "MOZ1991",
        "yCap": 473.61,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "MOZ1992",
        "yCap": 436.38,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "MOZ1993",
        "yCap": 469.4,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "MOZ1994",
        "yCap": 476.37,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "MOZ1995",
        "yCap": 465.83,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "MOZ1996",
        "yCap": 502.4,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "MOZ1997",
        "yCap": 544.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "MOZ1998",
        "yCap": 584.07,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "MOZ1999",
        "yCap": 636.79,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "MOZ2000",
        "yCap": 628.69,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "MOZ2001",
        "yCap": 687.19,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "MOZ2002",
        "yCap": 731.99,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "MOZ2003",
        "yCap": 762.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "MOZ2004",
        "yCap": 801.43,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "MOZ2005",
        "yCap": 832.84,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "MOZ2006",
        "yCap": 890.45,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "MOZ2007",
        "yCap": 934.74,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "MOZ2008",
        "yCap": 977.19,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "MOZ2009",
        "yCap": 1011.56,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "MOZ2010",
        "yCap": 1047.59,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "MOZ2011",
        "yCap": 1092.77,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "MOZ2012",
        "yCap": 1137.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "MOZ2013",
        "yCap": 1179.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "MOZ2014",
        "yCap": 1228.66,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "MOZ2015",
        "yCap": 1271.96,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "MOZ2016",
        "yCap": 1279.92,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "MOZ2017",
        "yCap": 1287.23,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "MOZ2018",
        "yCap": 1292.9,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "MOZ2019",
        "yCap": 1285.18,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "MOZ2020",
        "yCap": 1232.99,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "MOZ2021",
        "yCap": 1226.77,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Mozambique",
        "Alpha-3": "MOZ",
        "Alpha-2": "MZ",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "MOZ2022",
        "yCap": 1226.77,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "MMR1990",
        "yCap": 581.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "MMR1991",
        "yCap": 579.38,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "MMR1992",
        "yCap": 597.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "MMR1993",
        "yCap": 635.11,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "MMR1994",
        "yCap": 669.38,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "MMR1995",
        "yCap": 708.5,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "MMR1996",
        "yCap": 746.43,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "MMR1997",
        "yCap": 781.6,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "MMR1998",
        "yCap": 816.53,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "MMR1999",
        "yCap": 875.42,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "MMR2000",
        "yCap": 973.4,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "MMR2001",
        "yCap": 1083.42,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "MMR2002",
        "yCap": 1198.09,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "MMR2003",
        "yCap": 1340.87,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "MMR2004",
        "yCap": 1511.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "MMR2005",
        "yCap": 1702.31,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "MMR2006",
        "yCap": 1914.24,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "MMR2007",
        "yCap": 2137.64,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "MMR2008",
        "yCap": 2360.54,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "MMR2009",
        "yCap": 2591.05,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "MMR2010",
        "yCap": 2830.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "MMR2011",
        "yCap": 3018.46,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "MMR2012",
        "yCap": 3187.11,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "MMR2013",
        "yCap": 3409.65,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "MMR2014",
        "yCap": 3658.59,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "MMR2015",
        "yCap": 3748.29,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "MMR2016",
        "yCap": 4109.56,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "MMR2017",
        "yCap": 4312.95,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "MMR2018",
        "yCap": 4556.28,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "MMR2019",
        "yCap": 4829.54,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "MMR2020",
        "yCap": 4947.09,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "MMR2021",
        "yCap": 4032.63,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Myanmar",
        "Alpha-3": "MMR",
        "Alpha-2": "MM",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "MMR2022",
        "yCap": 4032.63,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "NAM1990",
        "yCap": 6223.45,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "NAM1991",
        "yCap": 6510.01,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "NAM1992",
        "yCap": 6758.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "NAM1993",
        "yCap": 6439.25,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "NAM1994",
        "yCap": 6346.3,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "NAM1995",
        "yCap": 6401.02,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "NAM1996",
        "yCap": 6426.38,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "NAM1997",
        "yCap": 6526.8,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "NAM1998",
        "yCap": 6575.76,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "NAM1999",
        "yCap": 6635.54,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "NAM2000",
        "yCap": 6713,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "NAM2001",
        "yCap": 6655.75,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "NAM2002",
        "yCap": 6855.84,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "NAM2003",
        "yCap": 7046.15,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "NAM2004",
        "yCap": 7812.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "NAM2005",
        "yCap": 7914.73,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "NAM2006",
        "yCap": 8373.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "NAM2007",
        "yCap": 8714.1,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "NAM2008",
        "yCap": 8826.27,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "NAM2009",
        "yCap": 8726.68,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "NAM2010",
        "yCap": 9115.51,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "NAM2011",
        "yCap": 9431.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "NAM2012",
        "yCap": 9747.82,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "NAM2013",
        "yCap": 10122.15,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "NAM2014",
        "yCap": 10554.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "NAM2015",
        "yCap": 10813.23,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "NAM2016",
        "yCap": 10627.64,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "NAM2017",
        "yCap": 10335.27,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "NAM2018",
        "yCap": 10266.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "NAM2019",
        "yCap": 10009.58,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "NAM2020",
        "yCap": 9048.18,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "NAM2021",
        "yCap": 9137.8,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Namibia",
        "Alpha-3": "NAM",
        "Alpha-2": "NA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "NAM2022",
        "yCap": 9137.8,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "NRU1990",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "NRU1991",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "NRU1992",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "NRU1993",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "NRU1994",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "NRU1995",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "NRU1996",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "NRU1997",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "NRU1998",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "NRU1999",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "NRU2000",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "NRU2001",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "NRU2002",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "NRU2003",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "NRU2004",
        "yCap": 5343.43,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "NRU2005",
        "yCap": 5190.04,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "NRU2006",
        "yCap": 5689.84,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "NRU2007",
        "yCap": 4400.85,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "NRU2008",
        "yCap": 5391.42,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "NRU2009",
        "yCap": 5887.3,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "NRU2010",
        "yCap": 6536.33,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "NRU2011",
        "yCap": 7160.6,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "NRU2012",
        "yCap": 7851.38,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "NRU2013",
        "yCap": 10015.12,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "NRU2014",
        "yCap": 12390.37,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "NRU2015",
        "yCap": 12567.82,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "NRU2016",
        "yCap": 12729.86,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "NRU2017",
        "yCap": 11746.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "NRU2018",
        "yCap": 12209.95,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "NRU2019",
        "yCap": 12000.61,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "NRU2020",
        "yCap": 11958.17,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "NRU2021",
        "yCap": 11947.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nauru",
        "Alpha-3": "NRU",
        "Alpha-2": "NR",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "NRU2022",
        "yCap": 11947.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "NPL1990",
        "yCap": 1554.6,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "NPL1991",
        "yCap": 1611.36,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "NPL1992",
        "yCap": 1631.23,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "NPL1993",
        "yCap": 1649.01,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "NPL1994",
        "yCap": 1741.31,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "NPL1995",
        "yCap": 1760.44,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "NPL1996",
        "yCap": 1815.31,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "NPL1997",
        "yCap": 1868.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "NPL1998",
        "yCap": 1888.29,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "NPL1999",
        "yCap": 1935.69,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "NPL2000",
        "yCap": 2020.86,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "NPL2001",
        "yCap": 2084.2,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "NPL2002",
        "yCap": 2055.72,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "NPL2003",
        "yCap": 2107.64,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "NPL2004",
        "yCap": 2179.09,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "NPL2005",
        "yCap": 2230.79,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "NPL2006",
        "yCap": 2285.51,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "NPL2007",
        "yCap": 2346.26,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "NPL2008",
        "yCap": 2473.94,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "NPL2009",
        "yCap": 2572.18,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "NPL2010",
        "yCap": 2682.7,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "NPL2011",
        "yCap": 2763.83,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "NPL2012",
        "yCap": 2886.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "NPL2013",
        "yCap": 2982.29,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "NPL2014",
        "yCap": 3152.29,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "NPL2015",
        "yCap": 3260.03,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "NPL2016",
        "yCap": 3244.67,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "NPL2017",
        "yCap": 3495.53,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "NPL2018",
        "yCap": 3719.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "NPL2019",
        "yCap": 3922.08,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "NPL2020",
        "yCap": 3761.8,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "NPL2021",
        "yCap": 3831.95,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nepal",
        "Alpha-3": "NPL",
        "Alpha-2": "NP",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "NPL2022",
        "yCap": 3831.95,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "NLD1990",
        "yCap": 36461.42,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "NLD1991",
        "yCap": 37057.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "NLD1992",
        "yCap": 37405.93,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "NLD1993",
        "yCap": 37613.25,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "NLD1994",
        "yCap": 38494.22,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "NLD1995",
        "yCap": 39498.14,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "NLD1996",
        "yCap": 40691.89,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "NLD1997",
        "yCap": 42235.49,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "NLD1998",
        "yCap": 43933.57,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "NLD1999",
        "yCap": 45839.13,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "NLD2000",
        "yCap": 47422.2,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "NLD2001",
        "yCap": 48160.78,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "NLD2002",
        "yCap": 47958.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "NLD2003",
        "yCap": 47806.88,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "NLD2004",
        "yCap": 48586.7,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "NLD2005",
        "yCap": 49467.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "NLD2006",
        "yCap": 51097.36,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "NLD2007",
        "yCap": 52909.97,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "NLD2008",
        "yCap": 53848.25,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "NLD2009",
        "yCap": 51607.61,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "NLD2010",
        "yCap": 52032.99,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "NLD2011",
        "yCap": 52594.23,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "NLD2012",
        "yCap": 51860.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "NLD2013",
        "yCap": 51640.08,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "NLD2014",
        "yCap": 52187,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "NLD2015",
        "yCap": 52974.12,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "NLD2016",
        "yCap": 53847.83,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "NLD2017",
        "yCap": 55088.63,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "NLD2018",
        "yCap": 56060.91,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "NLD2019",
        "yCap": 56784.04,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "NLD2020",
        "yCap": 54275,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "NLD2021",
        "yCap": 56617.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Netherlands",
        "Alpha-3": "NLD",
        "Alpha-2": "NL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "NLD2022",
        "yCap": 56617.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "NCL1990",
        "yCap": 12367.59,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "NCL1991",
        "yCap": 12505.46,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "NCL1992",
        "yCap": 12903.06,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "NCL1993",
        "yCap": 13250.15,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "NCL1994",
        "yCap": 13652.2,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "NCL1995",
        "yCap": 14030.41,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "NCL1996",
        "yCap": 14250.36,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "NCL1997",
        "yCap": 14345.62,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "NCL1998",
        "yCap": 13907.32,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "NCL1999",
        "yCap": 14177.28,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "NCL2000",
        "yCap": 14657.18,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "NCL2001",
        "yCap": 14767.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "NCL2002",
        "yCap": 15202.57,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "NCL2003",
        "yCap": 15690.84,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "NCL2004",
        "yCap": 16635.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "NCL2005",
        "yCap": 17160.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "NCL2006",
        "yCap": 17930.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "NCL2007",
        "yCap": 18645.08,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "NCL2008",
        "yCap": 18659.79,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "NCL2009",
        "yCap": 18352.43,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "NCL2010",
        "yCap": 19761.77,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "NCL2011",
        "yCap": 20971.66,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "NCL2012",
        "yCap": 21618,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "NCL2013",
        "yCap": 22402.01,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "NCL2014",
        "yCap": 22596.64,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "NCL2015",
        "yCap": 21826.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "NCL2016",
        "yCap": 22024.82,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "NCL2017",
        "yCap": 22747.82,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "NCL2018",
        "yCap": 23336.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "NCL2019",
        "yCap": 23421.25,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "NCL2020",
        "yCap": 20593.57,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "NCL2021",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Caledonia",
        "Alpha-3": "NCL",
        "Alpha-2": "NC",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "NCL2022",
        "yCap": 21482.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "NZL1990",
        "yCap": 28032,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "NZL1991",
        "yCap": 26415,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "NZL1992",
        "yCap": 26427.35,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "NZL1993",
        "yCap": 27797.71,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "NZL1994",
        "yCap": 28834.73,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "NZL1995",
        "yCap": 29757.47,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "NZL1996",
        "yCap": 30349.21,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "NZL1997",
        "yCap": 30567.52,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "NZL1998",
        "yCap": 30539.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "NZL1999",
        "yCap": 32035.05,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "NZL2000",
        "yCap": 32772.58,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "NZL2001",
        "yCap": 33708.7,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "NZL2002",
        "yCap": 34676.8,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "NZL2003",
        "yCap": 35546.1,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "NZL2004",
        "yCap": 36432.49,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "NZL2005",
        "yCap": 37219.8,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "NZL2006",
        "yCap": 37828.19,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "NZL2007",
        "yCap": 38611.98,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "NZL2008",
        "yCap": 37860.11,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "NZL2009",
        "yCap": 37432.83,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "NZL2010",
        "yCap": 37583.11,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "NZL2011",
        "yCap": 38137.49,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "NZL2012",
        "yCap": 38780.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "NZL2013",
        "yCap": 39521.08,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "NZL2014",
        "yCap": 40353.11,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "NZL2015",
        "yCap": 41020.31,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "NZL2016",
        "yCap": 41620.35,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "NZL2017",
        "yCap": 42218.54,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "NZL2018",
        "yCap": 42862.07,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "NZL2019",
        "yCap": 43110.37,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "NZL2020",
        "yCap": 41642.03,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "NZL2021",
        "yCap": 42915.45,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "New Zealand",
        "Alpha-3": "NZL",
        "Alpha-2": "NZ",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "NZL2022",
        "yCap": 42915.45,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "NIC1990",
        "yCap": 3409.78,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "NIC1991",
        "yCap": 3325.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "NIC1992",
        "yCap": 3262.63,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "NIC1993",
        "yCap": 3178.36,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "NIC1994",
        "yCap": 3214.45,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "NIC1995",
        "yCap": 3334.79,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "NIC1996",
        "yCap": 3479.69,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "NIC1997",
        "yCap": 3555.97,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "NIC1998",
        "yCap": 3629.69,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "NIC1999",
        "yCap": 3827.13,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "NIC2000",
        "yCap": 3927.06,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "NIC2001",
        "yCap": 3989.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "NIC2002",
        "yCap": 3968.64,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "NIC2003",
        "yCap": 4019.71,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "NIC2004",
        "yCap": 4183.61,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "NIC2005",
        "yCap": 4308.02,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "NIC2006",
        "yCap": 4425.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "NIC2007",
        "yCap": 4586.27,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "NIC2008",
        "yCap": 4676.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "NIC2009",
        "yCap": 4457.93,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "NIC2010",
        "yCap": 4586.87,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "NIC2011",
        "yCap": 4805.4,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "NIC2012",
        "yCap": 5042.8,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "NIC2013",
        "yCap": 5214.53,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "NIC2014",
        "yCap": 5385.5,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "NIC2015",
        "yCap": 5563,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "NIC2016",
        "yCap": 5734.36,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "NIC2017",
        "yCap": 5915.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "NIC2018",
        "yCap": 5636.64,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "NIC2019",
        "yCap": 5348.99,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "NIC2020",
        "yCap": 5181.73,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "NIC2021",
        "yCap": 5638.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Nicaragua",
        "Alpha-3": "NIC",
        "Alpha-2": "NI",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "NIC2022",
        "yCap": 5638.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "NER1990",
        "yCap": 1077.91,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "NER1991",
        "yCap": 1040.36,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "NER1992",
        "yCap": 1028.66,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "NER1993",
        "yCap": 999.92,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "NER1994",
        "yCap": 986.26,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "NER1995",
        "yCap": 977.48,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "NER1996",
        "yCap": 946.07,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "NER1997",
        "yCap": 929.01,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "NER1998",
        "yCap": 987.71,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "NER1999",
        "yCap": 952.5,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "NER2000",
        "yCap": 909.32,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "NER2001",
        "yCap": 942.27,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "NER2002",
        "yCap": 954.88,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "NER2003",
        "yCap": 942.01,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "NER2004",
        "yCap": 912.47,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "NER2005",
        "yCap": 944.85,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "NER2006",
        "yCap": 965.36,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "NER2007",
        "yCap": 960.09,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "NER2008",
        "yCap": 997.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "NER2009",
        "yCap": 979.65,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "NER2010",
        "yCap": 1024.74,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "NER2011",
        "yCap": 1010.33,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "NER2012",
        "yCap": 1075.15,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "NER2013",
        "yCap": 1089.87,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "NER2014",
        "yCap": 1119.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "NER2015",
        "yCap": 1124.41,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "NER2016",
        "yCap": 1143.86,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "NER2017",
        "yCap": 1155.97,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "NER2018",
        "yCap": 1193.27,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "NER2019",
        "yCap": 1217.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "NER2020",
        "yCap": 1214.55,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "NER2021",
        "yCap": 1186.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Niger",
        "Alpha-3": "NER",
        "Alpha-2": "NE",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "NER2022",
        "yCap": 1186.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "NGA1990",
        "yCap": 3259.58,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "NGA1991",
        "yCap": 3188.51,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "NGA1992",
        "yCap": 3253.03,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "NGA1993",
        "yCap": 3106.41,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "NGA1994",
        "yCap": 2972.5,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "NGA1995",
        "yCap": 2895.35,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "NGA1996",
        "yCap": 2941.56,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "NGA1997",
        "yCap": 2952.52,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "NGA1998",
        "yCap": 2953.48,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "NGA1999",
        "yCap": 2896.15,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "NGA2000",
        "yCap": 2963.27,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "NGA2001",
        "yCap": 3056.51,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "NGA2002",
        "yCap": 3431.73,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "NGA2003",
        "yCap": 3585.99,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "NGA2004",
        "yCap": 3813.53,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "NGA2005",
        "yCap": 3951.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "NGA2006",
        "yCap": 4079.14,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "NGA2007",
        "yCap": 4231.76,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "NGA2008",
        "yCap": 4396.8,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "NGA2009",
        "yCap": 4622.36,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "NGA2010",
        "yCap": 4857.27,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "NGA2011",
        "yCap": 4975.64,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "NGA2012",
        "yCap": 5045.47,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "NGA2013",
        "yCap": 5238.83,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "NGA2014",
        "yCap": 5424.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "NGA2015",
        "yCap": 5429.1,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "NGA2016",
        "yCap": 5209.07,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "NGA2017",
        "yCap": 5120.01,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "NGA2018",
        "yCap": 5089.78,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "NGA2019",
        "yCap": 5076.37,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "NGA2020",
        "yCap": 4865.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "NGA2021",
        "yCap": 4922.63,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Nigeria",
        "Alpha-3": "NGA",
        "Alpha-2": "NG",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "NGA2022",
        "yCap": 4922.63,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "MKD1990",
        "yCap": 11111.25,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "MKD1991",
        "yCap": 10463.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "MKD1992",
        "yCap": 9867.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "MKD1993",
        "yCap": 9226.96,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "MKD1994",
        "yCap": 9130.74,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "MKD1995",
        "yCap": 9022.1,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "MKD1996",
        "yCap": 9082.05,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "MKD1997",
        "yCap": 9200.63,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "MKD1998",
        "yCap": 9461.02,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "MKD1999",
        "yCap": 9824.46,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "MKD2000",
        "yCap": 10224.72,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "MKD2001",
        "yCap": 9869.54,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "MKD2002",
        "yCap": 10089.97,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "MKD2003",
        "yCap": 10280.56,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "MKD2004",
        "yCap": 10730.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "MKD2005",
        "yCap": 11213.67,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "MKD2006",
        "yCap": 11770.23,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "MKD2007",
        "yCap": 12511.74,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "MKD2008",
        "yCap": 13174.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "MKD2009",
        "yCap": 13103.46,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "MKD2010",
        "yCap": 13515.01,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "MKD2011",
        "yCap": 13807.5,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "MKD2012",
        "yCap": 13727.81,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "MKD2013",
        "yCap": 14108.93,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "MKD2014",
        "yCap": 14596.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "MKD2015",
        "yCap": 15139.29,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "MKD2016",
        "yCap": 15553.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "MKD2017",
        "yCap": 15706.48,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "MKD2018",
        "yCap": 16145.58,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "MKD2019",
        "yCap": 16773.08,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "MKD2020",
        "yCap": 15779.73,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "MKD2021",
        "yCap": 16464.38,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "North Macedonia",
        "Alpha-3": "MKD",
        "Alpha-2": "MK",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "MKD2022",
        "yCap": 16464.38,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "MNP1990",
        "yCap": 12367.59,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "MNP1991",
        "yCap": 12505.46,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "MNP1992",
        "yCap": 12903.06,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "MNP1993",
        "yCap": 13250.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "MNP1994",
        "yCap": 13652.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "MNP1995",
        "yCap": 14030.41,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "MNP1996",
        "yCap": 14250.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "MNP1997",
        "yCap": 14345.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "MNP1998",
        "yCap": 13907.32,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "MNP1999",
        "yCap": 14177.28,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "MNP2000",
        "yCap": 14657.18,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "MNP2001",
        "yCap": 14767.58,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "MNP2002",
        "yCap": 15202.57,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "MNP2003",
        "yCap": 15690.84,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "MNP2004",
        "yCap": 16635.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "MNP2005",
        "yCap": 17160.26,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "MNP2006",
        "yCap": 17930.46,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "MNP2007",
        "yCap": 18645.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "MNP2008",
        "yCap": 18659.79,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "MNP2009",
        "yCap": 18352.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "MNP2010",
        "yCap": 19761.77,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "MNP2011",
        "yCap": 20971.66,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "MNP2012",
        "yCap": 21618,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "MNP2013",
        "yCap": 22402.01,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "MNP2014",
        "yCap": 22596.64,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "MNP2015",
        "yCap": 21826.58,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "MNP2016",
        "yCap": 22024.82,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "MNP2017",
        "yCap": 22747.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "MNP2018",
        "yCap": 23336.48,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "MNP2019",
        "yCap": 23421.25,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "MNP2020",
        "yCap": 20593.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "MNP2021",
        "yCap": 21482.05,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Northern Mariana Islands",
        "Alpha-3": "MNP",
        "Alpha-2": "MP",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "MNP2022",
        "yCap": 21482.05,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "NOR1990",
        "yCap": 42136.6,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "NOR1991",
        "yCap": 43229.92,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "NOR1992",
        "yCap": 44517.44,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "NOR1993",
        "yCap": 45512.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "NOR1994",
        "yCap": 47541.77,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "NOR1995",
        "yCap": 49260.97,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "NOR1996",
        "yCap": 51476.2,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "NOR1997",
        "yCap": 53903.44,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "NOR1998",
        "yCap": 54989.7,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "NOR1999",
        "yCap": 55713.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "NOR2000",
        "yCap": 57127.38,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "NOR2001",
        "yCap": 58018.31,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "NOR2002",
        "yCap": 58540.85,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "NOR2003",
        "yCap": 58728.16,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "NOR2004",
        "yCap": 60699.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "NOR2005",
        "yCap": 61870.36,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "NOR2006",
        "yCap": 62846.94,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "NOR2007",
        "yCap": 64062.44,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "NOR2008",
        "yCap": 63570.34,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "NOR2009",
        "yCap": 61689.56,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "NOR2010",
        "yCap": 61353.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "NOR2011",
        "yCap": 61157.13,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "NOR2012",
        "yCap": 61990.69,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "NOR2013",
        "yCap": 61878.98,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "NOR2014",
        "yCap": 62390.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "NOR2015",
        "yCap": 62987.52,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "NOR2016",
        "yCap": 63104.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "NOR2017",
        "yCap": 64050.76,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "NOR2018",
        "yCap": 64341.26,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "NOR2019",
        "yCap": 64385.01,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "NOR2020",
        "yCap": 63548,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "NOR2021",
        "yCap": 65662.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Norway",
        "Alpha-3": "NOR",
        "Alpha-2": "NO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "NOR2022",
        "yCap": 65662.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "OMN1990",
        "yCap": 30462.2,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "OMN1991",
        "yCap": 30766.72,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "OMN1992",
        "yCap": 31842.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "OMN1993",
        "yCap": 32354.74,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "OMN1994",
        "yCap": 32635.18,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "OMN1995",
        "yCap": 33650.86,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "OMN1996",
        "yCap": 34090.8,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "OMN1997",
        "yCap": 35574.13,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "OMN1998",
        "yCap": 35967.32,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "OMN1999",
        "yCap": 35573.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "OMN2000",
        "yCap": 37392.25,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "OMN2001",
        "yCap": 38568.32,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "OMN2002",
        "yCap": 37683.42,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "OMN2003",
        "yCap": 36256.35,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "OMN2004",
        "yCap": 36170.68,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "OMN2005",
        "yCap": 36388.44,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "OMN2006",
        "yCap": 37662.52,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "OMN2007",
        "yCap": 38659.35,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "OMN2008",
        "yCap": 41114.24,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "OMN2009",
        "yCap": 42875.11,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "OMN2010",
        "yCap": 40819.94,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "OMN2011",
        "yCap": 37745.45,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "OMN2012",
        "yCap": 37270.59,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "OMN2013",
        "yCap": 36330.48,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "OMN2014",
        "yCap": 35032.26,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "OMN2015",
        "yCap": 35188.02,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "OMN2016",
        "yCap": 35229.95,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "OMN2017",
        "yCap": 34218.39,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "OMN2018",
        "yCap": 34212.11,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "OMN2019",
        "yCap": 33814.11,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "OMN2020",
        "yCap": 33098.21,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "OMN2021",
        "yCap": 34294.76,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Oman",
        "Alpha-3": "OMN",
        "Alpha-2": "OM",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "OMN2022",
        "yCap": 34294.76,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "PAK1990",
        "yCap": 3061.53,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "PAK1991",
        "yCap": 3114.24,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "PAK1992",
        "yCap": 3267.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "PAK1993",
        "yCap": 3240.74,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "PAK1994",
        "yCap": 3265.65,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "PAK1995",
        "yCap": 3328,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "PAK1996",
        "yCap": 3384.61,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "PAK1997",
        "yCap": 3319.87,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "PAK1998",
        "yCap": 3307.51,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "PAK1999",
        "yCap": 3331.95,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "PAK2000",
        "yCap": 3368.68,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "PAK2001",
        "yCap": 3382.2,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "PAK2002",
        "yCap": 3381.14,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "PAK2003",
        "yCap": 3499.02,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "PAK2004",
        "yCap": 3679.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "PAK2005",
        "yCap": 3836.09,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "PAK2006",
        "yCap": 3978.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "PAK2007",
        "yCap": 4081.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "PAK2008",
        "yCap": 4061.89,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "PAK2009",
        "yCap": 4084.83,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "PAK2010",
        "yCap": 4058.01,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "PAK2011",
        "yCap": 4082.45,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "PAK2012",
        "yCap": 4150.33,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "PAK2013",
        "yCap": 4266.71,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "PAK2014",
        "yCap": 4403.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "PAK2015",
        "yCap": 4552.61,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "PAK2016",
        "yCap": 4746.72,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "PAK2017",
        "yCap": 4891.72,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "PAK2018",
        "yCap": 5113.43,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "PAK2019",
        "yCap": 5157.55,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "PAK2020",
        "yCap": 5004.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "PAK2021",
        "yCap": 5232.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Pakistan",
        "Alpha-3": "PAK",
        "Alpha-2": "PK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "PAK2022",
        "yCap": 5232.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "PLW1990",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "PLW1991",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "PLW1992",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "PLW1993",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "PLW1994",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "PLW1995",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "PLW1996",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "PLW1997",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "PLW1998",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "PLW1999",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "PLW2000",
        "yCap": 13301.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "PLW2001",
        "yCap": 14084.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "PLW2002",
        "yCap": 14572.04,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "PLW2003",
        "yCap": 14075.94,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "PLW2004",
        "yCap": 14739.45,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "PLW2005",
        "yCap": 15381.83,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "PLW2006",
        "yCap": 15484.98,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "PLW2007",
        "yCap": 16004.34,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "PLW2008",
        "yCap": 15287.38,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "PLW2009",
        "yCap": 14489.22,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "PLW2010",
        "yCap": 14744.53,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "PLW2011",
        "yCap": 15988.72,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "PLW2012",
        "yCap": 16522.46,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "PLW2013",
        "yCap": 16089.21,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "PLW2014",
        "yCap": 17110.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "PLW2015",
        "yCap": 18402.53,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "PLW2016",
        "yCap": 18364.48,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "PLW2017",
        "yCap": 17594.69,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "PLW2018",
        "yCap": 17587.09,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "PLW2019",
        "yCap": 17605.48,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "PLW2020",
        "yCap": 15952.25,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "PLW2021",
        "yCap": 13785.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Palau",
        "Alpha-3": "PLW",
        "Alpha-2": "PW",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "PLW2022",
        "yCap": 13785.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "PAN1990",
        "yCap": 10817.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "PAN1991",
        "yCap": 11590.41,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "PAN1992",
        "yCap": 12284.06,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "PAN1993",
        "yCap": 12690.36,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "PAN1994",
        "yCap": 12787.79,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "PAN1995",
        "yCap": 12749.82,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "PAN1996",
        "yCap": 13004.07,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "PAN1997",
        "yCap": 13568.23,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "PAN1998",
        "yCap": 14275.67,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "PAN1999",
        "yCap": 14542.81,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "PAN2000",
        "yCap": 14646.03,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "PAN2001",
        "yCap": 14444.81,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "PAN2002",
        "yCap": 14483.08,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "PAN2003",
        "yCap": 14804.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "PAN2004",
        "yCap": 15615.4,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "PAN2005",
        "yCap": 16421.61,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "PAN2006",
        "yCap": 17510.35,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "PAN2007",
        "yCap": 19248.56,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "PAN2008",
        "yCap": 20760.49,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "PAN2009",
        "yCap": 20640.22,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "PAN2010",
        "yCap": 21455.68,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "PAN2011",
        "yCap": 23461.87,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "PAN2012",
        "yCap": 25302.08,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "PAN2013",
        "yCap": 26576.86,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "PAN2014",
        "yCap": 27440.57,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "PAN2015",
        "yCap": 28512.92,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "PAN2016",
        "yCap": 29410.64,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "PAN2017",
        "yCap": 30526.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "PAN2018",
        "yCap": 31125.62,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "PAN2019",
        "yCap": 31543.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "PAN2020",
        "yCap": 25510.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "PAN2021",
        "yCap": 29037.95,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Panama",
        "Alpha-3": "PAN",
        "Alpha-2": "PA",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "PAN2022",
        "yCap": 29037.95,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "PNG1990",
        "yCap": 2907.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "PNG1991",
        "yCap": 3084.34,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "PNG1992",
        "yCap": 3387.86,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "PNG1993",
        "yCap": 3859.95,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "PNG1994",
        "yCap": 3942.24,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "PNG1995",
        "yCap": 3675.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "PNG1996",
        "yCap": 3819.68,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "PNG1997",
        "yCap": 3541.39,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "PNG1998",
        "yCap": 3289.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "PNG1999",
        "yCap": 3235.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "PNG2000",
        "yCap": 3047.92,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "PNG2001",
        "yCap": 2942.62,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "PNG2002",
        "yCap": 2841.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "PNG2003",
        "yCap": 2808.11,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "PNG2004",
        "yCap": 2791.85,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "PNG2005",
        "yCap": 2875.03,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "PNG2006",
        "yCap": 2935.97,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "PNG2007",
        "yCap": 3068.07,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "PNG2008",
        "yCap": 2966.02,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "PNG2009",
        "yCap": 3072.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "PNG2010",
        "yCap": 3283.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "PNG2011",
        "yCap": 3225.09,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "PNG2012",
        "yCap": 3282.81,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "PNG2013",
        "yCap": 3317.82,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "PNG2014",
        "yCap": 3669.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "PNG2015",
        "yCap": 3813.11,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "PNG2016",
        "yCap": 3924.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "PNG2017",
        "yCap": 3966.95,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "PNG2018",
        "yCap": 3864.94,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "PNG2019",
        "yCap": 3947.87,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "PNG2020",
        "yCap": 3741.6,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "PNG2021",
        "yCap": 3677.46,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Papua New Guinea",
        "Alpha-3": "PNG",
        "Alpha-2": "PG",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "PNG2022",
        "yCap": 3677.46,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "PRY1990",
        "yCap": 8818.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "PRY1991",
        "yCap": 8888.48,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "PRY1992",
        "yCap": 8808.78,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "PRY1993",
        "yCap": 9013.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "PRY1994",
        "yCap": 9262.54,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "PRY1995",
        "yCap": 9661.07,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "PRY1996",
        "yCap": 9588.72,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "PRY1997",
        "yCap": 9774.32,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "PRY1998",
        "yCap": 9572.05,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "PRY1999",
        "yCap": 9248.59,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "PRY2000",
        "yCap": 8862.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "PRY2001",
        "yCap": 8640.3,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "PRY2002",
        "yCap": 8515.95,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "PRY2003",
        "yCap": 8773.14,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "PRY2004",
        "yCap": 9022.8,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "PRY2005",
        "yCap": 9113.41,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "PRY2006",
        "yCap": 9451.76,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "PRY2007",
        "yCap": 9865.33,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "PRY2008",
        "yCap": 10403.84,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "PRY2009",
        "yCap": 10272.18,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "PRY2010",
        "yCap": 11281.26,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "PRY2011",
        "yCap": 11613.17,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "PRY2012",
        "yCap": 11376.41,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "PRY2013",
        "yCap": 12150.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "PRY2014",
        "yCap": 12616.41,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "PRY2015",
        "yCap": 12806.1,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "PRY2016",
        "yCap": 13163.74,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "PRY2017",
        "yCap": 13604.17,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "PRY2018",
        "yCap": 13848.5,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "PRY2019",
        "yCap": 13609.72,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "PRY2020",
        "yCap": 13317.32,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "PRY2021",
        "yCap": 13687.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Paraguay",
        "Alpha-3": "PRY",
        "Alpha-2": "PY",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "PRY2022",
        "yCap": 13687.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "PER1990",
        "yCap": 5240.26,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "PER1991",
        "yCap": 5244.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "PER1992",
        "yCap": 5110.74,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "PER1993",
        "yCap": 5271.98,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "PER1994",
        "yCap": 5805,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "PER1995",
        "yCap": 6116.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "PER1996",
        "yCap": 6171.96,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "PER1997",
        "yCap": 6453.02,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "PER1998",
        "yCap": 6315.02,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "PER1999",
        "yCap": 6303.46,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "PER2000",
        "yCap": 6375.62,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "PER2001",
        "yCap": 6329.41,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "PER2002",
        "yCap": 6596.55,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "PER2003",
        "yCap": 6799.45,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "PER2004",
        "yCap": 7067.36,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "PER2005",
        "yCap": 7443.93,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "PER2006",
        "yCap": 7938.43,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "PER2007",
        "yCap": 8548.6,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "PER2008",
        "yCap": 9262.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "PER2009",
        "yCap": 9298.08,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "PER2010",
        "yCap": 9996.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "PER2011",
        "yCap": 10539.98,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "PER2012",
        "yCap": 11084.87,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "PER2013",
        "yCap": 11620.64,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "PER2014",
        "yCap": 11773.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "PER2015",
        "yCap": 12015.19,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "PER2016",
        "yCap": 12321.32,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "PER2017",
        "yCap": 12442.75,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "PER2018",
        "yCap": 12696.24,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "PER2019",
        "yCap": 12735.17,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "PER2020",
        "yCap": 11176.92,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "PER2021",
        "yCap": 12514.65,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Peru",
        "Alpha-3": "PER",
        "Alpha-2": "PE",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "PER2022",
        "yCap": 12514.65,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "PHL1990",
        "yCap": 4232.48,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "PHL1991",
        "yCap": 4115.02,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "PHL1992",
        "yCap": 4035.93,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "PHL1993",
        "yCap": 4027.9,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "PHL1994",
        "yCap": 4106.68,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "PHL1995",
        "yCap": 4197.34,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "PHL1996",
        "yCap": 4337.2,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "PHL1997",
        "yCap": 4450.85,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "PHL1998",
        "yCap": 4322.58,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "PHL1999",
        "yCap": 4364.28,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "PHL2000",
        "yCap": 4455.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "PHL2001",
        "yCap": 4495.36,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "PHL2002",
        "yCap": 4567.23,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "PHL2003",
        "yCap": 4703.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "PHL2004",
        "yCap": 4914.04,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "PHL2005",
        "yCap": 5058.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "PHL2006",
        "yCap": 5227.53,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "PHL2007",
        "yCap": 5465.15,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "PHL2008",
        "yCap": 5596.91,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "PHL2009",
        "yCap": 5574.45,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "PHL2010",
        "yCap": 5876.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "PHL2011",
        "yCap": 5995.43,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "PHL2012",
        "yCap": 6298.16,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "PHL2013",
        "yCap": 6610.85,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "PHL2014",
        "yCap": 6917.75,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "PHL2015",
        "yCap": 7235.08,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "PHL2016",
        "yCap": 7616.05,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "PHL2017",
        "yCap": 8001.76,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "PHL2018",
        "yCap": 8365.73,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "PHL2019",
        "yCap": 8731.86,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "PHL2020",
        "yCap": 7773.26,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "PHL2021",
        "yCap": 8094.69,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Philippines",
        "Alpha-3": "PHL",
        "Alpha-2": "PH",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "PHL2022",
        "yCap": 8094.69,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "POL1990",
        "yCap": 11259.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "POL1991",
        "yCap": 10432.46,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "POL1992",
        "yCap": 10662.08,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "POL1993",
        "yCap": 11032.55,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "POL1994",
        "yCap": 11592,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "POL1995",
        "yCap": 12398.52,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "POL1996",
        "yCap": 13146.76,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "POL1997",
        "yCap": 13985.43,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "POL1998",
        "yCap": 14629.2,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "POL1999",
        "yCap": 15311.44,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "POL2000",
        "yCap": 16177.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "POL2001",
        "yCap": 16386,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "POL2002",
        "yCap": 16727.34,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "POL2003",
        "yCap": 17324.22,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "POL2004",
        "yCap": 18198.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "POL2005",
        "yCap": 18844.56,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "POL2006",
        "yCap": 20012.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "POL2007",
        "yCap": 21437.46,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "POL2008",
        "yCap": 22334.78,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "POL2009",
        "yCap": 22951.78,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "POL2010",
        "yCap": 23692.91,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "POL2011",
        "yCap": 24874.17,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "POL2012",
        "yCap": 25258.59,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "POL2013",
        "yCap": 25490.33,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "POL2014",
        "yCap": 26488.2,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "POL2015",
        "yCap": 27667.69,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "POL2016",
        "yCap": 28497.1,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "POL2017",
        "yCap": 29958.12,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "POL2018",
        "yCap": 31739.26,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "POL2019",
        "yCap": 33159.75,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "POL2020",
        "yCap": 32546.83,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "POL2021",
        "yCap": 34915.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Poland",
        "Alpha-3": "POL",
        "Alpha-2": "PL",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "POL2022",
        "yCap": 34915.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "PRT1990",
        "yCap": 23556.86,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "PRT1991",
        "yCap": 24642.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "PRT1992",
        "yCap": 24930.45,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "PRT1993",
        "yCap": 24391.2,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "PRT1994",
        "yCap": 24560.36,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "PRT1995",
        "yCap": 25523.71,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "PRT1996",
        "yCap": 26318.98,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "PRT1997",
        "yCap": 27354.84,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "PRT1998",
        "yCap": 28525.52,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "PRT1999",
        "yCap": 29472.71,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "PRT2000",
        "yCap": 30383.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "PRT2001",
        "yCap": 30756.02,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "PRT2002",
        "yCap": 30823.85,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "PRT2003",
        "yCap": 30422.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "PRT2004",
        "yCap": 30892.82,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "PRT2005",
        "yCap": 31076.64,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "PRT2006",
        "yCap": 31524.75,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "PRT2007",
        "yCap": 32251.57,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "PRT2008",
        "yCap": 32307.91,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "PRT2009",
        "yCap": 31269.41,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "PRT2010",
        "yCap": 31798.15,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "PRT2011",
        "yCap": 31304.82,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "PRT2012",
        "yCap": 30156.7,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "PRT2013",
        "yCap": 30042.89,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "PRT2014",
        "yCap": 30444.6,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "PRT2015",
        "yCap": 31118.79,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "PRT2016",
        "yCap": 31847.54,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "PRT2017",
        "yCap": 33044.72,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "PRT2018",
        "yCap": 34040.73,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "PRT2019",
        "yCap": 34945.66,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "PRT2020",
        "yCap": 32011.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "PRT2021",
        "yCap": 33674.53,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Portugal",
        "Alpha-3": "PRT",
        "Alpha-2": "PT",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "PRT2022",
        "yCap": 33674.53,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "PRI1990",
        "yCap": 21595.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "PRI1991",
        "yCap": 21937.9,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "PRI1992",
        "yCap": 22791.57,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "PRI1993",
        "yCap": 23650.11,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "PRI1994",
        "yCap": 24412.28,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "PRI1995",
        "yCap": 25288.11,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "PRI1996",
        "yCap": 25584.57,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "PRI1997",
        "yCap": 26584.91,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "PRI1998",
        "yCap": 27894.45,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "PRI1999",
        "yCap": 29251.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "PRI2000",
        "yCap": 30124.7,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "PRI2001",
        "yCap": 31958.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "PRI2002",
        "yCap": 32209.43,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "PRI2003",
        "yCap": 32206.47,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "PRI2004",
        "yCap": 35016.95,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "PRI2005",
        "yCap": 34370.72,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "PRI2006",
        "yCap": 34030.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "PRI2007",
        "yCap": 33831.94,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "PRI2008",
        "yCap": 33403.43,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "PRI2009",
        "yCap": 32930.37,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "PRI2010",
        "yCap": 32960.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "PRI2011",
        "yCap": 33224.57,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "PRI2012",
        "yCap": 33638.87,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "PRI2013",
        "yCap": 33922.17,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "PRI2014",
        "yCap": 34070.26,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "PRI2015",
        "yCap": 34311.04,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "PRI2016",
        "yCap": 34539.6,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "PRI2017",
        "yCap": 34363.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "PRI2018",
        "yCap": 34223.1,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "PRI2019",
        "yCap": 34792.66,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "PRI2020",
        "yCap": 32387.16,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "PRI2021",
        "yCap": 32632.88,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Puerto Rico",
        "Alpha-3": "PRI",
        "Alpha-2": "PR",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "PRI2022",
        "yCap": 32632.88,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "QAT1990",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "QAT1991",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "QAT1992",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "QAT1993",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "QAT1994",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "QAT1995",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "QAT1996",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "QAT1997",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "QAT1998",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "QAT1999",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "QAT2000",
        "yCap": 79400.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "QAT2001",
        "yCap": 78498.13,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "QAT2002",
        "yCap": 80083.06,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "QAT2003",
        "yCap": 79140.63,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "QAT2004",
        "yCap": 90782.72,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "QAT2005",
        "yCap": 89448.05,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "QAT2006",
        "yCap": 94361.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "QAT2007",
        "yCap": 91736.75,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "QAT2008",
        "yCap": 92067.81,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "QAT2009",
        "yCap": 92450.25,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "QAT2010",
        "yCap": 103902.52,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "QAT2011",
        "yCap": 111879.75,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "QAT2012",
        "yCap": 110931.51,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "QAT2013",
        "yCap": 109625.63,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "QAT2014",
        "yCap": 106141.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "QAT2015",
        "yCap": 101971.99,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "QAT2016",
        "yCap": 97783.12,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "QAT2017",
        "yCap": 92177.6,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "QAT2018",
        "yCap": 91461.62,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "QAT2019",
        "yCap": 90840.43,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "QAT2020",
        "yCap": 89019.07,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "QAT2021",
        "yCap": 92862.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Qatar",
        "Alpha-3": "QAT",
        "Alpha-2": "QA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "QAT2022",
        "yCap": 92862.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "ROU1990",
        "yCap": 13376.1,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "ROU1991",
        "yCap": 11749.78,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "ROU1992",
        "yCap": 10816.93,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "ROU1993",
        "yCap": 10997.26,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "ROU1994",
        "yCap": 11446.3,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "ROU1995",
        "yCap": 12184.43,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "ROU1996",
        "yCap": 12697.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "ROU1997",
        "yCap": 12116.24,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "ROU1998",
        "yCap": 11894.89,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "ROU1999",
        "yCap": 11868.71,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "ROU2000",
        "yCap": 12176.58,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "ROU2001",
        "yCap": 12992.01,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "ROU2002",
        "yCap": 13986.66,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "ROU2003",
        "yCap": 14417.72,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "ROU2004",
        "yCap": 16012.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "ROU2005",
        "yCap": 16863.48,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "ROU2006",
        "yCap": 18325.66,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "ROU2007",
        "yCap": 19943.75,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "ROU2008",
        "yCap": 22166.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "ROU2009",
        "yCap": 21118.53,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "ROU2010",
        "yCap": 20415.54,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "ROU2011",
        "yCap": 21442.94,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "ROU2012",
        "yCap": 21953.23,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "ROU2013",
        "yCap": 22094.39,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "ROU2014",
        "yCap": 23091.16,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "ROU2015",
        "yCap": 23933.19,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "ROU2016",
        "yCap": 24758.71,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "ROU2017",
        "yCap": 26943.35,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "ROU2018",
        "yCap": 28736.09,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "ROU2019",
        "yCap": 30000.98,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "ROU2020",
        "yCap": 29057.28,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "ROU2021",
        "yCap": 30771.44,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Romania",
        "Alpha-3": "ROU",
        "Alpha-2": "RO",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "ROU2022",
        "yCap": 30771.44,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "RUS1990",
        "yCap": 21482.75,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "RUS1991",
        "yCap": 20340.13,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "RUS1992",
        "yCap": 17367.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "RUS1993",
        "yCap": 15870.61,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "RUS1994",
        "yCap": 13880.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "RUS1995",
        "yCap": 13308.21,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "RUS1996",
        "yCap": 12827.12,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "RUS1997",
        "yCap": 13028.21,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "RUS1998",
        "yCap": 12358.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "RUS1999",
        "yCap": 13189.79,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "RUS2000",
        "yCap": 14569.94,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "RUS2001",
        "yCap": 15378.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "RUS2002",
        "yCap": 16175.1,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "RUS2003",
        "yCap": 17434.81,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "RUS2004",
        "yCap": 18765.52,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "RUS2005",
        "yCap": 20042.81,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "RUS2006",
        "yCap": 21757.46,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "RUS2007",
        "yCap": 23647.27,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "RUS2008",
        "yCap": 24887.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "RUS2009",
        "yCap": 22939.69,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "RUS2010",
        "yCap": 23961.22,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "RUS2011",
        "yCap": 24972.08,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "RUS2012",
        "yCap": 25933.29,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "RUS2013",
        "yCap": 26332.4,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "RUS2014",
        "yCap": 26057.16,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "RUS2015",
        "yCap": 25488.1,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "RUS2016",
        "yCap": 25490.71,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "RUS2017",
        "yCap": 25926.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "RUS2018",
        "yCap": 26656.41,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "RUS2019",
        "yCap": 27254.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "RUS2020",
        "yCap": 26583.8,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "RUS2021",
        "yCap": 27960.1,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Russian Federation",
        "Alpha-3": "RUS",
        "Alpha-2": "RU",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "RUS2022",
        "yCap": 27960.1,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "RWA1990",
        "yCap": 932.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "RWA1991",
        "yCap": 889.08,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "RWA1992",
        "yCap": 920.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "RWA1993",
        "yCap": 819.11,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "RWA1994",
        "yCap": 478.47,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "RWA1995",
        "yCap": 765.98,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "RWA1996",
        "yCap": 731.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "RWA1997",
        "yCap": 729.32,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "RWA1998",
        "yCap": 769.06,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "RWA1999",
        "yCap": 793.01,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "RWA2000",
        "yCap": 848.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "RWA2001",
        "yCap": 908.01,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "RWA2002",
        "yCap": 1009.58,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "RWA2003",
        "yCap": 1008.25,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "RWA2004",
        "yCap": 1055.76,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "RWA2005",
        "yCap": 1124.77,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "RWA2006",
        "yCap": 1196.25,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "RWA2007",
        "yCap": 1253.34,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "RWA2008",
        "yCap": 1356.37,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "RWA2009",
        "yCap": 1403.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "RWA2010",
        "yCap": 1467.74,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "RWA2011",
        "yCap": 1544.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "RWA2012",
        "yCap": 1637.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "RWA2013",
        "yCap": 1674.06,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "RWA2014",
        "yCap": 1735.55,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "RWA2015",
        "yCap": 1844.72,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "RWA2016",
        "yCap": 1907.68,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "RWA2017",
        "yCap": 1934.97,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "RWA2018",
        "yCap": 2050.44,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "RWA2019",
        "yCap": 2191.4,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "RWA2020",
        "yCap": 2067.64,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "RWA2021",
        "yCap": 2238.96,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Rwanda",
        "Alpha-3": "RWA",
        "Alpha-2": "RW",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "RWA2022",
        "yCap": 2238.96,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "WSM1990",
        "yCap": 3655.89,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "WSM1991",
        "yCap": 3560.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "WSM1992",
        "yCap": 3530.8,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "WSM1993",
        "yCap": 3642.03,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "WSM1994",
        "yCap": 3513.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "WSM1995",
        "yCap": 3709.69,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "WSM1996",
        "yCap": 3935.26,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "WSM1997",
        "yCap": 3919.99,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "WSM1998",
        "yCap": 3965.12,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "WSM1999",
        "yCap": 4011.17,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "WSM2000",
        "yCap": 4174.3,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "WSM2001",
        "yCap": 4446.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "WSM2002",
        "yCap": 4669.61,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "WSM2003",
        "yCap": 4891.79,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "WSM2004",
        "yCap": 5025.83,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "WSM2005",
        "yCap": 5343.8,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "WSM2006",
        "yCap": 5436.03,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "WSM2007",
        "yCap": 5430.94,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "WSM2008",
        "yCap": 5585.68,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "WSM2009",
        "yCap": 5515.48,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "WSM2010",
        "yCap": 5808.3,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "WSM2011",
        "yCap": 5980.29,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "WSM2012",
        "yCap": 5705.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "WSM2013",
        "yCap": 5659.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "WSM2014",
        "yCap": 5645.87,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "WSM2015",
        "yCap": 5811.01,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "WSM2016",
        "yCap": 6214.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "WSM2017",
        "yCap": 6238.8,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "WSM2018",
        "yCap": 6139.52,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "WSM2019",
        "yCap": 6346.15,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "WSM2020",
        "yCap": 6062.37,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "WSM2021",
        "yCap": 5534.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Samoa",
        "Alpha-3": "WSM",
        "Alpha-2": "WS",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "WSM2022",
        "yCap": 5534.13,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "SMR1990",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "SMR1991",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "SMR1992",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "SMR1993",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "SMR1994",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "SMR1995",
        "yCap": 66407.51,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "SMR1996",
        "yCap": 66407.51,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "SMR1997",
        "yCap": 66407.51,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "SMR1998",
        "yCap": 70264.45,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "SMR1999",
        "yCap": 75441.84,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "SMR2000",
        "yCap": 75881.85,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "SMR2001",
        "yCap": 78614.2,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "SMR2002",
        "yCap": 77070.97,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "SMR2003",
        "yCap": 78286.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "SMR2004",
        "yCap": 80463.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "SMR2005",
        "yCap": 81256.14,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "SMR2006",
        "yCap": 83098.25,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "SMR2007",
        "yCap": 87795.66,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "SMR2008",
        "yCap": 86422.55,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "SMR2009",
        "yCap": 76607.09,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "SMR2010",
        "yCap": 71156.1,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "SMR2011",
        "yCap": 63458.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "SMR2012",
        "yCap": 57854.32,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "SMR2013",
        "yCap": 57136.9,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "SMR2014",
        "yCap": 56558.87,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "SMR2015",
        "yCap": 57542.98,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "SMR2016",
        "yCap": 58432.5,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "SMR2017",
        "yCap": 58201.53,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "SMR2018",
        "yCap": 58898.06,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "SMR2019",
        "yCap": 60076.01,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "SMR2020",
        "yCap": 56364.21,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "SMR2021",
        "yCap": 56364.21,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "San Marino",
        "Alpha-3": "SMR",
        "Alpha-2": "SM",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "SMR2022",
        "yCap": 56364.21,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "STP1990",
        "yCap": 2558.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "STP1991",
        "yCap": 2558.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "STP1992",
        "yCap": 2558.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "STP1993",
        "yCap": 2558.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "STP1994",
        "yCap": 2558.98,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "STP1995",
        "yCap": 2558.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "STP1996",
        "yCap": 2558.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "STP1997",
        "yCap": 2558.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "STP1998",
        "yCap": 2558.98,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "STP1999",
        "yCap": 2558.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "STP2000",
        "yCap": 2558.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "STP2001",
        "yCap": 2558.98,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "STP2002",
        "yCap": 2581.72,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "STP2003",
        "yCap": 2724.49,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "STP2004",
        "yCap": 2750.49,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "STP2005",
        "yCap": 2860.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "STP2006",
        "yCap": 3037.78,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "STP2007",
        "yCap": 3072.02,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "STP2008",
        "yCap": 3185.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "STP2009",
        "yCap": 3187.12,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "STP2010",
        "yCap": 3324.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "STP2011",
        "yCap": 3398.28,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "STP2012",
        "yCap": 3433.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "STP2013",
        "yCap": 3527.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "STP2014",
        "yCap": 3687.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "STP2015",
        "yCap": 3762.57,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "STP2016",
        "yCap": 3852.08,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "STP2017",
        "yCap": 3934.89,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "STP2018",
        "yCap": 3987.38,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "STP2019",
        "yCap": 4013.72,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "STP2020",
        "yCap": 4058.67,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "STP2021",
        "yCap": 4052.12,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sao Tome and Principe",
        "Alpha-3": "STP",
        "Alpha-2": "ST",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "STP2022",
        "yCap": 4052.12,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "SAU1990",
        "yCap": 41984.83,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "SAU1991",
        "yCap": 46402.72,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "SAU1992",
        "yCap": 46502.13,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "SAU1993",
        "yCap": 44415.55,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "SAU1994",
        "yCap": 43396.64,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "SAU1995",
        "yCap": 42288.4,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "SAU1996",
        "yCap": 42237.76,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "SAU1997",
        "yCap": 41572.9,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "SAU1998",
        "yCap": 41659.58,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "SAU1999",
        "yCap": 39066.93,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "SAU2000",
        "yCap": 40234.82,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "SAU2001",
        "yCap": 38778.48,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "SAU2002",
        "yCap": 36789.92,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "SAU2003",
        "yCap": 39993.48,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "SAU2004",
        "yCap": 42243.97,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "SAU2005",
        "yCap": 43253.49,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "SAU2006",
        "yCap": 42733.89,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "SAU2007",
        "yCap": 41846.28,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "SAU2008",
        "yCap": 42780.68,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "SAU2009",
        "yCap": 40360.4,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "SAU2010",
        "yCap": 41056.55,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "SAU2011",
        "yCap": 44054,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "SAU2012",
        "yCap": 45427.57,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "SAU2013",
        "yCap": 45674.32,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "SAU2014",
        "yCap": 46394.9,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "SAU2015",
        "yCap": 47379.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "SAU2016",
        "yCap": 47210.23,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "SAU2017",
        "yCap": 45795.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "SAU2018",
        "yCap": 45840.84,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "SAU2019",
        "yCap": 44954.99,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "SAU2020",
        "yCap": 42891.69,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "SAU2021",
        "yCap": 44339.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Saudi Arabia",
        "Alpha-3": "SAU",
        "Alpha-2": "SA",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "SAU2022",
        "yCap": 44339.31,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "SEN1990",
        "yCap": 2469.6,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "SEN1991",
        "yCap": 2463.92,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "SEN1992",
        "yCap": 2427.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "SEN1993",
        "yCap": 2393.88,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "SEN1994",
        "yCap": 2330.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "SEN1995",
        "yCap": 2396.09,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "SEN1996",
        "yCap": 2386.24,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "SEN1997",
        "yCap": 2402.38,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "SEN1998",
        "yCap": 2486.4,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "SEN1999",
        "yCap": 2582.12,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "SEN2000",
        "yCap": 2620.1,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "SEN2001",
        "yCap": 2668.77,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "SEN2002",
        "yCap": 2606.88,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "SEN2003",
        "yCap": 2685.82,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "SEN2004",
        "yCap": 2741.12,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "SEN2005",
        "yCap": 2787.53,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "SEN2006",
        "yCap": 2779.22,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "SEN2007",
        "yCap": 2783.54,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "SEN2008",
        "yCap": 2811.48,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "SEN2009",
        "yCap": 2812.55,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "SEN2010",
        "yCap": 2830.15,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "SEN2011",
        "yCap": 2790.9,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "SEN2012",
        "yCap": 2824.53,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "SEN2013",
        "yCap": 2815.28,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "SEN2014",
        "yCap": 2910.29,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "SEN2015",
        "yCap": 3012.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "SEN2016",
        "yCap": 3118.42,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "SEN2017",
        "yCap": 3259.19,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "SEN2018",
        "yCap": 3368.86,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "SEN2019",
        "yCap": 3430.48,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "SEN2020",
        "yCap": 3383.89,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "SEN2021",
        "yCap": 3495.4,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Senegal",
        "Alpha-3": "SEN",
        "Alpha-2": "SN",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "SEN2022",
        "yCap": 3495.4,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "SRB1990",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "SRB1991",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "SRB1992",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "SRB1993",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "SRB1994",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "SRB1995",
        "yCap": 7752.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "SRB1996",
        "yCap": 8245.99,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "SRB1997",
        "yCap": 8865.08,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "SRB1998",
        "yCap": 9196.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "SRB1999",
        "yCap": 8359.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "SRB2000",
        "yCap": 8900.61,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "SRB2001",
        "yCap": 9529.23,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "SRB2002",
        "yCap": 10146.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "SRB2003",
        "yCap": 10614.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "SRB2004",
        "yCap": 11599.67,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "SRB2005",
        "yCap": 12278,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "SRB2006",
        "yCap": 12955.98,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "SRB2007",
        "yCap": 13846.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "SRB2008",
        "yCap": 14691.81,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "SRB2009",
        "yCap": 14347.88,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "SRB2010",
        "yCap": 14510.99,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "SRB2011",
        "yCap": 14923.83,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "SRB2012",
        "yCap": 14894.22,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "SRB2013",
        "yCap": 15399.81,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "SRB2014",
        "yCap": 15226.35,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "SRB2015",
        "yCap": 15578.23,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "SRB2016",
        "yCap": 16182.85,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "SRB2017",
        "yCap": 16611.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "SRB2018",
        "yCap": 17452.83,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "SRB2019",
        "yCap": 18306.81,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "SRB2020",
        "yCap": 18262.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "SRB2021",
        "yCap": 19827.76,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Serbia",
        "Alpha-3": "SRB",
        "Alpha-2": "RS",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "SRB2022",
        "yCap": 19827.76,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1990,
        "ID": "SYC1990",
        "yCap": 14884.89,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1991,
        "ID": "SYC1991",
        "yCap": 15093.39,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1992,
        "ID": "SYC1992",
        "yCap": 16100.24,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1993,
        "ID": "SYC1993",
        "yCap": 16745.33,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1994,
        "ID": "SYC1994",
        "yCap": 16174.6,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1995,
        "ID": "SYC1995",
        "yCap": 15806.95,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1996,
        "ID": "SYC1996",
        "yCap": 16342.72,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1997,
        "ID": "SYC1997",
        "yCap": 18084.1,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1998,
        "ID": "SYC1998",
        "yCap": 19224.12,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 1999,
        "ID": "SYC1999",
        "yCap": 19203.08,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2000,
        "ID": "SYC2000",
        "yCap": 19320.64,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2001,
        "ID": "SYC2001",
        "yCap": 18865.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2002,
        "ID": "SYC2002",
        "yCap": 18519.19,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2003,
        "ID": "SYC2003",
        "yCap": 17627.27,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2004,
        "ID": "SYC2004",
        "yCap": 17188.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2005,
        "ID": "SYC2005",
        "yCap": 18649.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2006,
        "ID": "SYC2006",
        "yCap": 19983.76,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2007,
        "ID": "SYC2007",
        "yCap": 21953.99,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2008,
        "ID": "SYC2008",
        "yCap": 21007.58,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2009,
        "ID": "SYC2009",
        "yCap": 20694,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2010,
        "ID": "SYC2010",
        "yCap": 21322.54,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2011,
        "ID": "SYC2011",
        "yCap": 23617.03,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2012,
        "ID": "SYC2012",
        "yCap": 23681.35,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2013,
        "ID": "SYC2013",
        "yCap": 24647.08,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2014,
        "ID": "SYC2014",
        "yCap": 25359.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2015,
        "ID": "SYC2015",
        "yCap": 26198.72,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2016,
        "ID": "SYC2016",
        "yCap": 27234.41,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2017,
        "ID": "SYC2017",
        "yCap": 28116.71,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2018,
        "ID": "SYC2018",
        "yCap": 28740.55,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2019,
        "ID": "SYC2019",
        "yCap": 29369.53,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2020,
        "ID": "SYC2020",
        "yCap": 26879.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2021,
        "ID": "SYC2021",
        "yCap": 28760.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Seychelles",
        "Alpha-3": "SYC",
        "Alpha-2": "SC",
        "Region": "SSA",
        "IncGroup": "High",
        "RegIncGrp": "SSA-High",
        "Time": 2022,
        "ID": "SYC2022",
        "yCap": 28760.52,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "SLE1990",
        "yCap": 1451.25,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "SLE1991",
        "yCap": 1467.48,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "SLE1992",
        "yCap": 1209.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "SLE1993",
        "yCap": 1227.94,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "SLE1994",
        "yCap": 1198.65,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "SLE1995",
        "yCap": 1100.42,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "SLE1996",
        "yCap": 1113.73,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "SLE1997",
        "yCap": 1034.56,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "SLE1998",
        "yCap": 1042.39,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "SLE1999",
        "yCap": 1016.01,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "SLE2000",
        "yCap": 1057.86,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "SLE2001",
        "yCap": 935.04,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "SLE2002",
        "yCap": 1116.97,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "SLE2003",
        "yCap": 1172.89,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "SLE2004",
        "yCap": 1209.06,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "SLE2005",
        "yCap": 1230.18,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "SLE2006",
        "yCap": 1254.24,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "SLE2007",
        "yCap": 1325.78,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "SLE2008",
        "yCap": 1362.55,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "SLE2009",
        "yCap": 1368.03,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "SLE2010",
        "yCap": 1401.57,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "SLE2011",
        "yCap": 1450.49,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "SLE2012",
        "yCap": 1627.34,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "SLE2013",
        "yCap": 1914.74,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "SLE2014",
        "yCap": 1952.69,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "SLE2015",
        "yCap": 1513.56,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "SLE2016",
        "yCap": 1566.84,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "SLE2017",
        "yCap": 1593.48,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "SLE2018",
        "yCap": 1610.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "SLE2019",
        "yCap": 1655.69,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "SLE2020",
        "yCap": 1586.2,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "SLE2021",
        "yCap": 1614.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sierra Leone",
        "Alpha-3": "SLE",
        "Alpha-2": "SL",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "SLE2022",
        "yCap": 1614.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1990,
        "ID": "SGP1990",
        "yCap": 37289.75,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1991,
        "ID": "SGP1991",
        "yCap": 38667.75,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1992,
        "ID": "SGP1992",
        "yCap": 40014.83,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1993,
        "ID": "SGP1993",
        "yCap": 43486.23,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1994,
        "ID": "SGP1994",
        "yCap": 46819.52,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1995,
        "ID": "SGP1995",
        "yCap": 48677.59,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1996,
        "ID": "SGP1996",
        "yCap": 50230.88,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1997,
        "ID": "SGP1997",
        "yCap": 52611.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1998,
        "ID": "SGP1998",
        "yCap": 49740.24,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 1999,
        "ID": "SGP1999",
        "yCap": 52166.02,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2000,
        "ID": "SGP2000",
        "yCap": 55904.23,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2001,
        "ID": "SGP2001",
        "yCap": 53833.73,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2002,
        "ID": "SGP2002",
        "yCap": 55437.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2003,
        "ID": "SGP2003",
        "yCap": 58819.96,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2004,
        "ID": "SGP2004",
        "yCap": 63862.12,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2005,
        "ID": "SGP2005",
        "yCap": 66973.55,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2006,
        "ID": "SGP2006",
        "yCap": 70756.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2007,
        "ID": "SGP2007",
        "yCap": 73992.12,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2008,
        "ID": "SGP2008",
        "yCap": 71464.93,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2009,
        "ID": "SGP2009",
        "yCap": 69430.48,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2010,
        "ID": "SGP2010",
        "yCap": 78115.21,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2011,
        "ID": "SGP2011",
        "yCap": 81258.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2012,
        "ID": "SGP2012",
        "yCap": 82805.61,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2013,
        "ID": "SGP2013",
        "yCap": 85400.73,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2014,
        "ID": "SGP2014",
        "yCap": 87616.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2015,
        "ID": "SGP2015",
        "yCap": 89160.73,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2016,
        "ID": "SGP2016",
        "yCap": 91146.1,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2017,
        "ID": "SGP2017",
        "yCap": 95310.33,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2018,
        "ID": "SGP2018",
        "yCap": 98336.96,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2019,
        "ID": "SGP2019",
        "yCap": 98283.31,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2020,
        "ID": "SGP2020",
        "yCap": 94505.64,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2021,
        "ID": "SGP2021",
        "yCap": 106032.22,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Singapore",
        "Alpha-3": "SGP",
        "Alpha-2": "SG",
        "Region": "EAP",
        "IncGroup": "High",
        "RegIncGrp": "EAP-High",
        "Time": 2022,
        "ID": "SGP2022",
        "yCap": 106032.22,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "SXM1990",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "SXM1991",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "SXM1992",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "SXM1993",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "SXM1994",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "SXM1995",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "SXM1996",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "SXM1997",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "SXM1998",
        "yCap": 41969.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "SXM1999",
        "yCap": 41969.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "SXM2000",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "SXM2001",
        "yCap": 41969.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "SXM2002",
        "yCap": 41969.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "SXM2003",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "SXM2004",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "SXM2005",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "SXM2006",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "SXM2007",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "SXM2008",
        "yCap": 41969.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "SXM2009",
        "yCap": 41969.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "SXM2010",
        "yCap": 43543.89,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "SXM2011",
        "yCap": 46392.87,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "SXM2012",
        "yCap": 45401.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "SXM2013",
        "yCap": 43523.54,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "SXM2014",
        "yCap": 42946.69,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "SXM2015",
        "yCap": 41852.4,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "SXM2016",
        "yCap": 40857.77,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "SXM2017",
        "yCap": 37914.12,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "SXM2018",
        "yCap": 35133.83,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "SXM2019",
        "yCap": 35133.83,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "SXM2020",
        "yCap": 35133.83,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "SXM2021",
        "yCap": 35133.83,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sint Maarten (Dutch part)",
        "Alpha-3": "SXM",
        "Alpha-2": "SX",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "SXM2022",
        "yCap": 35133.83,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "SVK1990",
        "yCap": 11663.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "SVK1991",
        "yCap": 11663.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "SVK1992",
        "yCap": 11663.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "SVK1993",
        "yCap": 11840.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "SVK1994",
        "yCap": 12525.79,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "SVK1995",
        "yCap": 13218.99,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "SVK1996",
        "yCap": 14064.27,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "SVK1997",
        "yCap": 14870.22,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "SVK1998",
        "yCap": 15455.6,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "SVK1999",
        "yCap": 15423.48,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "SVK2000",
        "yCap": 15624.54,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "SVK2001",
        "yCap": 16162.48,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "SVK2002",
        "yCap": 16897.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "SVK2003",
        "yCap": 17838.4,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "SVK2004",
        "yCap": 18783.9,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "SVK2005",
        "yCap": 20026.08,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "SVK2006",
        "yCap": 21725.93,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "SVK2007",
        "yCap": 24072.26,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "SVK2008",
        "yCap": 25392.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "SVK2009",
        "yCap": 23975.21,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "SVK2010",
        "yCap": 25561.71,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "SVK2011",
        "yCap": 26210.76,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "SVK2012",
        "yCap": 26511.27,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "SVK2013",
        "yCap": 26650.36,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "SVK2014",
        "yCap": 27342.63,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "SVK2015",
        "yCap": 28728.16,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "SVK2016",
        "yCap": 29248.88,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "SVK2017",
        "yCap": 30061.55,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "SVK2018",
        "yCap": 31229.86,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "SVK2019",
        "yCap": 31973.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "SVK2020",
        "yCap": 30867.97,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "SVK2021",
        "yCap": 31866.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovak Republic",
        "Alpha-3": "SVK",
        "Alpha-2": "SK",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "SVK2022",
        "yCap": 31866.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "SVN1990",
        "yCap": 21480.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "SVN1991",
        "yCap": 21480.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "SVN1992",
        "yCap": 21480.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "SVN1993",
        "yCap": 21480.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "SVN1994",
        "yCap": 21480.33,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "SVN1995",
        "yCap": 21480.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "SVN1996",
        "yCap": 22182.21,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "SVN1997",
        "yCap": 23333.72,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "SVN1998",
        "yCap": 24151.54,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "SVN1999",
        "yCap": 25421.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "SVN2000",
        "yCap": 26276.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "SVN2001",
        "yCap": 27079.47,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "SVN2002",
        "yCap": 27994.06,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "SVN2003",
        "yCap": 28805.39,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "SVN2004",
        "yCap": 30041.81,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "SVN2005",
        "yCap": 31128.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "SVN2006",
        "yCap": 32812.74,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "SVN2007",
        "yCap": 34907.37,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "SVN2008",
        "yCap": 36075.47,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "SVN2009",
        "yCap": 33052.23,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "SVN2010",
        "yCap": 33350.61,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "SVN2011",
        "yCap": 33568.07,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "SVN2012",
        "yCap": 32613.49,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "SVN2013",
        "yCap": 32234.03,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "SVN2014",
        "yCap": 33093.75,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "SVN2015",
        "yCap": 33799.73,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "SVN2016",
        "yCap": 34853.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "SVN2017",
        "yCap": 36507.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "SVN2018",
        "yCap": 37995.72,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "SVN2019",
        "yCap": 39034.23,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "SVN2020",
        "yCap": 37098.12,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "SVN2021",
        "yCap": 40036.49,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Slovenia",
        "Alpha-3": "SVN",
        "Alpha-2": "SI",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "SVN2022",
        "yCap": 40036.49,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "SLB1990",
        "yCap": 2192.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "SLB1991",
        "yCap": 2255.53,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "SLB1992",
        "yCap": 2468.34,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "SLB1993",
        "yCap": 2493.34,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "SLB1994",
        "yCap": 2618.26,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "SLB1995",
        "yCap": 2800.33,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "SLB1996",
        "yCap": 2765.22,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "SLB1997",
        "yCap": 2664.17,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "SLB1998",
        "yCap": 2625.22,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "SLB1999",
        "yCap": 2543.4,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "SLB2000",
        "yCap": 2125.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "SLB2001",
        "yCap": 1910.35,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "SLB2002",
        "yCap": 1814.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "SLB2003",
        "yCap": 1888.7,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "SLB2004",
        "yCap": 1988.25,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "SLB2005",
        "yCap": 2087.2,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "SLB2006",
        "yCap": 2125.37,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "SLB2007",
        "yCap": 2153.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "SLB2008",
        "yCap": 2237.51,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "SLB2009",
        "yCap": 2250.01,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "SLB2010",
        "yCap": 2411.07,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "SLB2011",
        "yCap": 2527.87,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "SLB2012",
        "yCap": 2526.15,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "SLB2013",
        "yCap": 2591.8,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "SLB2014",
        "yCap": 2556.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "SLB2015",
        "yCap": 2534.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "SLB2016",
        "yCap": 2609.74,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "SLB2017",
        "yCap": 2625.09,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "SLB2018",
        "yCap": 2633.29,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "SLB2019",
        "yCap": 2616.86,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "SLB2020",
        "yCap": 2469.11,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "SLB2021",
        "yCap": 2406.11,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Solomon Islands",
        "Alpha-3": "SLB",
        "Alpha-2": "SB",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "SLB2022",
        "yCap": 2406.11,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "SOM1990",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "SOM1991",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "SOM1992",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "SOM1993",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "SOM1994",
        "yCap": 889.17,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "SOM1995",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "SOM1996",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "SOM1997",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "SOM1998",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "SOM1999",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "SOM2000",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "SOM2001",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "SOM2002",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "SOM2003",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "SOM2004",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "SOM2005",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "SOM2006",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "SOM2007",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "SOM2008",
        "yCap": 889.17,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "SOM2009",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "SOM2010",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "SOM2011",
        "yCap": 889.17,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "SOM2012",
        "yCap": 889.17,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "SOM2013",
        "yCap": 889.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "SOM2014",
        "yCap": 925.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "SOM2015",
        "yCap": 1029.7,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "SOM2016",
        "yCap": 1076.2,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "SOM2017",
        "yCap": 1059.14,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "SOM2018",
        "yCap": 1098.49,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "SOM2019",
        "yCap": 1138.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "SOM2020",
        "yCap": 1127.42,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "SOM2021",
        "yCap": 1136.74,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Somalia",
        "Alpha-3": "SOM",
        "Alpha-2": "SO",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "SOM2022",
        "yCap": 1136.74,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1990,
        "ID": "ZAF1990",
        "yCap": 10391.96,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1991,
        "ID": "ZAF1991",
        "yCap": 10026.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1992,
        "ID": "ZAF1992",
        "yCap": 9612.39,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1993,
        "ID": "ZAF1993",
        "yCap": 9555.99,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1994,
        "ID": "ZAF1994",
        "yCap": 9692.54,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1995,
        "ID": "ZAF1995",
        "yCap": 9829.86,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1996,
        "ID": "ZAF1996",
        "yCap": 10097.47,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1997,
        "ID": "ZAF1997",
        "yCap": 10217.38,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1998,
        "ID": "ZAF1998",
        "yCap": 10141.46,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 1999,
        "ID": "ZAF1999",
        "yCap": 10270.06,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2000,
        "ID": "ZAF2000",
        "yCap": 10598.86,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2001,
        "ID": "ZAF2001",
        "yCap": 10789.05,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2002,
        "ID": "ZAF2002",
        "yCap": 11086.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2003,
        "ID": "ZAF2003",
        "yCap": 11308.88,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2004,
        "ID": "ZAF2004",
        "yCap": 11713.88,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2005,
        "ID": "ZAF2005",
        "yCap": 12216.03,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2006,
        "ID": "ZAF2006",
        "yCap": 12776.88,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2007,
        "ID": "ZAF2007",
        "yCap": 13325.98,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2008,
        "ID": "ZAF2008",
        "yCap": 13596.29,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2009,
        "ID": "ZAF2009",
        "yCap": 13228.89,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2010,
        "ID": "ZAF2010",
        "yCap": 13469.36,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2011,
        "ID": "ZAF2011",
        "yCap": 13721.69,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2012,
        "ID": "ZAF2012",
        "yCap": 13864.97,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2013,
        "ID": "ZAF2013",
        "yCap": 14017.41,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2014,
        "ID": "ZAF2014",
        "yCap": 13993.27,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2015,
        "ID": "ZAF2015",
        "yCap": 13887.21,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2016,
        "ID": "ZAF2016",
        "yCap": 13844.28,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2017,
        "ID": "ZAF2017",
        "yCap": 13950.45,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2018,
        "ID": "ZAF2018",
        "yCap": 13990.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2019,
        "ID": "ZAF2019",
        "yCap": 13852.21,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2020,
        "ID": "ZAF2020",
        "yCap": 12815.91,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2021,
        "ID": "ZAF2021",
        "yCap": 13311.93,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Africa",
        "Alpha-3": "ZAF",
        "Alpha-2": "ZA",
        "Region": "SSA",
        "IncGroup": "UpMid",
        "RegIncGrp": "SSA-UpMid",
        "Time": 2022,
        "ID": "ZAF2022",
        "yCap": 13311.93,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "SSD1990",
        "yCap": 3314.23,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "SSD1991",
        "yCap": 3321.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "SSD1992",
        "yCap": 3288.3,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "SSD1993",
        "yCap": 3252.75,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "SSD1994",
        "yCap": 3226.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "SSD1995",
        "yCap": 3295.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "SSD1996",
        "yCap": 3400.43,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "SSD1997",
        "yCap": 3587.25,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "SSD1998",
        "yCap": 3656.06,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "SSD1999",
        "yCap": 3675.57,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "SSD2000",
        "yCap": 3725.11,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "SSD2001",
        "yCap": 3854.99,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "SSD2002",
        "yCap": 3933.49,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "SSD2003",
        "yCap": 3987.22,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "SSD2004",
        "yCap": 4196.07,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "SSD2005",
        "yCap": 4372.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "SSD2006",
        "yCap": 4526.64,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "SSD2007",
        "yCap": 4735.79,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "SSD2008",
        "yCap": 4856.07,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "SSD2009",
        "yCap": 4779.56,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "SSD2010",
        "yCap": 4821.24,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "SSD2011",
        "yCap": 4976.13,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "SSD2012",
        "yCap": 5087.71,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "SSD2013",
        "yCap": 5149.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "SSD2014",
        "yCap": 5227.59,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "SSD2015",
        "yCap": 5177.97,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "SSD2016",
        "yCap": 5161.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "SSD2017",
        "yCap": 5176,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "SSD2018",
        "yCap": 5196.74,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "SSD2019",
        "yCap": 5220.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "SSD2020",
        "yCap": 4876.83,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "SSD2021",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "South Sudan",
        "Alpha-3": "SSD",
        "Alpha-2": "SS",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "SSD2022",
        "yCap": 5008.87,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "ESP1990",
        "yCap": 27528.41,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "ESP1991",
        "yCap": 28157.53,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "ESP1992",
        "yCap": 28280.33,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "ESP1993",
        "yCap": 27843.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "ESP1994",
        "yCap": 28372.03,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "ESP1995",
        "yCap": 29025.99,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "ESP1996",
        "yCap": 29674.39,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "ESP1997",
        "yCap": 30644.38,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "ESP1998",
        "yCap": 31858.5,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "ESP1999",
        "yCap": 33154.47,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "ESP2000",
        "yCap": 34738.06,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "ESP2001",
        "yCap": 35854.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "ESP2002",
        "yCap": 36317.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "ESP2003",
        "yCap": 36729.77,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "ESP2004",
        "yCap": 37228.8,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "ESP2005",
        "yCap": 37942.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "ESP2006",
        "yCap": 38836.59,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "ESP2007",
        "yCap": 39498.59,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "ESP2008",
        "yCap": 39218.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "ESP2009",
        "yCap": 37409.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "ESP2010",
        "yCap": 37298.47,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "ESP2011",
        "yCap": 36863.49,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "ESP2012",
        "yCap": 35749.51,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "ESP2013",
        "yCap": 35363.51,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "ESP2014",
        "yCap": 35964.46,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "ESP2015",
        "yCap": 37373.95,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "ESP2016",
        "yCap": 38476.79,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "ESP2017",
        "yCap": 39528.93,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "ESP2018",
        "yCap": 40255.25,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "ESP2019",
        "yCap": 40760.31,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "ESP2020",
        "yCap": 35967.89,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "ESP2021",
        "yCap": 37913.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Spain",
        "Alpha-3": "ESP",
        "Alpha-2": "ES",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "ESP2022",
        "yCap": 37913.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1990,
        "ID": "LKA1990",
        "yCap": 4011.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1991,
        "ID": "LKA1991",
        "yCap": 4145.95,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1992,
        "ID": "LKA1992",
        "yCap": 4279.3,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1993,
        "ID": "LKA1993",
        "yCap": 4526.59,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1994,
        "ID": "LKA1994",
        "yCap": 4735.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1995,
        "ID": "LKA1995",
        "yCap": 4955.05,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1996,
        "ID": "LKA1996",
        "yCap": 5108.52,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1997,
        "ID": "LKA1997",
        "yCap": 5405.25,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1998,
        "ID": "LKA1998",
        "yCap": 5630.65,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 1999,
        "ID": "LKA1999",
        "yCap": 5841.74,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2000,
        "ID": "LKA2000",
        "yCap": 6154.55,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2001,
        "ID": "LKA2001",
        "yCap": 6016.46,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2002,
        "ID": "LKA2002",
        "yCap": 6205.53,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2003,
        "ID": "LKA2003",
        "yCap": 6518.9,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2004,
        "ID": "LKA2004",
        "yCap": 6816.03,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2005,
        "ID": "LKA2005",
        "yCap": 7182.99,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2006,
        "ID": "LKA2006",
        "yCap": 7674.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2007,
        "ID": "LKA2007",
        "yCap": 8135.8,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2008,
        "ID": "LKA2008",
        "yCap": 8558.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2009,
        "ID": "LKA2009",
        "yCap": 8800.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2010,
        "ID": "LKA2010",
        "yCap": 9440.67,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2011,
        "ID": "LKA2011",
        "yCap": 10190.35,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2012,
        "ID": "LKA2012",
        "yCap": 11055.63,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2013,
        "ID": "LKA2013",
        "yCap": 11414.17,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2014,
        "ID": "LKA2014",
        "yCap": 12029.38,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2015,
        "ID": "LKA2015",
        "yCap": 12420.55,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2016,
        "ID": "LKA2016",
        "yCap": 12904.85,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2017,
        "ID": "LKA2017",
        "yCap": 13584.19,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2018,
        "ID": "LKA2018",
        "yCap": 13753.05,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2019,
        "ID": "LKA2019",
        "yCap": 13639.02,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2020,
        "ID": "LKA2020",
        "yCap": 13095.76,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2021,
        "ID": "LKA2021",
        "yCap": 13386.68,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sri Lanka",
        "Alpha-3": "LKA",
        "Alpha-2": "LK",
        "Region": "SA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SA-LowMid",
        "Time": 2022,
        "ID": "LKA2022",
        "yCap": 13386.68,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "KNA1990",
        "yCap": 14178.57,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "KNA1991",
        "yCap": 13946.45,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "KNA1992",
        "yCap": 14414.53,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "KNA1993",
        "yCap": 15191.82,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "KNA1994",
        "yCap": 15786.24,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "KNA1995",
        "yCap": 16421.39,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "KNA1996",
        "yCap": 17160.27,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "KNA1997",
        "yCap": 18096.55,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "KNA1998",
        "yCap": 17774.76,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "KNA1999",
        "yCap": 18103.55,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "KNA2000",
        "yCap": 19642.95,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "KNA2001",
        "yCap": 20438.85,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "KNA2002",
        "yCap": 20593.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "KNA2003",
        "yCap": 19712.7,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "KNA2004",
        "yCap": 20434.91,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "KNA2005",
        "yCap": 22354.22,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "KNA2006",
        "yCap": 22985.17,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "KNA2007",
        "yCap": 23053.87,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "KNA2008",
        "yCap": 25554.31,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "KNA2009",
        "yCap": 24622.87,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "KNA2010",
        "yCap": 24572.83,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "KNA2011",
        "yCap": 24876.7,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "KNA2012",
        "yCap": 24672.4,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "KNA2013",
        "yCap": 26058.27,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "KNA2014",
        "yCap": 28019.89,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "KNA2015",
        "yCap": 28219.11,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "KNA2016",
        "yCap": 29329.48,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "KNA2017",
        "yCap": 29333.39,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "KNA2018",
        "yCap": 29950.49,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "KNA2019",
        "yCap": 31194.74,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "KNA2020",
        "yCap": 26700.67,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "KNA2021",
        "yCap": 26485.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Kitts and Nevis",
        "Alpha-3": "KNA",
        "Alpha-2": "KN",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "KNA2022",
        "yCap": 26485.82,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "LCA1990",
        "yCap": 11419.1,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "LCA1991",
        "yCap": 11305.72,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "LCA1992",
        "yCap": 12025.49,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "LCA1993",
        "yCap": 11918.58,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "LCA1994",
        "yCap": 11950.99,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "LCA1995",
        "yCap": 12017.29,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "LCA1996",
        "yCap": 12238.29,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "LCA1997",
        "yCap": 12037.24,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "LCA1998",
        "yCap": 12679.68,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "LCA1999",
        "yCap": 12903.06,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "LCA2000",
        "yCap": 12808.67,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "LCA2001",
        "yCap": 12287.86,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "LCA2002",
        "yCap": 12247.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "LCA2003",
        "yCap": 12671.69,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "LCA2004",
        "yCap": 13493.94,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "LCA2005",
        "yCap": 13345.37,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "LCA2006",
        "yCap": 14079.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "LCA2007",
        "yCap": 14228.47,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "LCA2008",
        "yCap": 14838.96,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "LCA2009",
        "yCap": 14324.69,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "LCA2010",
        "yCap": 14448.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "LCA2011",
        "yCap": 15133.4,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "LCA2012",
        "yCap": 15017.75,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "LCA2013",
        "yCap": 14469.65,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "LCA2014",
        "yCap": 14605.22,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "LCA2015",
        "yCap": 14339.11,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "LCA2016",
        "yCap": 14810.64,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "LCA2017",
        "yCap": 15247.46,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "LCA2018",
        "yCap": 15622.78,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "LCA2019",
        "yCap": 15460.8,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "LCA2020",
        "yCap": 11651.17,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "LCA2021",
        "yCap": 13045.96,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Lucia",
        "Alpha-3": "LCA",
        "Alpha-2": "LC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "LCA2022",
        "yCap": 13045.96,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "MAF1990",
        "yCap": 14458,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "MAF1991",
        "yCap": 14562.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "MAF1992",
        "yCap": 14756.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "MAF1993",
        "yCap": 14965.32,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "MAF1994",
        "yCap": 15230.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "MAF1995",
        "yCap": 15335.78,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "MAF1996",
        "yCap": 15545.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "MAF1997",
        "yCap": 15961.58,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "MAF1998",
        "yCap": 16132.18,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "MAF1999",
        "yCap": 16282.35,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "MAF2000",
        "yCap": 16566.14,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "MAF2001",
        "yCap": 16630.31,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "MAF2002",
        "yCap": 16649.59,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "MAF2003",
        "yCap": 16884.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "MAF2004",
        "yCap": 17359.15,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "MAF2005",
        "yCap": 17714.1,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "MAF2006",
        "yCap": 18214.89,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "MAF2007",
        "yCap": 18690.28,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "MAF2008",
        "yCap": 18915.13,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "MAF2009",
        "yCap": 18152.51,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "MAF2010",
        "yCap": 18305.2,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "MAF2011",
        "yCap": 18677.9,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "MAF2012",
        "yCap": 18833.91,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "MAF2013",
        "yCap": 19012.88,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "MAF2014",
        "yCap": 19274.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "MAF2015",
        "yCap": 19451.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "MAF2016",
        "yCap": 19564.52,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "MAF2017",
        "yCap": 19627.35,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "MAF2018",
        "yCap": 19828.33,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "MAF2019",
        "yCap": 19983.88,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "MAF2020",
        "yCap": 17800.85,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "MAF2021",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Martin (French part)",
        "Alpha-3": "MAF",
        "Alpha-2": "MF",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "MAF2022",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "VCT1990",
        "yCap": 6499.78,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "VCT1991",
        "yCap": 6556.51,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "VCT1992",
        "yCap": 6947.45,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "VCT1993",
        "yCap": 7217.33,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "VCT1994",
        "yCap": 7107.08,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "VCT1995",
        "yCap": 7643.64,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "VCT1996",
        "yCap": 7734.22,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "VCT1997",
        "yCap": 8006.3,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "VCT1998",
        "yCap": 8341.88,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "VCT1999",
        "yCap": 8580.74,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "VCT2000",
        "yCap": 8735.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "VCT2001",
        "yCap": 8901.5,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "VCT2002",
        "yCap": 9392.81,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "VCT2003",
        "yCap": 10051.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "VCT2004",
        "yCap": 10511.68,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "VCT2005",
        "yCap": 10827.6,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "VCT2006",
        "yCap": 11649.24,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "VCT2007",
        "yCap": 12101.83,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "VCT2008",
        "yCap": 12206.76,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "VCT2009",
        "yCap": 12090.69,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "VCT2010",
        "yCap": 11606.4,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "VCT2011",
        "yCap": 11600.24,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "VCT2012",
        "yCap": 11803.18,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "VCT2013",
        "yCap": 12165.07,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "VCT2014",
        "yCap": 12366.37,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "VCT2015",
        "yCap": 12762.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "VCT2016",
        "yCap": 13356.81,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "VCT2017",
        "yCap": 13633.05,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "VCT2018",
        "yCap": 14084.94,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "VCT2019",
        "yCap": 14189.96,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "VCT2020",
        "yCap": 13473.59,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "VCT2021",
        "yCap": 13695.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "St. Vincent and the Grenadines",
        "Alpha-3": "VCT",
        "Alpha-2": "VC",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "VCT2022",
        "yCap": 13695.04,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "SDN1990",
        "yCap": 2756.53,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "SDN1991",
        "yCap": 2909.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "SDN1992",
        "yCap": 3052.74,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "SDN1993",
        "yCap": 3140.03,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "SDN1994",
        "yCap": 3095.87,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "SDN1995",
        "yCap": 3195.9,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "SDN1996",
        "yCap": 3303.67,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "SDN1997",
        "yCap": 3811.52,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "SDN1998",
        "yCap": 3887.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "SDN1999",
        "yCap": 3911.6,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "SDN2000",
        "yCap": 4040.61,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "SDN2001",
        "yCap": 4183.43,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "SDN2002",
        "yCap": 4316.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "SDN2003",
        "yCap": 4467.12,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "SDN2004",
        "yCap": 4571.09,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "SDN2005",
        "yCap": 4692.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "SDN2006",
        "yCap": 4847.59,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "SDN2007",
        "yCap": 4964.31,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "SDN2008",
        "yCap": 4993.87,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "SDN2009",
        "yCap": 4707.35,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "SDN2010",
        "yCap": 4745.3,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "SDN2011",
        "yCap": 5047.33,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "SDN2012",
        "yCap": 4711.06,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "SDN2013",
        "yCap": 4692.28,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "SDN2014",
        "yCap": 4776.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "SDN2015",
        "yCap": 4718.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "SDN2016",
        "yCap": 4733.02,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "SDN2017",
        "yCap": 4613.95,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "SDN2018",
        "yCap": 4349.21,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "SDN2019",
        "yCap": 4133.13,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "SDN2020",
        "yCap": 3874.8,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "SDN2021",
        "yCap": 3701.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sudan",
        "Alpha-3": "SDN",
        "Alpha-2": "SD",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "SDN2022",
        "yCap": 3701.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1990,
        "ID": "SUR1990",
        "yCap": 13633.75,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1991,
        "ID": "SUR1991",
        "yCap": 13893.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1992,
        "ID": "SUR1992",
        "yCap": 13831.18,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1993,
        "ID": "SUR1993",
        "yCap": 12817.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1994,
        "ID": "SUR1994",
        "yCap": 13123.42,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1995,
        "ID": "SUR1995",
        "yCap": 12883.54,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1996,
        "ID": "SUR1996",
        "yCap": 12738.43,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1997,
        "ID": "SUR1997",
        "yCap": 13195.37,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1998,
        "ID": "SUR1998",
        "yCap": 13239.79,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 1999,
        "ID": "SUR1999",
        "yCap": 12812.77,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2000,
        "ID": "SUR2000",
        "yCap": 12848.61,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2001,
        "ID": "SUR2001",
        "yCap": 13157.62,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2002,
        "ID": "SUR2002",
        "yCap": 13300.3,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2003,
        "ID": "SUR2003",
        "yCap": 13910.51,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2004,
        "ID": "SUR2004",
        "yCap": 14892.12,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2005,
        "ID": "SUR2005",
        "yCap": 15392,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2006,
        "ID": "SUR2006",
        "yCap": 16102.73,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2007,
        "ID": "SUR2007",
        "yCap": 16735.92,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2008,
        "ID": "SUR2008",
        "yCap": 17233.76,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2009,
        "ID": "SUR2009",
        "yCap": 17554.27,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2010,
        "ID": "SUR2010",
        "yCap": 18255.63,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2011,
        "ID": "SUR2011",
        "yCap": 19111.17,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2012,
        "ID": "SUR2012",
        "yCap": 19415.58,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2013,
        "ID": "SUR2013",
        "yCap": 19778.31,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2014,
        "ID": "SUR2014",
        "yCap": 19629.22,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2015,
        "ID": "SUR2015",
        "yCap": 18768.79,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2016,
        "ID": "SUR2016",
        "yCap": 17662.68,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2017,
        "ID": "SUR2017",
        "yCap": 17753.06,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2018,
        "ID": "SUR2018",
        "yCap": 18438.35,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2019,
        "ID": "SUR2019",
        "yCap": 18449.2,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2020,
        "ID": "SUR2020",
        "yCap": 15329.18,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2021,
        "ID": "SUR2021",
        "yCap": 14766.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Suriname",
        "Alpha-3": "SUR",
        "Alpha-2": "SR",
        "Region": "LAC",
        "IncGroup": "UpMid",
        "RegIncGrp": "LAC-UpMid",
        "Time": 2022,
        "ID": "SUR2022",
        "yCap": 14766.75,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "SWE1990",
        "yCap": 34156.82,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "SWE1991",
        "yCap": 33536.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "SWE1992",
        "yCap": 32953.62,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "SWE1993",
        "yCap": 32086.01,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "SWE1994",
        "yCap": 33110.83,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "SWE1995",
        "yCap": 34233.7,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "SWE1996",
        "yCap": 34719.11,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "SWE1997",
        "yCap": 35764.68,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "SWE1998",
        "yCap": 37286.1,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "SWE1999",
        "yCap": 38839.43,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "SWE2000",
        "yCap": 40625.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "SWE2001",
        "yCap": 41103.73,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "SWE2002",
        "yCap": 41870.26,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "SWE2003",
        "yCap": 42678.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "SWE2004",
        "yCap": 44354.39,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "SWE2005",
        "yCap": 45440.3,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "SWE2006",
        "yCap": 47292.31,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "SWE2007",
        "yCap": 48557.38,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "SWE2008",
        "yCap": 47963.49,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "SWE2009",
        "yCap": 45492.77,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "SWE2010",
        "yCap": 47791.37,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "SWE2011",
        "yCap": 48947.44,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "SWE2012",
        "yCap": 48300.84,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "SWE2013",
        "yCap": 48462.16,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "SWE2014",
        "yCap": 49259,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "SWE2015",
        "yCap": 50928.96,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "SWE2016",
        "yCap": 51334.43,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "SWE2017",
        "yCap": 51947.95,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "SWE2018",
        "yCap": 52349.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "SWE2019",
        "yCap": 52850.57,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "SWE2020",
        "yCap": 51331.28,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "SWE2021",
        "yCap": 53613.42,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Sweden",
        "Alpha-3": "SWE",
        "Alpha-2": "SE",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "SWE2022",
        "yCap": 53613.42,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "CHE1990",
        "yCap": 56982.46,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "CHE1991",
        "yCap": 55759.34,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "CHE1992",
        "yCap": 55123.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "CHE1993",
        "yCap": 54555.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "CHE1994",
        "yCap": 54809.34,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "CHE1995",
        "yCap": 54706.1,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "CHE1996",
        "yCap": 54721.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "CHE1997",
        "yCap": 55824.51,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "CHE1998",
        "yCap": 57345.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "CHE1999",
        "yCap": 58021.44,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "CHE2000",
        "yCap": 59980.33,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "CHE2001",
        "yCap": 60541.12,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "CHE2002",
        "yCap": 60040.88,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "CHE2003",
        "yCap": 59577.76,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "CHE2004",
        "yCap": 60768.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "CHE2005",
        "yCap": 62040.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "CHE2006",
        "yCap": 64163.81,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "CHE2007",
        "yCap": 66083.47,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "CHE2008",
        "yCap": 67081.3,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "CHE2009",
        "yCap": 64726.37,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "CHE2010",
        "yCap": 66133.29,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "CHE2011",
        "yCap": 66587.85,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "CHE2012",
        "yCap": 66661.49,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "CHE2013",
        "yCap": 67080.37,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "CHE2014",
        "yCap": 67824.08,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "CHE2015",
        "yCap": 68159.22,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "CHE2016",
        "yCap": 68813.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "CHE2017",
        "yCap": 69103.57,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "CHE2018",
        "yCap": 70558.56,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "CHE2019",
        "yCap": 70857.09,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "CHE2020",
        "yCap": 68670.24,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "CHE2021",
        "yCap": 71032.84,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Switzerland",
        "Alpha-3": "CHE",
        "Alpha-2": "CH",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "CHE2022",
        "yCap": 71032.84,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1990,
        "ID": "SYR1990",
        "yCap": 24642.33,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1991,
        "ID": "SYR1991",
        "yCap": 24723.38,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1992,
        "ID": "SYR1992",
        "yCap": 24934.53,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1993,
        "ID": "SYR1993",
        "yCap": 25624.77,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1994,
        "ID": "SYR1994",
        "yCap": 25949.74,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1995,
        "ID": "SYR1995",
        "yCap": 26291.17,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1996,
        "ID": "SYR1996",
        "yCap": 26484.79,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1997,
        "ID": "SYR1997",
        "yCap": 26708.7,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1998,
        "ID": "SYR1998",
        "yCap": 26735.63,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1999,
        "ID": "SYR1999",
        "yCap": 26463.14,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2000,
        "ID": "SYR2000",
        "yCap": 27399.98,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2001,
        "ID": "SYR2001",
        "yCap": 26975.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2002,
        "ID": "SYR2002",
        "yCap": 26770.58,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2003,
        "ID": "SYR2003",
        "yCap": 27640.96,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2004,
        "ID": "SYR2004",
        "yCap": 29212.73,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2005,
        "ID": "SYR2005",
        "yCap": 29624.75,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2006,
        "ID": "SYR2006",
        "yCap": 30012.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2007,
        "ID": "SYR2007",
        "yCap": 29623.53,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2008,
        "ID": "SYR2008",
        "yCap": 29336.44,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2009,
        "ID": "SYR2009",
        "yCap": 28125.51,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2010,
        "ID": "SYR2010",
        "yCap": 28673.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2011,
        "ID": "SYR2011",
        "yCap": 28706.29,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2012,
        "ID": "SYR2012",
        "yCap": 29787.76,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2013,
        "ID": "SYR2013",
        "yCap": 29408.97,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2014,
        "ID": "SYR2014",
        "yCap": 29010.77,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2015,
        "ID": "SYR2015",
        "yCap": 29022.2,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2016,
        "ID": "SYR2016",
        "yCap": 29159.08,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2017,
        "ID": "SYR2017",
        "yCap": 29138.23,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2018,
        "ID": "SYR2018",
        "yCap": 29259.79,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2019,
        "ID": "SYR2019",
        "yCap": 29025.99,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2020,
        "ID": "SYR2020",
        "yCap": 27251.16,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2021,
        "ID": "SYR2021",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Syrian Arab Republic",
        "Alpha-3": "SYR",
        "Alpha-2": "SY",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2022,
        "ID": "SYR2022",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1990,
        "ID": "TJK1990",
        "yCap": 3990.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1991,
        "ID": "TJK1991",
        "yCap": 3615.17,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1992,
        "ID": "TJK1992",
        "yCap": 2521.43,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1993,
        "ID": "TJK1993",
        "yCap": 2085.21,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1994,
        "ID": "TJK1994",
        "yCap": 1616.69,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1995,
        "ID": "TJK1995",
        "yCap": 1389.11,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1996,
        "ID": "TJK1996",
        "yCap": 1134.23,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1997,
        "ID": "TJK1997",
        "yCap": 1137.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1998,
        "ID": "TJK1998",
        "yCap": 1190.66,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1999,
        "ID": "TJK1999",
        "yCap": 1228.17,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2000,
        "ID": "TJK2000",
        "yCap": 1312.71,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2001,
        "ID": "TJK2001",
        "yCap": 1408,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2002,
        "ID": "TJK2002",
        "yCap": 1528.36,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2003,
        "ID": "TJK2003",
        "yCap": 1663.24,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2004,
        "ID": "TJK2004",
        "yCap": 1799.83,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2005,
        "ID": "TJK2005",
        "yCap": 1884.96,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2006,
        "ID": "TJK2006",
        "yCap": 1980.25,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2007,
        "ID": "TJK2007",
        "yCap": 2095.81,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2008,
        "ID": "TJK2008",
        "yCap": 2219.32,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2009,
        "ID": "TJK2009",
        "yCap": 2261.43,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2010,
        "ID": "TJK2010",
        "yCap": 2360.01,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2011,
        "ID": "TJK2011",
        "yCap": 2481.57,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2012,
        "ID": "TJK2012",
        "yCap": 2610.17,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2013,
        "ID": "TJK2013",
        "yCap": 2741.22,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2014,
        "ID": "TJK2014",
        "yCap": 2858.24,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2015,
        "ID": "TJK2015",
        "yCap": 2959.99,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2016,
        "ID": "TJK2016",
        "yCap": 3091.23,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2017,
        "ID": "TJK2017",
        "yCap": 3236.44,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2018,
        "ID": "TJK2018",
        "yCap": 3405.09,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2019,
        "ID": "TJK2019",
        "yCap": 3575.28,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2020,
        "ID": "TJK2020",
        "yCap": 3651.94,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2021,
        "ID": "TJK2021",
        "yCap": 3903.32,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tajikistan",
        "Alpha-3": "TJK",
        "Alpha-2": "TJ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2022,
        "ID": "TJK2022",
        "yCap": 3903.32,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "TZA1990",
        "yCap": 1338.98,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "TZA1991",
        "yCap": 1332,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "TZA1992",
        "yCap": 1306.36,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "TZA1993",
        "yCap": 1280.95,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "TZA1994",
        "yCap": 1251.47,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "TZA1995",
        "yCap": 1255.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "TZA1996",
        "yCap": 1288.1,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "TZA1997",
        "yCap": 1306.54,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "TZA1998",
        "yCap": 1320.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "TZA1999",
        "yCap": 1348.38,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "TZA2000",
        "yCap": 1370.01,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "TZA2001",
        "yCap": 1414.26,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "TZA2002",
        "yCap": 1475.55,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "TZA2003",
        "yCap": 1532.76,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "TZA2004",
        "yCap": 1603.75,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "TZA2005",
        "yCap": 1676.6,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "TZA2006",
        "yCap": 1736.77,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "TZA2007",
        "yCap": 1803.11,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "TZA2008",
        "yCap": 1854.42,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "TZA2009",
        "yCap": 1903.96,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "TZA2010",
        "yCap": 1972.98,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "TZA2011",
        "yCap": 2064.71,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "TZA2012",
        "yCap": 2095.87,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "TZA2013",
        "yCap": 2171.44,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "TZA2014",
        "yCap": 2246.56,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "TZA2015",
        "yCap": 2306.64,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "TZA2016",
        "yCap": 2380.93,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "TZA2017",
        "yCap": 2458.34,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "TZA2018",
        "yCap": 2510.97,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "TZA2019",
        "yCap": 2577.67,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "TZA2020",
        "yCap": 2551.21,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "TZA2021",
        "yCap": 2581.7,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tanzania",
        "Alpha-3": "TZA",
        "Alpha-2": "TZ",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "TZA2022",
        "yCap": 2581.7,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "THA1990",
        "yCap": 7273.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "THA1991",
        "yCap": 7772.86,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "THA1992",
        "yCap": 8277.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "THA1993",
        "yCap": 8830.58,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "THA1994",
        "yCap": 9401.07,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "THA1995",
        "yCap": 10025.09,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "THA1996",
        "yCap": 10453.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "THA1997",
        "yCap": 10035.87,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "THA1998",
        "yCap": 9156.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "THA1999",
        "yCap": 9467.92,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "THA2000",
        "yCap": 9791.9,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "THA2001",
        "yCap": 10036.33,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "THA2002",
        "yCap": 10558.47,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "THA2003",
        "yCap": 11220.68,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "THA2004",
        "yCap": 11828.84,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "THA2005",
        "yCap": 12228.66,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "THA2006",
        "yCap": 12739.74,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "THA2007",
        "yCap": 13330.21,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "THA2008",
        "yCap": 13459.25,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "THA2009",
        "yCap": 13270.62,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "THA2010",
        "yCap": 14172.22,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "THA2011",
        "yCap": 14199.28,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "THA2012",
        "yCap": 15129.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "THA2013",
        "yCap": 15442.38,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "THA2014",
        "yCap": 15509.18,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "THA2015",
        "yCap": 15919.37,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "THA2016",
        "yCap": 16393.31,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "THA2017",
        "yCap": 17008.04,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "THA2018",
        "yCap": 17669.05,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "THA2019",
        "yCap": 18003.67,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "THA2020",
        "yCap": 16848.58,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "THA2021",
        "yCap": 17077.04,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Thailand",
        "Alpha-3": "THA",
        "Alpha-2": "TH",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "THA2022",
        "yCap": 17077.04,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 3.75
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "TLS1990",
        "yCap": 2314.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "TLS1991",
        "yCap": 2314.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "TLS1992",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "TLS1993",
        "yCap": 2314.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "TLS1994",
        "yCap": 2314.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "TLS1995",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "TLS1996",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "TLS1997",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "TLS1998",
        "yCap": 2314.81,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "TLS1999",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "TLS2000",
        "yCap": 2314.81,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "TLS2001",
        "yCap": 2649.07,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "TLS2002",
        "yCap": 2426.36,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "TLS2003",
        "yCap": 2329.66,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "TLS2004",
        "yCap": 2291.34,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "TLS2005",
        "yCap": 2303.01,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "TLS2006",
        "yCap": 2152.08,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "TLS2007",
        "yCap": 2315.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "TLS2008",
        "yCap": 2520.29,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "TLS2009",
        "yCap": 2717.44,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "TLS2010",
        "yCap": 2908.33,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "TLS2011",
        "yCap": 3013.74,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "TLS2012",
        "yCap": 3091.56,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "TLS2013",
        "yCap": 3121.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "TLS2014",
        "yCap": 3197.5,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "TLS2015",
        "yCap": 3228.68,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "TLS2016",
        "yCap": 3286.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "TLS2017",
        "yCap": 3138.44,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "TLS2018",
        "yCap": 3070.81,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 2.5
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "TLS2019",
        "yCap": 3737.8,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "TLS2020",
        "yCap": 4856.52,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "TLS2021",
        "yCap": 5032.41,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Timor-Leste",
        "Alpha-3": "TLS",
        "Alpha-2": "TL",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "TLS2022",
        "yCap": 5032.41,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "TGO1990",
        "yCap": 1740.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "TGO1991",
        "yCap": 1678.64,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "TGO1992",
        "yCap": 1566.28,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "TGO1993",
        "yCap": 1338.91,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "TGO1994",
        "yCap": 1534.17,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "TGO1995",
        "yCap": 1582.26,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "TGO1996",
        "yCap": 1657.65,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "TGO1997",
        "yCap": 1835.42,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "TGO1998",
        "yCap": 1741.73,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "TGO1999",
        "yCap": 1733.75,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "TGO2000",
        "yCap": 1672.05,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "TGO2001",
        "yCap": 1640.8,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "TGO2002",
        "yCap": 1659.68,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "TGO2003",
        "yCap": 1725.65,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "TGO2004",
        "yCap": 1664.48,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "TGO2005",
        "yCap": 1546.14,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "TGO2006",
        "yCap": 1543.18,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "TGO2007",
        "yCap": 1481.34,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "TGO2008",
        "yCap": 1498.18,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "TGO2009",
        "yCap": 1537.62,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "TGO2010",
        "yCap": 1588.4,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "TGO2011",
        "yCap": 1645.75,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "TGO2012",
        "yCap": 1708.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "TGO2013",
        "yCap": 1767,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "TGO2014",
        "yCap": 1824.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "TGO2015",
        "yCap": 1881.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "TGO2016",
        "yCap": 1937.74,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "TGO2017",
        "yCap": 1972.7,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "TGO2018",
        "yCap": 2020.97,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "TGO2019",
        "yCap": 2080.52,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "TGO2020",
        "yCap": 2066.97,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "TGO2021",
        "yCap": 2124.8,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Togo",
        "Alpha-3": "TGO",
        "Alpha-2": "TG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "TGO2022",
        "yCap": 2124.8,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "TON1990",
        "yCap": 3625.44,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "TON1991",
        "yCap": 3846.98,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "TON1992",
        "yCap": 3846.96,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "TON1993",
        "yCap": 3981.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "TON1994",
        "yCap": 4168.62,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "TON1995",
        "yCap": 4465.19,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "TON1996",
        "yCap": 4535.92,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "TON1997",
        "yCap": 4570.3,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "TON1998",
        "yCap": 4651.02,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "TON1999",
        "yCap": 4793.39,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "TON2000",
        "yCap": 4806.11,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "TON2001",
        "yCap": 4956.97,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "TON2002",
        "yCap": 5167.54,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "TON2003",
        "yCap": 5256.12,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "TON2004",
        "yCap": 5098.84,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "TON2005",
        "yCap": 5074.13,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "TON2006",
        "yCap": 5040.02,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "TON2007",
        "yCap": 5032.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "TON2008",
        "yCap": 5257.01,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "TON2009",
        "yCap": 4973.83,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "TON2010",
        "yCap": 5002.63,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "TON2011",
        "yCap": 5332.4,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "TON2012",
        "yCap": 5381.75,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "TON2013",
        "yCap": 5419.37,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "TON2014",
        "yCap": 5552.78,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "TON2015",
        "yCap": 5644.54,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "TON2016",
        "yCap": 6039.05,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "TON2017",
        "yCap": 6256.98,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "TON2018",
        "yCap": 6287.88,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "TON2019",
        "yCap": 6344.31,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "TON2020",
        "yCap": 6356.96,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "TON2021",
        "yCap": 6142.86,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tonga",
        "Alpha-3": "TON",
        "Alpha-2": "TO",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "TON2022",
        "yCap": 6142.86,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "TTO1990",
        "yCap": 9766.82,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "TTO1991",
        "yCap": 9994.21,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "TTO1992",
        "yCap": 10923.51,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "TTO1993",
        "yCap": 10779.38,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "TTO1994",
        "yCap": 11100.26,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "TTO1995",
        "yCap": 11466.52,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "TTO1996",
        "yCap": 12232.11,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "TTO1997",
        "yCap": 13105.05,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "TTO1998",
        "yCap": 14122.62,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "TTO1999",
        "yCap": 15204.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "TTO2000",
        "yCap": 16190.9,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "TTO2001",
        "yCap": 16785.64,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "TTO2002",
        "yCap": 18018.3,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "TTO2003",
        "yCap": 20504.78,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "TTO2004",
        "yCap": 22010.94,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "TTO2005",
        "yCap": 23242.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "TTO2006",
        "yCap": 26162.64,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "TTO2007",
        "yCap": 27249.3,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "TTO2008",
        "yCap": 28012.87,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "TTO2009",
        "yCap": 26622.29,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "TTO2010",
        "yCap": 27329.42,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "TTO2011",
        "yCap": 27062.38,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "TTO2012",
        "yCap": 28642.34,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "TTO2013",
        "yCap": 29529.58,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "TTO2014",
        "yCap": 30301.93,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "TTO2015",
        "yCap": 29876.98,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "TTO2016",
        "yCap": 27682.45,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "TTO2017",
        "yCap": 26213.92,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "TTO2018",
        "yCap": 25534.84,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "TTO2019",
        "yCap": 25306.62,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "TTO2020",
        "yCap": 23391.32,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "TTO2021",
        "yCap": 23037.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Trinidad and Tobago",
        "Alpha-3": "TTO",
        "Alpha-2": "TT",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "TTO2022",
        "yCap": 23037.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "TUN1990",
        "yCap": 5659.91,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "TUN1991",
        "yCap": 5756.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "TUN1992",
        "yCap": 6078.85,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "TUN1993",
        "yCap": 6091.12,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "TUN1994",
        "yCap": 6170.64,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "TUN1995",
        "yCap": 6213.17,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "TUN1996",
        "yCap": 6560.85,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "TUN1997",
        "yCap": 6825.61,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "TUN1998",
        "yCap": 7064.04,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "TUN1999",
        "yCap": 7406.84,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "TUN2000",
        "yCap": 7673.19,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "TUN2001",
        "yCap": 7883.37,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "TUN2002",
        "yCap": 7908.94,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "TUN2003",
        "yCap": 8200.24,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "TUN2004",
        "yCap": 8628.28,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "TUN2005",
        "yCap": 8846.49,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "TUN2006",
        "yCap": 9225.85,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "TUN2007",
        "yCap": 9754.76,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "TUN2008",
        "yCap": 10072.95,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "TUN2009",
        "yCap": 10279.3,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "TUN2010",
        "yCap": 10477.3,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "TUN2011",
        "yCap": 10135,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "TUN2012",
        "yCap": 10428.27,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "TUN2013",
        "yCap": 10562.66,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "TUN2014",
        "yCap": 10766.5,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "TUN2015",
        "yCap": 10749.49,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "TUN2016",
        "yCap": 10750.71,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "TUN2017",
        "yCap": 10874.95,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "TUN2018",
        "yCap": 11042.96,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "TUN2019",
        "yCap": 11100.82,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "TUN2020",
        "yCap": 10050.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "TUN2021",
        "yCap": 10397.94,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tunisia",
        "Alpha-3": "TUN",
        "Alpha-2": "TN",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "TUN2022",
        "yCap": 10397.94,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "TUR1990",
        "yCap": 12535.92,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "TUR1991",
        "yCap": 12398.66,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "TUR1992",
        "yCap": 12796.13,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "TUR1993",
        "yCap": 13536.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "TUR1994",
        "yCap": 12679.87,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "TUR1995",
        "yCap": 13449.28,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "TUR1996",
        "yCap": 14205.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "TUR1997",
        "yCap": 15036.18,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "TUR1998",
        "yCap": 15159,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "TUR1999",
        "yCap": 14445.39,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "TUR2000",
        "yCap": 15223.35,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "TUR2001",
        "yCap": 14136.67,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "TUR2002",
        "yCap": 14839.13,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "TUR2003",
        "yCap": 15488.11,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "TUR2004",
        "yCap": 16775.08,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "TUR2005",
        "yCap": 18038.81,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "TUR2006",
        "yCap": 19043.62,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "TUR2007",
        "yCap": 19757.82,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "TUR2008",
        "yCap": 19680.94,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "TUR2009",
        "yCap": 18497.01,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "TUR2010",
        "yCap": 19790.07,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "TUR2011",
        "yCap": 21716.27,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "TUR2012",
        "yCap": 22422.54,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "TUR2013",
        "yCap": 23912.73,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "TUR2014",
        "yCap": 24600.52,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "TUR2015",
        "yCap": 25594.66,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "TUR2016",
        "yCap": 25996.97,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "TUR2017",
        "yCap": 27582.83,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "TUR2018",
        "yCap": 28157.98,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "TUR2019",
        "yCap": 28150.06,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "TUR2020",
        "yCap": 28473.21,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "TUR2021",
        "yCap": 31466.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turkiye",
        "Alpha-3": "TUR",
        "Alpha-2": "TR",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "TUR2022",
        "yCap": 31466.56,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1990,
        "ID": "TKM1990",
        "yCap": 7137.6,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1991,
        "ID": "TKM1991",
        "yCap": 6628.06,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1992,
        "ID": "TKM1992",
        "yCap": 5485.97,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1993,
        "ID": "TKM1993",
        "yCap": 5422.79,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1994,
        "ID": "TKM1994",
        "yCap": 4374.7,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1995,
        "ID": "TKM1995",
        "yCap": 3969.83,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1996,
        "ID": "TKM1996",
        "yCap": 4162.46,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1997,
        "ID": "TKM1997",
        "yCap": 3635.06,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1998,
        "ID": "TKM1998",
        "yCap": 3833.54,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 1999,
        "ID": "TKM1999",
        "yCap": 4397.05,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2000,
        "ID": "TKM2000",
        "yCap": 4568.46,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2001,
        "ID": "TKM2001",
        "yCap": 4699.07,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2002,
        "ID": "TKM2002",
        "yCap": 4647.11,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2003,
        "ID": "TKM2003",
        "yCap": 4738.5,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2004,
        "ID": "TKM2004",
        "yCap": 4912.67,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2005,
        "ID": "TKM2005",
        "yCap": 5478,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2006,
        "ID": "TKM2006",
        "yCap": 5995.36,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2007,
        "ID": "TKM2007",
        "yCap": 6564.5,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2008,
        "ID": "TKM2008",
        "yCap": 7418.48,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2009,
        "ID": "TKM2009",
        "yCap": 7748.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2010,
        "ID": "TKM2010",
        "yCap": 8321.22,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2011,
        "ID": "TKM2011",
        "yCap": 9379.14,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2012,
        "ID": "TKM2012",
        "yCap": 10233.4,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2013,
        "ID": "TKM2013",
        "yCap": 11071.52,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2014,
        "ID": "TKM2014",
        "yCap": 11989.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2015,
        "ID": "TKM2015",
        "yCap": 12540.28,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2016,
        "ID": "TKM2016",
        "yCap": 13086.01,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2017,
        "ID": "TKM2017",
        "yCap": 13703.51,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2018,
        "ID": "TKM2018",
        "yCap": 14321.14,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2019,
        "ID": "TKM2019",
        "yCap": 14992.6,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2020,
        "ID": "TKM2020",
        "yCap": 14992.6,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2021,
        "ID": "TKM2021",
        "yCap": 14992.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turkmenistan",
        "Alpha-3": "TKM",
        "Alpha-2": "TM",
        "Region": "ECA",
        "IncGroup": "UpMid",
        "RegIncGrp": "ECA-UpMid",
        "Time": 2022,
        "ID": "TKM2022",
        "yCap": 14992.6,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "TCA1990",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "TCA1991",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "TCA1992",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "TCA1993",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "TCA1994",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "TCA1995",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "TCA1996",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "TCA1997",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "TCA1998",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "TCA1999",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "TCA2000",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "TCA2001",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "TCA2002",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "TCA2003",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "TCA2004",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "TCA2005",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "TCA2006",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "TCA2007",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "TCA2008",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "TCA2009",
        "yCap": 25731.28,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "TCA2010",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "TCA2011",
        "yCap": 25731.28,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "TCA2012",
        "yCap": 24476.34,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "TCA2013",
        "yCap": 23624.29,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "TCA2014",
        "yCap": 24661.15,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "TCA2015",
        "yCap": 26284.72,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "TCA2016",
        "yCap": 26931.73,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "TCA2017",
        "yCap": 25207.83,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "TCA2018",
        "yCap": 25567.92,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "TCA2019",
        "yCap": 25933.51,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "TCA2020",
        "yCap": 18474.87,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "TCA2021",
        "yCap": 18512.46,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Turks and Caicos Islands",
        "Alpha-3": "TCA",
        "Alpha-2": "TC",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "TCA2022",
        "yCap": 18512.46,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1990,
        "ID": "TUV1990",
        "yCap": 2703.25,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1991,
        "ID": "TUV1991",
        "yCap": 2749.31,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1992,
        "ID": "TUV1992",
        "yCap": 2792.55,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1993,
        "ID": "TUV1993",
        "yCap": 2891.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1994,
        "ID": "TUV1994",
        "yCap": 3175.12,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1995,
        "ID": "TUV1995",
        "yCap": 3008.04,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1996,
        "ID": "TUV1996",
        "yCap": 2821.07,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1997,
        "ID": "TUV1997",
        "yCap": 3097.15,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1998,
        "ID": "TUV1998",
        "yCap": 3575.76,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 1999,
        "ID": "TUV1999",
        "yCap": 3517.65,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2000,
        "ID": "TUV2000",
        "yCap": 3484.23,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2001,
        "ID": "TUV2001",
        "yCap": 3490.39,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2002,
        "ID": "TUV2002",
        "yCap": 3827.58,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2003,
        "ID": "TUV2003",
        "yCap": 3638.82,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2004,
        "ID": "TUV2004",
        "yCap": 3511.44,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2005,
        "ID": "TUV2005",
        "yCap": 3307.25,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2006,
        "ID": "TUV2006",
        "yCap": 3348.06,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2007,
        "ID": "TUV2007",
        "yCap": 3545.14,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2008,
        "ID": "TUV2008",
        "yCap": 3736.2,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2009,
        "ID": "TUV2009",
        "yCap": 3456.92,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2010,
        "ID": "TUV2010",
        "yCap": 3334.61,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2011,
        "ID": "TUV2011",
        "yCap": 3512.03,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2012,
        "ID": "TUV2012",
        "yCap": 3388.54,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2013,
        "ID": "TUV2013",
        "yCap": 3515.14,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2014,
        "ID": "TUV2014",
        "yCap": 3521.27,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2015,
        "ID": "TUV2015",
        "yCap": 3895.93,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2016,
        "ID": "TUV2016",
        "yCap": 4125.94,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2017,
        "ID": "TUV2017",
        "yCap": 4208.92,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2018,
        "ID": "TUV2018",
        "yCap": 4268.18,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2019,
        "ID": "TUV2019",
        "yCap": 4816.55,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2020,
        "ID": "TUV2020",
        "yCap": 4839.62,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2021,
        "ID": "TUV2021",
        "yCap": 4924.03,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Tuvalu",
        "Alpha-3": "TUV",
        "Alpha-2": "TV",
        "Region": "EAP",
        "IncGroup": "UpMid",
        "RegIncGrp": "EAP-UpMid",
        "Time": 2022,
        "ID": "TUV2022",
        "yCap": 4924.03,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 3.75,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "UGA1990",
        "yCap": 901.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "UGA1991",
        "yCap": 921.09,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "UGA1992",
        "yCap": 920.65,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "UGA1993",
        "yCap": 963.44,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "UGA1994",
        "yCap": 991.41,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "UGA1995",
        "yCap": 1075.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "UGA1996",
        "yCap": 1142.14,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "UGA1997",
        "yCap": 1165.96,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "UGA1998",
        "yCap": 1187.3,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "UGA1999",
        "yCap": 1242.05,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "UGA2000",
        "yCap": 1241.53,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "UGA2001",
        "yCap": 1266.73,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "UGA2002",
        "yCap": 1335.2,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "UGA2003",
        "yCap": 1377.95,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "UGA2004",
        "yCap": 1428.85,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "UGA2005",
        "yCap": 1475.81,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "UGA2006",
        "yCap": 1588,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "UGA2007",
        "yCap": 1671.82,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "UGA2008",
        "yCap": 1764.99,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "UGA2009",
        "yCap": 1830.87,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "UGA2010",
        "yCap": 1878.52,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "UGA2011",
        "yCap": 1996.07,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "UGA2012",
        "yCap": 2013.55,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "UGA2013",
        "yCap": 2026.62,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "UGA2014",
        "yCap": 2067.8,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "UGA2015",
        "yCap": 2108.86,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "UGA2016",
        "yCap": 2137.21,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "UGA2017",
        "yCap": 2128.4,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "UGA2018",
        "yCap": 2186.91,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "UGA2019",
        "yCap": 2250.01,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "UGA2020",
        "yCap": 2240.49,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "UGA2021",
        "yCap": 2246.41,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Uganda",
        "Alpha-3": "UGA",
        "Alpha-2": "UG",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "UGA2022",
        "yCap": 2246.41,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1990,
        "ID": "UKR1990",
        "yCap": 16428.48,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1991,
        "ID": "UKR1991",
        "yCap": 14967.73,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1992,
        "ID": "UKR1992",
        "yCap": 13447.16,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1993,
        "ID": "UKR1993",
        "yCap": 11531.3,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1994,
        "ID": "UKR1994",
        "yCap": 8934.78,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1995,
        "ID": "UKR1995",
        "yCap": 7906.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1996,
        "ID": "UKR1996",
        "yCap": 7179.68,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1997,
        "ID": "UKR1997",
        "yCap": 7028.05,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1998,
        "ID": "UKR1998",
        "yCap": 6956.4,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1999,
        "ID": "UKR1999",
        "yCap": 7008.24,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2000,
        "ID": "UKR2000",
        "yCap": 7496.81,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2001,
        "ID": "UKR2001",
        "yCap": 8242.7,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2002,
        "ID": "UKR2002",
        "yCap": 8765.68,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2003,
        "ID": "UKR2003",
        "yCap": 9678.09,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2004,
        "ID": "UKR2004",
        "yCap": 10902.04,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2005,
        "ID": "UKR2005",
        "yCap": 11319.51,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2006,
        "ID": "UKR2006",
        "yCap": 12259.16,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2007,
        "ID": "UKR2007",
        "yCap": 13345.77,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2008,
        "ID": "UKR2008",
        "yCap": 13719.27,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2009,
        "ID": "UKR2009",
        "yCap": 11694.45,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2010,
        "ID": "UKR2010",
        "yCap": 12221.44,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2011,
        "ID": "UKR2011",
        "yCap": 12933.36,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2012,
        "ID": "UKR2012",
        "yCap": 12985.09,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2013,
        "ID": "UKR2013",
        "yCap": 13020.6,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2014,
        "ID": "UKR2014",
        "yCap": 12385.81,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2015,
        "ID": "UKR2015",
        "yCap": 11216.12,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2016,
        "ID": "UKR2016",
        "yCap": 11536.32,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2017,
        "ID": "UKR2017",
        "yCap": 11860.56,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2018,
        "ID": "UKR2018",
        "yCap": 12336.93,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2019,
        "ID": "UKR2019",
        "yCap": 12804.96,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2020,
        "ID": "UKR2020",
        "yCap": 12407.79,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2021,
        "ID": "UKR2021",
        "yCap": 12943.61,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Ukraine",
        "Alpha-3": "UKR",
        "Alpha-2": "UA",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2022,
        "ID": "UKR2022",
        "yCap": 12943.61,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 5,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 0,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1990,
        "ID": "ARE1990",
        "yCap": 105893.63,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1991,
        "ID": "ARE1991",
        "yCap": 101048.7,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1992,
        "ID": "ARE1992",
        "yCap": 99106.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1993,
        "ID": "ARE1993",
        "yCap": 95524.46,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1994,
        "ID": "ARE1994",
        "yCap": 97475.98,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1995,
        "ID": "ARE1995",
        "yCap": 99510.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1996,
        "ID": "ARE1996",
        "yCap": 99602.62,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1997,
        "ID": "ARE1997",
        "yCap": 100956.71,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1998,
        "ID": "ARE1998",
        "yCap": 95185.31,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 1999,
        "ID": "ARE1999",
        "yCap": 92368.81,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2000,
        "ID": "ARE2000",
        "yCap": 96835.89,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2001,
        "ID": "ARE2001",
        "yCap": 93106.2,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2002,
        "ID": "ARE2002",
        "yCap": 90661.73,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2003,
        "ID": "ARE2003",
        "yCap": 93989.96,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2004,
        "ID": "ARE2004",
        "yCap": 98342.24,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2005,
        "ID": "ARE2005",
        "yCap": 96188.13,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2006,
        "ID": "ARE2006",
        "yCap": 92323.56,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2007,
        "ID": "ARE2007",
        "yCap": 79469,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2008,
        "ID": "ARE2008",
        "yCap": 68909.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2009,
        "ID": "ARE2009",
        "yCap": 57094.79,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2010,
        "ID": "ARE2010",
        "yCap": 54664.61,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2011,
        "ID": "ARE2011",
        "yCap": 57815.17,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2012,
        "ID": "ARE2012",
        "yCap": 59949.24,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2013,
        "ID": "ARE2013",
        "yCap": 62354.82,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2014,
        "ID": "ARE2014",
        "yCap": 64334.09,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2015,
        "ID": "ARE2015",
        "yCap": 68076.64,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2016,
        "ID": "ARE2016",
        "yCap": 71244.59,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2017,
        "ID": "ARE2017",
        "yCap": 71182.37,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2018,
        "ID": "ARE2018",
        "yCap": 71550.55,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2019,
        "ID": "ARE2019",
        "yCap": 71782.15,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2020,
        "ID": "ARE2020",
        "yCap": 67668.29,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2021,
        "ID": "ARE2021",
        "yCap": 69733.79,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Arab Emirates",
        "Alpha-3": "ARE",
        "Alpha-2": "AE",
        "Region": "MENA",
        "IncGroup": "High",
        "RegIncGrp": "MENA-High",
        "Time": 2022,
        "ID": "ARE2022",
        "yCap": 69733.79,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 2.5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 2.5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1990,
        "ID": "GBR1990",
        "yCap": 31537.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1991,
        "ID": "GBR1991",
        "yCap": 31093.64,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1992,
        "ID": "GBR1992",
        "yCap": 31134.04,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1993,
        "ID": "GBR1993",
        "yCap": 31832.82,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1994,
        "ID": "GBR1994",
        "yCap": 32973.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1995,
        "ID": "GBR1995",
        "yCap": 33718.51,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1996,
        "ID": "GBR1996",
        "yCap": 34274.41,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1997,
        "ID": "GBR1997",
        "yCap": 35679.02,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1998,
        "ID": "GBR1998",
        "yCap": 36698.41,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 1999,
        "ID": "GBR1999",
        "yCap": 37678.69,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2000,
        "ID": "GBR2000",
        "yCap": 39080.87,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2001,
        "ID": "GBR2001",
        "yCap": 39770.61,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2002,
        "ID": "GBR2002",
        "yCap": 40303.87,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2003,
        "ID": "GBR2003",
        "yCap": 41369.5,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2004,
        "ID": "GBR2004",
        "yCap": 42099.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2005,
        "ID": "GBR2005",
        "yCap": 42926.99,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2006,
        "ID": "GBR2006",
        "yCap": 43533.22,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2007,
        "ID": "GBR2007",
        "yCap": 44302.86,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2008,
        "ID": "GBR2008",
        "yCap": 43887.71,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2009,
        "ID": "GBR2009",
        "yCap": 41592.36,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2010,
        "ID": "GBR2010",
        "yCap": 42270.43,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2011,
        "ID": "GBR2011",
        "yCap": 42389.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2012,
        "ID": "GBR2012",
        "yCap": 42705.15,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2013,
        "ID": "GBR2013",
        "yCap": 43192.08,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2014,
        "ID": "GBR2014",
        "yCap": 44247.03,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2015,
        "ID": "GBR2015",
        "yCap": 44948.34,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2016,
        "ID": "GBR2016",
        "yCap": 45574.85,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2017,
        "ID": "GBR2017",
        "yCap": 46372.39,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2018,
        "ID": "GBR2018",
        "yCap": 46878.13,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2019,
        "ID": "GBR2019",
        "yCap": 47362.26,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2020,
        "ID": "GBR2020",
        "yCap": 41984.11,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2021,
        "ID": "GBR2021",
        "yCap": 44978.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United Kingdom",
        "Alpha-3": "GBR",
        "Alpha-2": "GB",
        "Region": "ECA",
        "IncGroup": "High",
        "RegIncGrp": "ECA-High",
        "Time": 2022,
        "ID": "GBR2022",
        "yCap": 44978.71,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1990,
        "ID": "USA1990",
        "yCap": 40456.24,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1991,
        "ID": "USA1991",
        "yCap": 39876.02,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1992,
        "ID": "USA1992",
        "yCap": 40712.06,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1993,
        "ID": "USA1993",
        "yCap": 41284.36,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1994,
        "ID": "USA1994",
        "yCap": 42424.17,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1995,
        "ID": "USA1995",
        "yCap": 43047.26,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1996,
        "ID": "USA1996",
        "yCap": 44154.55,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1997,
        "ID": "USA1997",
        "yCap": 45566.26,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1998,
        "ID": "USA1998",
        "yCap": 47056.51,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 1999,
        "ID": "USA1999",
        "yCap": 48749.6,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2000,
        "ID": "USA2000",
        "yCap": 50175.74,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2001,
        "ID": "USA2001",
        "yCap": 50155.71,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2002,
        "ID": "USA2002",
        "yCap": 50535.27,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2003,
        "ID": "USA2003",
        "yCap": 51503.77,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2004,
        "ID": "USA2004",
        "yCap": 52995.24,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2005,
        "ID": "USA2005",
        "yCap": 54338.03,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 3.75,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2006,
        "ID": "USA2006",
        "yCap": 55314.2,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2007,
        "ID": "USA2007",
        "yCap": 55892.2,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2008,
        "ID": "USA2008",
        "yCap": 55433.68,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2009,
        "ID": "USA2009",
        "yCap": 53521.21,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2010,
        "ID": "USA2010",
        "yCap": 54516.86,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 3.75,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2011,
        "ID": "USA2011",
        "yCap": 54960.91,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2012,
        "ID": "USA2012",
        "yCap": 55803.51,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2013,
        "ID": "USA2013",
        "yCap": 56438.94,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 5,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2014,
        "ID": "USA2014",
        "yCap": 57308.32,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2015,
        "ID": "USA2015",
        "yCap": 58427.55,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 3.75,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 5
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2016,
        "ID": "USA2016",
        "yCap": 58972.9,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2017,
        "ID": "USA2017",
        "yCap": 59914.78,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2018,
        "ID": "USA2018",
        "yCap": 61355.65,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2019,
        "ID": "USA2019",
        "yCap": 62478.25,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2020,
        "ID": "USA2020",
        "yCap": 60167.86,
        "priceStab": 3.75,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2021,
        "ID": "USA2021",
        "yCap": 63669.71,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "United States",
        "Alpha-3": "USA",
        "Alpha-2": "US",
        "Region": "NRTHAM",
        "IncGroup": "High",
        "RegIncGrp": "NRTHAM-High",
        "Time": 2022,
        "ID": "USA2022",
        "yCap": 63669.71,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 5,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 5,
        "schoolEnr": 5,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "URY1990",
        "yCap": 11039.81,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "URY1991",
        "yCap": 11363.54,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "URY1992",
        "yCap": 12193.46,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "URY1993",
        "yCap": 12446.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "URY1994",
        "yCap": 13276.5,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "URY1995",
        "yCap": 13009.45,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "URY1996",
        "yCap": 13657.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "URY1997",
        "yCap": 14740.22,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "URY1998",
        "yCap": 15323.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "URY1999",
        "yCap": 14951.39,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "URY2000",
        "yCap": 14603.77,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 3.75,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "URY2001",
        "yCap": 14005.31,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "URY2002",
        "yCap": 12900.92,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "URY2003",
        "yCap": 12990.03,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "URY2004",
        "yCap": 13625.26,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "URY2005",
        "yCap": 14624.67,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "URY2006",
        "yCap": 15202.91,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 2.5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "URY2007",
        "yCap": 16166.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "URY2008",
        "yCap": 17287.72,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "URY2009",
        "yCap": 17978.06,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "URY2010",
        "yCap": 19331.85,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 3.75
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "URY2011",
        "yCap": 20275.44,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "URY2012",
        "yCap": 20933.69,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "URY2013",
        "yCap": 21839.41,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 3.75,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "URY2014",
        "yCap": 22477.06,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "URY2015",
        "yCap": 22486.43,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "URY2016",
        "yCap": 22793.07,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "URY2017",
        "yCap": 23107,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 1.25,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "URY2018",
        "yCap": 23184.62,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "URY2019",
        "yCap": 23256.6,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "URY2020",
        "yCap": 21828.64,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "URY2021",
        "yCap": 22800.69,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uruguay",
        "Alpha-3": "URY",
        "Alpha-2": "UY",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "URY2022",
        "yCap": 22800.69,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 2.5,
        "scientificOut": 3.75,
        "schoolEnr": 3.75,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 2.5,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1990,
        "ID": "UZB1990",
        "yCap": 3638.88,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1991,
        "ID": "UZB1991",
        "yCap": 3544.59,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1992,
        "ID": "UZB1992",
        "yCap": 3074.66,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1993,
        "ID": "UZB1993",
        "yCap": 2936.45,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1994,
        "ID": "UZB1994",
        "yCap": 2729.64,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1995,
        "ID": "UZB1995",
        "yCap": 2656.63,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 3.75,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1996,
        "ID": "UZB1996",
        "yCap": 2650.61,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1997,
        "ID": "UZB1997",
        "yCap": 2736.36,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1998,
        "ID": "UZB1998",
        "yCap": 2808.46,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 1999,
        "ID": "UZB1999",
        "yCap": 2897.82,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2000,
        "ID": "UZB2000",
        "yCap": 2967.6,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2001,
        "ID": "UZB2001",
        "yCap": 3052.28,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2002,
        "ID": "UZB2002",
        "yCap": 3134.96,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2003,
        "ID": "UZB2003",
        "yCap": 3229.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2004,
        "ID": "UZB2004",
        "yCap": 3430.63,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2005,
        "ID": "UZB2005",
        "yCap": 3626.62,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2006,
        "ID": "UZB2006",
        "yCap": 3849.59,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2007,
        "ID": "UZB2007",
        "yCap": 4154.7,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2008,
        "ID": "UZB2008",
        "yCap": 4457.7,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2009,
        "ID": "UZB2009",
        "yCap": 4736,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2010,
        "ID": "UZB2010",
        "yCap": 4953.96,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2011,
        "ID": "UZB2011",
        "yCap": 5185.68,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2012,
        "ID": "UZB2012",
        "yCap": 5472.83,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2013,
        "ID": "UZB2013",
        "yCap": 5781.16,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2014,
        "ID": "UZB2014",
        "yCap": 6075.19,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2015,
        "ID": "UZB2015",
        "yCap": 6401.12,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2016,
        "ID": "UZB2016",
        "yCap": 6663.95,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2017,
        "ID": "UZB2017",
        "yCap": 6840.71,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2018,
        "ID": "UZB2018",
        "yCap": 7082.93,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2019,
        "ID": "UZB2019",
        "yCap": 7348.15,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2020,
        "ID": "UZB2020",
        "yCap": 7344.22,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2021,
        "ID": "UZB2021",
        "yCap": 7734.83,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Uzbekistan",
        "Alpha-3": "UZB",
        "Alpha-2": "UZ",
        "Region": "ECA",
        "IncGroup": "LowMid",
        "RegIncGrp": "ECA-LowMid",
        "Time": 2022,
        "ID": "UZB2022",
        "yCap": 7734.83,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 5,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 3.75,
        "genderEqual": 2.5,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "VUT1990",
        "yCap": 2774.09,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "VUT1991",
        "yCap": 2791.19,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "VUT1992",
        "yCap": 2792.95,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 3.75,
        "pubHealth": 2.5,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "VUT1993",
        "yCap": 2744.76,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "VUT1994",
        "yCap": 2921.9,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "VUT1995",
        "yCap": 2881.14,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "VUT1996",
        "yCap": 2878.98,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "VUT1997",
        "yCap": 2950.05,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "VUT1998",
        "yCap": 2916.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "VUT1999",
        "yCap": 2858.05,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 0,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "VUT2000",
        "yCap": 2954.22,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "VUT2001",
        "yCap": 2782.01,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "VUT2002",
        "yCap": 2570.96,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "VUT2003",
        "yCap": 2614.81,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 0,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "VUT2004",
        "yCap": 2652.97,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "VUT2005",
        "yCap": 2726.84,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "VUT2006",
        "yCap": 2887.35,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "VUT2007",
        "yCap": 2899.86,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 3.75,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "VUT2008",
        "yCap": 2988.88,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "VUT2009",
        "yCap": 3005.95,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "VUT2010",
        "yCap": 2972.32,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "VUT2011",
        "yCap": 2994.39,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "VUT2012",
        "yCap": 2953.88,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "VUT2013",
        "yCap": 2897.51,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "VUT2014",
        "yCap": 2917.63,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "VUT2015",
        "yCap": 2859.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "VUT2016",
        "yCap": 2921.76,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "VUT2017",
        "yCap": 3031.19,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "VUT2018",
        "yCap": 3045.04,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "VUT2019",
        "yCap": 3070.35,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "VUT2020",
        "yCap": 2836.81,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "VUT2021",
        "yCap": 2783.02,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vanuatu",
        "Alpha-3": "VUT",
        "Alpha-2": "VU",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "VUT2022",
        "yCap": 2783.02,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 3.75,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1990,
        "ID": "VEN1990",
        "yCap": 14458,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1991,
        "ID": "VEN1991",
        "yCap": 14562.97,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1992,
        "ID": "VEN1992",
        "yCap": 14756.28,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 3.75,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 2.5,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1993,
        "ID": "VEN1993",
        "yCap": 14965.32,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1994,
        "ID": "VEN1994",
        "yCap": 15230.22,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1995,
        "ID": "VEN1995",
        "yCap": 15335.78,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1996,
        "ID": "VEN1996",
        "yCap": 15545.85,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1997,
        "ID": "VEN1997",
        "yCap": 15961.58,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1998,
        "ID": "VEN1998",
        "yCap": 16132.18,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 1999,
        "ID": "VEN1999",
        "yCap": 16282.35,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2000,
        "ID": "VEN2000",
        "yCap": 16566.14,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2001,
        "ID": "VEN2001",
        "yCap": 16630.31,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 5,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2002,
        "ID": "VEN2002",
        "yCap": 16649.59,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2003,
        "ID": "VEN2003",
        "yCap": 16884.42,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2004,
        "ID": "VEN2004",
        "yCap": 17359.15,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2005,
        "ID": "VEN2005",
        "yCap": 17714.1,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 5,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2006,
        "ID": "VEN2006",
        "yCap": 18214.89,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2007,
        "ID": "VEN2007",
        "yCap": 18690.28,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2008,
        "ID": "VEN2008",
        "yCap": 18915.13,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2009,
        "ID": "VEN2009",
        "yCap": 18152.51,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2010,
        "ID": "VEN2010",
        "yCap": 18305.2,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2011,
        "ID": "VEN2011",
        "yCap": 18677.9,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 3.75,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2012,
        "ID": "VEN2012",
        "yCap": 18833.91,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2013,
        "ID": "VEN2013",
        "yCap": 19012.88,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2014,
        "ID": "VEN2014",
        "yCap": 19274.6,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2015,
        "ID": "VEN2015",
        "yCap": 19451.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2016,
        "ID": "VEN2016",
        "yCap": 19564.52,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2017,
        "ID": "VEN2017",
        "yCap": 19627.35,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2018,
        "ID": "VEN2018",
        "yCap": 19828.33,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2019,
        "ID": "VEN2019",
        "yCap": 19983.88,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2020,
        "ID": "VEN2020",
        "yCap": 17800.85,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2021,
        "ID": "VEN2021",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Venezuela, RB",
        "Alpha-3": "VEN",
        "Alpha-2": "VE",
        "Region": "LAC",
        "IncGroup": "LowMid",
        "RegIncGrp": "LAC-LowMid",
        "Time": 2022,
        "ID": "VEN2022",
        "yCap": 18821.19,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 1.25,
        "tfp": 0,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 5,
        "equitSocDev": 5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 0,
        "safeWatAcc": 2.5,
        "cleanAirAcc": 3.75,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 3.75,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1990,
        "ID": "VNM1990",
        "yCap": 2099.39,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1991,
        "ID": "VNM1991",
        "yCap": 2177.47,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1992,
        "ID": "VNM1992",
        "yCap": 2317.27,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1993,
        "ID": "VNM1993",
        "yCap": 2455.51,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1994,
        "ID": "VNM1994",
        "yCap": 2623.72,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1995,
        "ID": "VNM1995",
        "yCap": 2825.02,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1996,
        "ID": "VNM1996",
        "yCap": 3039.94,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1997,
        "ID": "VNM1997",
        "yCap": 3239.68,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1998,
        "ID": "VNM1998",
        "yCap": 3378.9,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 1999,
        "ID": "VNM1999",
        "yCap": 3495.1,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2000,
        "ID": "VNM2000",
        "yCap": 3690.87,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2001,
        "ID": "VNM2001",
        "yCap": 3879.34,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2002,
        "ID": "VNM2002",
        "yCap": 4082.37,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2003,
        "ID": "VNM2003",
        "yCap": 4319.37,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2004,
        "ID": "VNM2004",
        "yCap": 4597.76,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2005,
        "ID": "VNM2005",
        "yCap": 4895.35,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 3.75,
        "openToImp": 0,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2006,
        "ID": "VNM2006",
        "yCap": 5186.43,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2007,
        "ID": "VNM2007",
        "yCap": 5503.07,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 1.25,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2008,
        "ID": "VNM2008",
        "yCap": 5757.92,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2009,
        "ID": "VNM2009",
        "yCap": 6006.58,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2010,
        "ID": "VNM2010",
        "yCap": 6324.53,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 1.25,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2011,
        "ID": "VNM2011",
        "yCap": 6658.67,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2012,
        "ID": "VNM2012",
        "yCap": 6950.29,
        "priceStab": 0,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2013,
        "ID": "VNM2013",
        "yCap": 7257.73,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2014,
        "ID": "VNM2014",
        "yCap": 7641.91,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2015,
        "ID": "VNM2015",
        "yCap": 8091.09,
        "priceStab": 5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2016,
        "ID": "VNM2016",
        "yCap": 8545.7,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2017,
        "ID": "VNM2017",
        "yCap": 9050.69,
        "priceStab": 1.25,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 3.75,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2018,
        "ID": "VNM2018",
        "yCap": 9636.01,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 2.5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2019,
        "ID": "VNM2019",
        "yCap": 10252,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 5,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2020,
        "ID": "VNM2020",
        "yCap": 10450.62,
        "priceStab": 2.5,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2021,
        "ID": "VNM2021",
        "yCap": 10628.22,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Vietnam",
        "Alpha-3": "VNM",
        "Alpha-2": "VN",
        "Region": "EAP",
        "IncGroup": "LowMid",
        "RegIncGrp": "EAP-LowMid",
        "Time": 2022,
        "ID": "VNM2022",
        "yCap": 10628.22,
        "priceStab": 3.75,
        "empLev": 5,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 5,
        "openToImp": 5,
        "polResp": 3.75,
        "tfp": 1.25,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 0,
        "equitSocDev": 2.5,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 1.25,
        "pubHealth": 3.75,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 2.5,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 3.75,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 1.25,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1990,
        "ID": "VIR1990",
        "yCap": 14458,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1991,
        "ID": "VIR1991",
        "yCap": 14562.97,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1992,
        "ID": "VIR1992",
        "yCap": 14756.28,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1993,
        "ID": "VIR1993",
        "yCap": 14965.32,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1994,
        "ID": "VIR1994",
        "yCap": 15230.22,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1995,
        "ID": "VIR1995",
        "yCap": 15335.78,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1996,
        "ID": "VIR1996",
        "yCap": 15545.85,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1997,
        "ID": "VIR1997",
        "yCap": 15961.58,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1998,
        "ID": "VIR1998",
        "yCap": 16132.18,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 1999,
        "ID": "VIR1999",
        "yCap": 16282.35,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 3.75,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2000,
        "ID": "VIR2000",
        "yCap": 16566.14,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2001,
        "ID": "VIR2001",
        "yCap": 16630.31,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2002,
        "ID": "VIR2002",
        "yCap": 16649.59,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2003,
        "ID": "VIR2003",
        "yCap": 16884.42,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 3.75,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2004,
        "ID": "VIR2004",
        "yCap": 17359.15,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2005,
        "ID": "VIR2005",
        "yCap": 17714.1,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2006,
        "ID": "VIR2006",
        "yCap": 18214.89,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2007,
        "ID": "VIR2007",
        "yCap": 18690.28,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2008,
        "ID": "VIR2008",
        "yCap": 18915.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2009,
        "ID": "VIR2009",
        "yCap": 18152.51,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2010,
        "ID": "VIR2010",
        "yCap": 18305.2,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2011,
        "ID": "VIR2011",
        "yCap": 18677.9,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2012,
        "ID": "VIR2012",
        "yCap": 18833.91,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2013,
        "ID": "VIR2013",
        "yCap": 19012.88,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2014,
        "ID": "VIR2014",
        "yCap": 19274.6,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 1.25,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2015,
        "ID": "VIR2015",
        "yCap": 19451.33,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 2.5,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2016,
        "ID": "VIR2016",
        "yCap": 19564.52,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2017,
        "ID": "VIR2017",
        "yCap": 19627.35,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2018,
        "ID": "VIR2018",
        "yCap": 19828.33,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2019,
        "ID": "VIR2019",
        "yCap": 19983.88,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2020,
        "ID": "VIR2020",
        "yCap": 17800.85,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2021,
        "ID": "VIR2021",
        "yCap": 18821.19,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "Virgin Islands (U.S.)",
        "Alpha-3": "VIR",
        "Alpha-2": "VI",
        "Region": "LAC",
        "IncGroup": "High",
        "RegIncGrp": "LAC-High",
        "Time": 2022,
        "ID": "VIR2022",
        "yCap": 18821.19,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 3.75,
        "socSec": 2.5,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 2.5,
        "infSurv": 2.5,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 5,
        "cleanAirAcc": 5,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1990,
        "ID": "PSE1990",
        "yCap": 3951.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1991,
        "ID": "PSE1991",
        "yCap": 3951.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1992,
        "ID": "PSE1992",
        "yCap": 3951.21,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1993,
        "ID": "PSE1993",
        "yCap": 3951.21,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1994,
        "ID": "PSE1994",
        "yCap": 3951.21,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1995,
        "ID": "PSE1995",
        "yCap": 4047.13,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1996,
        "ID": "PSE1996",
        "yCap": 3916.93,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 2.5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 2.5,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1997,
        "ID": "PSE1997",
        "yCap": 4294.75,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 2.5,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 3.75,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 2.5,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1998,
        "ID": "PSE1998",
        "yCap": 4786.48,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 1999,
        "ID": "PSE1999",
        "yCap": 5052.06,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2000,
        "ID": "PSE2000",
        "yCap": 4503.25,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2001,
        "ID": "PSE2001",
        "yCap": 3980.93,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2002,
        "ID": "PSE2002",
        "yCap": 3395.86,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2003,
        "ID": "PSE2003",
        "yCap": 3774.13,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2004,
        "ID": "PSE2004",
        "yCap": 4485.53,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2005,
        "ID": "PSE2005",
        "yCap": 4866.03,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 3.75,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2006,
        "ID": "PSE2006",
        "yCap": 4695.99,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2007,
        "ID": "PSE2007",
        "yCap": 4750.93,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 3.75,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2008,
        "ID": "PSE2008",
        "yCap": 4965.37,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2009,
        "ID": "PSE2009",
        "yCap": 5250.12,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 5,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 5,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2010,
        "ID": "PSE2010",
        "yCap": 5411.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 3.75,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2011,
        "ID": "PSE2011",
        "yCap": 5782.69,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2012,
        "ID": "PSE2012",
        "yCap": 5985.68,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 5,
        "SEPX": 3.75
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2013,
        "ID": "PSE2013",
        "yCap": 6118.26,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2014,
        "ID": "PSE2014",
        "yCap": 5967.07,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2015,
        "ID": "PSE2015",
        "yCap": 6048.98,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 3.75,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2016,
        "ID": "PSE2016",
        "yCap": 6438.93,
        "priceStab": 3.75,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2017,
        "ID": "PSE2017",
        "yCap": 6401.74,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2018,
        "ID": "PSE2018",
        "yCap": 6318.21,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2019,
        "ID": "PSE2019",
        "yCap": 6245.45,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2020,
        "ID": "PSE2020",
        "yCap": 5402.54,
        "priceStab": 2.5,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2021,
        "ID": "PSE2021",
        "yCap": 5643.07,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "West Bank and Gaza",
        "Alpha-3": "PSE",
        "Alpha-2": "PS",
        "Region": "MENA",
        "IncGroup": "LowMid",
        "RegIncGrp": "MENA-LowMid",
        "Time": 2022,
        "ID": "PSE2022",
        "yCap": 5643.07,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 2.5,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 3.75,
        "persSafe": 5,
        "genderEqual": 5,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 3.75,
        "infSurv": 2.5,
        "pubHealth": 5,
        "natureCons": 5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 3.75,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 3.75,
        "humEmp": 3.75,
        "envSust": 3.75,
        "SEPX": 2.5
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1990,
        "ID": "YEM1990",
        "yCap": 24642.33,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1991,
        "ID": "YEM1991",
        "yCap": 24723.38,
        "priceStab": 2.5,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1992,
        "ID": "YEM1992",
        "yCap": 24934.53,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1993,
        "ID": "YEM1993",
        "yCap": 25624.77,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1994,
        "ID": "YEM1994",
        "yCap": 25949.74,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1995,
        "ID": "YEM1995",
        "yCap": 26291.17,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 2.5,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 0,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1996,
        "ID": "YEM1996",
        "yCap": 26484.79,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1997,
        "ID": "YEM1997",
        "yCap": 26708.7,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1998,
        "ID": "YEM1998",
        "yCap": 26735.63,
        "priceStab": 5,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 2.5,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 1999,
        "ID": "YEM1999",
        "yCap": 26463.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2000,
        "ID": "YEM2000",
        "yCap": 27399.98,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2001,
        "ID": "YEM2001",
        "yCap": 26975.73,
        "priceStab": 3.75,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2002,
        "ID": "YEM2002",
        "yCap": 26770.58,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 1.25,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2003,
        "ID": "YEM2003",
        "yCap": 27640.96,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2004,
        "ID": "YEM2004",
        "yCap": 29212.73,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2005,
        "ID": "YEM2005",
        "yCap": 29624.75,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 2.5,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2006,
        "ID": "YEM2006",
        "yCap": 30012.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2007,
        "ID": "YEM2007",
        "yCap": 29623.53,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2008,
        "ID": "YEM2008",
        "yCap": 29336.44,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2009,
        "ID": "YEM2009",
        "yCap": 28125.51,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2010,
        "ID": "YEM2010",
        "yCap": 28673.1,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2011,
        "ID": "YEM2011",
        "yCap": 28706.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2012,
        "ID": "YEM2012",
        "yCap": 29787.76,
        "priceStab": 1.25,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2013,
        "ID": "YEM2013",
        "yCap": 29408.97,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2014,
        "ID": "YEM2014",
        "yCap": 29010.77,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2015,
        "ID": "YEM2015",
        "yCap": 29022.2,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2016,
        "ID": "YEM2016",
        "yCap": 29159.08,
        "priceStab": 5,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 3.75,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2017,
        "ID": "YEM2017",
        "yCap": 29138.23,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2018,
        "ID": "YEM2018",
        "yCap": 29259.79,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2019,
        "ID": "YEM2019",
        "yCap": 29025.99,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2020,
        "ID": "YEM2020",
        "yCap": 27251.16,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 2.5,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2021,
        "ID": "YEM2021",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Yemen, Rep.",
        "Alpha-3": "YEM",
        "Alpha-2": "YE",
        "Region": "MENA",
        "IncGroup": "Low",
        "RegIncGrp": "MENA-Low",
        "Time": 2022,
        "ID": "YEM2022",
        "yCap": 28473.86,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 3.75,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 2.5,
        "natureCons": 1.25,
        "safeWatAcc": 3.75,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1990,
        "ID": "ZMB1990",
        "yCap": 2290.04,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1991,
        "ID": "ZMB1991",
        "yCap": 2232.84,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1992,
        "ID": "ZMB1992",
        "yCap": 2141.5,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1993,
        "ID": "ZMB1993",
        "yCap": 2232.71,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1994,
        "ID": "ZMB1994",
        "yCap": 1991.19,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1995,
        "ID": "ZMB1995",
        "yCap": 1999.36,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1996,
        "ID": "ZMB1996",
        "yCap": 2071.71,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1997,
        "ID": "ZMB1997",
        "yCap": 2096.29,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1998,
        "ID": "ZMB1998",
        "yCap": 2034.9,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 0,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 0,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 1999,
        "ID": "ZMB1999",
        "yCap": 2074.45,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 0,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2000,
        "ID": "ZMB2000",
        "yCap": 2096.49,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2001,
        "ID": "ZMB2001",
        "yCap": 2142.79,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 0,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2002,
        "ID": "ZMB2002",
        "yCap": 2171.93,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2003,
        "ID": "ZMB2003",
        "yCap": 2252.12,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2004,
        "ID": "ZMB2004",
        "yCap": 2335.07,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2005,
        "ID": "ZMB2005",
        "yCap": 2422.43,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2006,
        "ID": "ZMB2006",
        "yCap": 2525.1,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2007,
        "ID": "ZMB2007",
        "yCap": 2641.03,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 1.25,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2008,
        "ID": "ZMB2008",
        "yCap": 2746.49,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2009,
        "ID": "ZMB2009",
        "yCap": 2894.96,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2010,
        "ID": "ZMB2010",
        "yCap": 3083.36,
        "priceStab": 0,
        "empLev": 0,
        "fiscHealth": 5,
        "favTradeBal": 5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2011,
        "ID": "ZMB2011",
        "yCap": 3146.84,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2012,
        "ID": "ZMB2012",
        "yCap": 3275.97,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2013,
        "ID": "ZMB2013",
        "yCap": 3330.88,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 5,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2014,
        "ID": "ZMB2014",
        "yCap": 3375.94,
        "priceStab": 1.25,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 2.5,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2015,
        "ID": "ZMB2015",
        "yCap": 3365.38,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2016,
        "ID": "ZMB2016",
        "yCap": 3384.27,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 3.75,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2017,
        "ID": "ZMB2017",
        "yCap": 3395.48,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2018,
        "ID": "ZMB2018",
        "yCap": 3425.95,
        "priceStab": 1.25,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 3.75,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2019,
        "ID": "ZMB2019",
        "yCap": 3372.36,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2020,
        "ID": "ZMB2020",
        "yCap": 3183.65,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2021,
        "ID": "ZMB2021",
        "yCap": 3236.79,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zambia",
        "Alpha-3": "ZMB",
        "Alpha-2": "ZM",
        "Region": "SSA",
        "IncGroup": "Low",
        "RegIncGrp": "SSA-Low",
        "Time": 2022,
        "ID": "ZMB2022",
        "yCap": 3236.79,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 0,
        "favTradeBal": 5,
        "tradeComp": 1.25,
        "openToImp": 2.5,
        "polResp": 0,
        "tfp": 0,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 0,
        "equitSocDev": 0,
        "scientificOut": 0,
        "schoolEnr": 0,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 0,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 1.25,
        "microComp": 0,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1990,
        "ID": "ZWE1990",
        "yCap": 2704.76,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 2.5,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 1.25,
        "envSust": 2.5,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1991,
        "ID": "ZWE1991",
        "yCap": 2781.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 5,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1992,
        "ID": "ZWE1992",
        "yCap": 2468.28,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1993,
        "ID": "ZWE1993",
        "yCap": 2458.78,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 2.5,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1994,
        "ID": "ZWE1994",
        "yCap": 2670.11,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 1.25,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 1.25,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 3.75,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1995,
        "ID": "ZWE1995",
        "yCap": 2641.38,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1996,
        "ID": "ZWE1996",
        "yCap": 2867.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1997,
        "ID": "ZWE1997",
        "yCap": 2896.15,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1998,
        "ID": "ZWE1998",
        "yCap": 2931.73,
        "priceStab": 5,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 1999,
        "ID": "ZWE1999",
        "yCap": 2866.03,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 1.25,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 1.25,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2000,
        "ID": "ZWE2000",
        "yCap": 2750.6,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2001,
        "ID": "ZWE2001",
        "yCap": 2772.33,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 1.25,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 1.25,
        "macroStab": 3.75,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2002,
        "ID": "ZWE2002",
        "yCap": 2510.23,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 2.5,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 2.5,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2003,
        "ID": "ZWE2003",
        "yCap": 2067.88,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2004,
        "ID": "ZWE2004",
        "yCap": 1934.16,
        "priceStab": 1.25,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2005,
        "ID": "ZWE2005",
        "yCap": 1814.17,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 0,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2006,
        "ID": "ZWE2006",
        "yCap": 1736.36,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2007,
        "ID": "ZWE2007",
        "yCap": 1656.79,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 1.25,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2008,
        "ID": "ZWE2008",
        "yCap": 1353.21,
        "priceStab": 5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 0,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 0,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 3.75,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 1.25,
        "SEPX": 1.25
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2009,
        "ID": "ZWE2009",
        "yCap": 1500.38,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2010,
        "ID": "ZWE2010",
        "yCap": 1799.54,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 1.25,
        "persSafe": 2.5,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 1.25,
        "socCoh": 1.25,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2011,
        "ID": "ZWE2011",
        "yCap": 2033.18,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2012,
        "ID": "ZWE2012",
        "yCap": 2310.81,
        "priceStab": 2.5,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 0,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 1.25,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2013,
        "ID": "ZWE2013",
        "yCap": 2333.64,
        "priceStab": 0,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2014,
        "ID": "ZWE2014",
        "yCap": 2316.95,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 1.25,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2015,
        "ID": "ZWE2015",
        "yCap": 2313.88,
        "priceStab": 3.75,
        "empLev": 3.75,
        "fiscHealth": 2.5,
        "favTradeBal": 0,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2016,
        "ID": "ZWE2016",
        "yCap": 2286.62,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 1.25,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2017,
        "ID": "ZWE2017",
        "yCap": 2331.78,
        "priceStab": 2.5,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 1.25,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 1.25,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 2.5,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2018,
        "ID": "ZWE2018",
        "yCap": 2399.62,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 1.25,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2019,
        "ID": "ZWE2019",
        "yCap": 2203.4,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 1.25,
        "favTradeBal": 3.75,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2020,
        "ID": "ZWE2020",
        "yCap": 1990.32,
        "priceStab": 0,
        "empLev": 2.5,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2021,
        "ID": "ZWE2021",
        "yCap": 2115.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 0
    },
    {
        "Country Name": "Zimbabwe",
        "Alpha-3": "ZWE",
        "Alpha-2": "ZW",
        "Region": "SSA",
        "IncGroup": "LowMid",
        "RegIncGrp": "SSA-LowMid",
        "Time": 2022,
        "ID": "ZWE2022",
        "yCap": 2115.14,
        "priceStab": 0,
        "empLev": 1.25,
        "fiscHealth": 2.5,
        "favTradeBal": 2.5,
        "tradeComp": 0,
        "openToImp": 2.5,
        "polResp": 5,
        "tfp": 1.25,
        "socSec": 0,
        "persSafe": 1.25,
        "genderEqual": 1.25,
        "equitSocDev": 0,
        "scientificOut": 1.25,
        "schoolEnr": 1.25,
        "infSurv": 0,
        "pubHealth": 0,
        "natureCons": 1.25,
        "safeWatAcc": 1.25,
        "cleanAirAcc": 0,
        "natDisRes": 0,
        "macroStab": 1.25,
        "microComp": 2.5,
        "socCoh": 0,
        "humEmp": 0,
        "envSust": 0,
        "SEPX": 1.25
    }
]


export { countriesData };