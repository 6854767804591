import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Chart } from "react-google-charts";

import { countries, dataSet, pillars, Alpha2 } from './global-data'

function GlobalBusiness() {

    const [worldMap, setworldMap] = useState()

    const [sum, setsum] = useState(0)
    const [indWeights, setIndWeights] = useState([]);
    const [ctryWeightedAvg, setCtryWeightedAvg] = useState([]);
    let sums = 0;

    const [indValue, setIndValue] = useState(
        {
            first: 0,
            second: 0,
            third: 0,
            fourth: 0,
            fifth: 0,
            sixth: 0,
            seventh: 0,
            eigth: 0,
            ninth: 0,
            tenth: 0,
            eleventh: 0,
            twelfth: 0,
        }
    )

    const [valInd, setValInd] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);

    useEffect(() => {
        const indValueArray = Object.values(indValue);

        // Compare the two arrays
        if (JSON.stringify(indValueArray) !== JSON.stringify(valInd)) {
            setValInd(indValueArray);
        }
    }, [indValue]);

    const handleInputChange = (index, value) => {
        const updatedIndValue = { ...indValue };
        updatedIndValue[Object.keys(indValue)[index]] = value;
        setIndValue(updatedIndValue);
    };

    const getAllValuesAsArray = () => {
        return Object.values(indValue);
    };
    console.log(getAllValuesAsArray())

    const calculateSum = () => {
        let sum = 0;
        for (let i = 0; i < valInd.length; i++) {
            sum += valInd[i];
        }
        return sum;
    };
    console.log(calculateSum())

    useEffect(() => {
        // console.log(sum)
        if (sum === 0) {
            setIndWeights(Array(12).fill(1 / 12));
            setsum(1);
            // console.log(indWeights)
        }
        else {
            const calculatedWeights = valInd.map((val) => val / sum);
            setIndWeights(calculatedWeights);
        }
    }, [sum, indValue]);
    console.log(indWeights)

    useEffect(() => {
        const calculateWeightedAverages = () => {
            const ctryWeightedAvgTemp = [];
            for (let i = 0; i < dataSet.length; i++) {
                const ctryScores = [];
                const ctryScoresWeighted = [];

                for (let j = 0; j < pillars.length; j++) {
                    ctryScores[j] = dataSet[i][pillars[j]];
                }

                for (let j = 0; j < ctryScores.length; j++) {
                    ctryScoresWeighted[j] = ctryScores[j] * indWeights[j];
                }

                let sum = 0;
                for (let j = 0; j < ctryScores.length; j++) {
                    sum = sum + ctryScoresWeighted[j];
                }

                ctryWeightedAvgTemp[i] = sum;
                // ctryWeightedAvg.push(sum)

            }

            setCtryWeightedAvg(ctryWeightedAvgTemp);
        };

        calculateWeightedAverages();

    }, [indWeights]);

    useEffect(() => {

        const wrldMapTable = [
            ['Country', 'Value']
        ];

        for (let i = 0; i < ctryWeightedAvg.length; i++) {

            wrldMapTable.push([Alpha2[i], ctryWeightedAvg[i]]);
        }


        setworldMap(wrldMapTable);
    }, [ctryWeightedAvg]);

    const changeindValue = (e) => {
        const { id, value } = e.target;
        setIndValue(prev => ({
            // setIndicators(prev => ({
            ...prev,
            [id]: value * 1
        }))
    }

    useEffect(() => {
        createMap();
        // calculateWeights();
        const newSum = Object.values(indValue).reduce((acc, curr) => acc + curr, 0);
        setsum(newSum);
        // something();
    }, [indValue, sum])

    useEffect(() => {
        console.log('indWeights:', indWeights);
    }, [indWeights]);

    const [regArray, setregArray] = useState([]);

    useEffect(() => {
        const updatedRegArray = [];
        for (let i = 0; i < ctryWeightedAvg.length; i++) {
            updatedRegArray.push(dataSet[i]['Region']);
        }
        setregArray(updatedRegArray);
    }, [dataSet, ctryWeightedAvg]);

    const getTableData = () => {
        const data = [['Name', 'Region', 'Score']];
        for (let i = 0; i < ctryWeightedAvg.length; i++) {
            data.push([countries[i], regArray[i], ctryWeightedAvg[i]]);
        }
        return data;
    };

    // const calculateRegArray = () => {
    //     for (let i = 0; i < ctryWeightedAvg.length; i++) {
    //         regArray[i] = dataSet[i]["Region"];
    //         console.log(regArray[0])
    //     }
    // }

    const options = {
        showRowNumber: true,
        width: '100%',
        height: '100%',
        allowHtml: true,
        cssClassNames: {
            tableCell: 'game-cell',
        },
        backgroundColor: 'transparent',
    };



    // const chartData = [
    //     ['Name', 'Region', 'Score'],
    //     ...countries.map((country, index) => [country, dataSet[index]['Region'], ctryWeightedAvg[index]]),
    // ];





    let data = []
    let alpha2 = []
    let wrldMapValues = []
    let toolTipCol = []
    let scores = ["..", 0, 1.25, 2.5, 3.75, 5]
    let rankings = ['No Data', 'Very Low', 'Low', 'Medium', 'High', 'Very High']
    let wrldMapTable = [
        [
            'Country',
            'Value'
        ]
    ]

    const [year, setYear] = useState(2022)
    const [indWorldmap, setindWorldmap] = useState('SEPX')

    // const [worldMap, setworldMap] = useState()
    const [currentData, setCurrentdata] = useState()

    const createMap = () => {
        setworldMap(wrldMapTable)
        console.log(worldMap)
    }

    const getIdForWrldMap = event => {
        setindWorldmap(event.currentTarget.id)
        createMap()
    };

    const getYear = (e) => {
        setYear(e.target.value)
        createMap()
    }

    const optionmaps = {
        title: 'Region Map',
        colorAxis: {
            colors: ['#802000', '#ff9f80', '#ffff80', '#99e699', '#1e7b1e']
        },
        datalessRegionColor: '#f5fafc',
        defaultColor: '#f5f5f5',
        backgroundColor: { fill: 'transparent' }
    }

    return (
        <>
            <div className="mb-16 p-4">
                <div className="mx-auto p-2 text-lg md:text-xl font-semibold">
                    World Economic Forum Global Competitiveness Rating with a Selection Option for the Importance of Individual Pillars
                </div>

                <div className="container mx-auto p-4">
                    <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4 items-left text-left">

                        {/* The Pillars */}
                        <div className="col-span-1">

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">Pillars</p>
                                </div>
                                <div className="col-span-1">
                                    <p className="font-semibold text-center">Importance</p>
                                    <div className="flex justify-between">
                                        <p className="font-semibold">0</p>
                                        <p className="font-semibold">+</p>
                                        <p className="font-semibold">++</p>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-2 border-t border-gray-600" />

                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">1st Pillar</p>
                                    <p>Institutions</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.first} className="slider indic" onChange={changeindValue} id="first" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">2nd Pillar</p>
                                    <p>Infrastructure</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.second} className="slider indic" onChange={changeindValue} id="second" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">3rd Pillar</p>
                                    <p>ICT Adoption</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.third} className="slider indic" onChange={changeindValue} id="third" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">4th Pillar</p>
                                    <p>Macroeconomic Stability</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.fourth} className="slider indic" onChange={changeindValue} id="fourth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">5th Pillar</p>
                                    <p>Health</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.fifth} className="slider indic" onChange={changeindValue} id="fifth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">6th Pillar</p>
                                    <p>Skills</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.sixth} className="slider indic" onChange={changeindValue} id="sixth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">7th Pillar</p>
                                    <p>Product Market</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.seventh} className="slider indic" onChange={changeindValue} id="seventh" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">8th Pillar</p>
                                    <p>Labour Market</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.eigth} className="slider indic" onChange={changeindValue} id="eigth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">9th Pillar</p>
                                    <p>Financial System</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.ninth} className="slider indic" onChange={changeindValue} id="ninth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">10th Pillar</p>
                                    <p>Market Size</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.tenth} className="slider indic" onChange={changeindValue} id="tenth" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">11th Pillar</p>
                                    <p>Business Dynamics</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.eleventh} className="slider indic" onChange={changeindValue} id="eleventh" />
                                </div>
                            </div>

                            <hr className="my-2 border-t border-gray-600" />
                            <div className="grid grid-cols-3 gap-2 items-left text-left mt-2">
                                <div className="col-span-2">
                                    <p className="font-semibold">12th Pillar</p>
                                    <p>Innovation Capability</p>
                                </div>
                                <div className="col-span-1">
                                    <input type="range" min="0" max="2" value={indValue.twelfth} className="slider indic" onChange={changeindValue} id="twelfth" />
                                </div>
                            </div>
                            <hr className="my-2 border-t border-gray-600" />

                        </div>

                        {/* To Show the GeoMap */}
                        <div className="col-span-3 mt-8 md:mt-0">
                            <div id="regions_div"></div>
                            <Chart
                                chartEvents={[
                                    {
                                        eventName: "select",
                                        callback: ({ chartWrapper }) => {
                                            const chart = chartWrapper.getChart();
                                            const selection = chart.getSelection();
                                            if (selection.length === 0) return;
                                            const region = data[selection[0].row + 1];
                                            console.log("Selected : " + region);
                                        },
                                    },
                                ]}
                                chartType="GeoChart"
                                width="100%"
                                height="auto"
                                data={worldMap}
                                options={optionmaps}
                            />
                        </div>

                    </div>

                    {/* Show the table data */}
                    <div className="mt-16">
                        <Chart
                            chartType="Table"
                            width="100%"
                            height="600px"
                            data={getTableData()}
                            options={options}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default GlobalBusiness