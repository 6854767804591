import React, { createContext, useState, useEffect } from 'react'

export const ModeContext = createContext({})

export const ModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const mode = JSON.parse(localStorage.getItem('mode'))

    if(mode){
      setDarkMode(true)
    }
  }, [])

  const setMode = ( mode ) => {
    localStorage.setItem('mode', mode)
    setDarkMode(mode)
  }

  return (
    <ModeContext.Provider value={{ darkMode, setMode }}>
      {children}
    </ModeContext.Provider>
  )
}
